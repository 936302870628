import MenuItem from '@mui/material/MenuItem';
import type { SelectProps } from '@mui/material/Select';
import MuiSelect from '@mui/material/Select';
import { clsx } from 'clsx';
import React from 'react';
import type { ReactNode } from 'react';
import Label from './Label';

type Props<T> = SelectProps<T> & {
  options: T[];
  label?: ReactNode | string;
  required?: boolean;
  getLabel: (option: T) => string | number;
  getValue: (option: T) => string | number;
  placeholder?: string;
};

export default function Select<T>({
  className,
  options,
  getValue,
  getLabel,
  label,
  required,
  placeholder,
  ...props
}: Props<T>) {
  const classes = clsx('custom-select relative', className);

  const renderValue = (selectedValue: T): React.ReactNode => {
    if (selectedValue === '' && Boolean(placeholder)) {
      return <span className="text-gray-400">{placeholder}</span>;
    }
    const selectedOption = options.find(
      (option) => getValue(option) === selectedValue,
    ) as T;
    return selectedOption ? getLabel(selectedOption) : '';
  };

  return (
    <MuiSelect<T>
      {...props}
      className={classes}
      displayEmpty={Boolean(placeholder)}
      label={
        typeof label === 'string' ? (
          <Label floatingLabel label={label} required={required} />
        ) : (
          label
        )
      }
      renderValue={renderValue}
    >
      {placeholder ? (
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
      ) : null}
      {options.map((option: T) => {
        const optionValue = getValue(option);
        const optionLabel = getLabel(option);

        return (
          <MenuItem key={optionValue} value={optionValue}>
            {optionLabel}
          </MenuItem>
        );
      })}
    </MuiSelect>
  );
}
