import { CloseIcon, Button, Input, SubLabel } from 'ui-kit';

import React, { useCallback, useState } from 'react';
import {
  type TemplateData,
  type Variable,
  type WorkflowSourceNode,
  type GlobalVariable,
  type VariableMap,
} from 'types-shared';
import { useSourceVariable } from '../../../hooks/useSourceVariable';
import { getTriggerBlockTitle } from './trigger.helpers';
import { TriggerBlockData } from './TriggerBlockData';

interface Props {
  node: WorkflowSourceNode;
  onCancel: () => void;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  updateNode: (node: WorkflowSourceNode) => void;
  updateVariable: (variable: Variable) => void;
  addVariable: (variable: Variable) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    query: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
}

interface LocalData {
  name?: string;
  description?: string;
}

export function TriggerBlockAdmin({
  onCancel,
  node,
  variablesMap,
  globalVariablesMap,
  updateVariable,
  addVariable,
  updateNode,
  transformApiReqStatus,
  onTransformApiReq,
}: Props) {
  const [localData, setLocalData] = useState<LocalData>({
    name: node.name ?? '',
    description: node.data.description ?? '',
  });

  const { sourceVariable } = useSourceVariable(variablesMap);

  const handleOnSave = useCallback(() => {
    const updatedNode = {
      ...node,
      name: localData.name,
      data: {
        ...node.data,
        description: localData.description,
      },
    };

    updateNode(updatedNode);
  }, [localData, node, updateNode]);

  if (!sourceVariable) return null;

  return (
    <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
      <div className="overflow-auto p-8 pb-24">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">
            Email Trigger
          </span>
          <Button
            className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            <CloseIcon className="text-info" />
          </Button>
        </div>
        <div className="my-6">
          <h2 className="text-cyan-900 text-lg font-medium leading-relaxed tracking-tight">
            {getTriggerBlockTitle(node, sourceVariable)}
          </h2>
        </div>
        <div className="flex-1 flex flex-col gap-6">
          <Input
            floatingLabel
            label="Trigger name"
            placeholder="Add a name to the trigger"
            value={localData.name ?? ''}
            onChange={(newName) => {
              const updatedNode = {
                ...node,
                name: newName,
              };
              updateNode(updatedNode);
              setLocalData((l) => ({ ...l, name: newName }));
            }}
          />

          <Input
            floatingLabel
            label="Description"
            placeholder="Add a description"
            value={localData.description ?? ''}
            onChange={(newDesc) => {
              setLocalData((l) => ({ ...l, description: newDesc }));
            }}
            multiline
            rows={5}
          />

          <SubLabel>
            The data in emails are stored as variables, which can be used in
            later parts of the workflow. You can also query them to extract data
            and create new variables.
          </SubLabel>

          <TriggerBlockData
            node={node}
            sourceVariable={sourceVariable}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            updateVariable={updateVariable}
            addVariable={addVariable}
            transformApiReqStatus={transformApiReqStatus}
            onTransformApiReq={onTransformApiReq}
          />
        </div>
      </div>

      <div className="flex w-full gap-9 px-8 py-6  absolute bottom-0 bg-white">
        <Button
          className="!flex-1"
          color="secondary"
          fullWidth
          disabled={!localData.name || !localData.description}
          onClick={() => {
            handleOnSave();
            onCancel();
          }}
          variant="contained"
        >
          Save changes
        </Button>
        <Button
          className="!flex-1"
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          cancel
        </Button>
      </div>
    </div>
  );
}
