import {
  Add,
  Button,
  DeleteOutlineIcon,
  GrayedOutInput,
  Input,
  Select,
} from 'ui-kit';
import SectionLabel from '../../Editor/components/EditNodePanel/SectionLabel';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { type WorkflowWebhook } from 'types-shared/workflowTypes';
import { useUpsertWorkflowWebhook } from '../hooks';
import isNil from 'lodash/isNil';
import {
  actions,
  convertFormValuesToWebhook,
  convertWebhookToFormValues,
  defaultForm,
  events,
  type FormValues,
} from '../helper';
import isEqual from 'lodash/isEqual';

interface SettingsFormProps {
  workflowId: string;
  webhooks: WorkflowWebhook[];
}

export default function SettingsForm({
  workflowId,
  webhooks,
}: SettingsFormProps) {
  const [settings, setSettings] = useState<FormValues>(defaultForm);
  const { mutate: upsertWebhook, status } = useUpsertWorkflowWebhook();
  const loading = status === 'pending';

  const lastWebhook = useMemo(() => webhooks[webhooks.length - 1], [webhooks]);
  const lastWebhookFormValues = useMemo(() => {
    if (isNil(lastWebhook)) {
      return defaultForm;
    }
    return convertWebhookToFormValues(lastWebhook);
  }, [lastWebhook]);

  const allowSave = settings.url && settings.event && settings.action;

  const onSave = () => {
    upsertWebhook({
      workflowId,
      webhooks: [convertFormValuesToWebhook(settings)],
    });
  };

  const onCancel = () => {
    setSettings(lastWebhookFormValues);
  };

  useEffect(() => {
    if (
      isEqual(settings, defaultForm) &&
      !isEqual(lastWebhookFormValues, defaultForm)
    ) {
      setSettings(lastWebhookFormValues);
    }
  }, [settings, lastWebhookFormValues]);

  return (
    <div className="flex flex-col space-y-8 w-1/2 min-w-100 xl:w-2/5">
      <h2 className="font-medium text-lg">
        Customize a webhook for a workflow event
      </h2>
      <div className="flex flex-col space-y-6">
        <Input
          label="Event Name"
          floatingLabel
          placeholder="Email notification"
          value={settings.eventName}
          onChange={(val) => {
            setSettings({ ...settings, eventName: val });
          }}
        />
        <Select
          label="Event"
          options={events}
          getLabel={(opt) => opt}
          getValue={(opt) => opt}
          placeholder="Select event"
          value={settings.event}
          onChange={(e) => {
            setSettings({ ...settings, event: e.target.value });
          }}
        />
        <Select
          label="Action"
          options={actions}
          getLabel={(opt) => opt}
          getValue={(opt) => opt}
          placeholder="Select action"
          value={settings.action}
          onChange={(e) => {
            setSettings({ ...settings, action: e.target.value });
          }}
        />
        <p className="text-sm font-medium">Request settings</p>
        <GrayedOutInput
          className="!px-3 !rounded-tl-md !rounded-tr-md"
          label="Method"
          value="POST"
        />
        <Input
          label="URL"
          floatingLabel
          placeholder="Email URL"
          value={settings.url}
          onChange={(val) => {
            setSettings({ ...settings, url: val });
          }}
        />
        <SectionLabel title="Headers" />

        {settings.headers.map((header) => {
          return (
            <div
              className="flex items-center w-100 justify-between"
              key={header.id}
            >
              <Input
                classes={{
                  wrapper: 'flex-1 mr-5',
                }}
                label="Key"
                floatingLabel
                placeholder="Enter key"
                value={header.key}
                onChange={(key) => {
                  setSettings({
                    ...settings,
                    headers: settings.headers.map((h) =>
                      h.id === header.id ? { ...h, key } : h,
                    ),
                  });
                }}
              />
              <Input
                classes={{
                  wrapper: 'flex-1 mr-5',
                }}
                label="Value"
                floatingLabel
                placeholder="Enter value"
                value={header.value}
                onChange={(value) => {
                  setSettings({
                    ...settings,
                    headers: settings.headers.map((h) =>
                      h.id === header.id ? { ...h, value } : h,
                    ),
                  });
                }}
              />
              <DeleteOutlineIcon
                className="hover:text-red-500 cursor-pointer"
                onClick={() => {
                  setSettings({
                    ...settings,
                    headers: settings.headers.filter((h) => h.id !== header.id),
                  });
                }}
              />
            </div>
          );
        })}
        <Button
          className="!max-w-min"
          color="secondary"
          onClick={() => {
            setSettings({
              ...settings,
              headers: [
                ...settings.headers,
                { id: uuid(), key: '', value: '' },
              ],
            });
          }}
          variant="outlined"
        >
          <Add />
        </Button>
        <div className="flex items-center space-x-4 !mt-10">
          <Button
            color="secondary"
            disabled={!allowSave || loading}
            variant="contained"
            onClick={onSave}
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </Button>
          <Button
            color="secondary"
            disabled={loading}
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
