import { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridValueFormatterParams,
  type GridValueGetterParams,
} from '@mui/x-data-grid/models';
import { Tooltip } from 'ui-kit';

import {
  type ExecutionStatus,
  type ExecutionBase,
  ExecutionStatusEnum,
  type ExecutionWithWorkflowName,
} from 'types-shared';
import dayjs from '../../../config/dayjs';
import { titleCase } from '../../../utils/helper';
import {
  allowedUserExecutionStatuses,
  triggerTitleMapping,
} from '../../../utils/constants';
import { capitalizeFirstLetter } from '../../Workflows/utils/helper';
import { ExecutionAdminRun } from '../../Workflows/components/ExecutionAdminRun';
import { ExecutionStatusDropdown } from '../../Workflows/components/ExecutionStatusDropdown';
import ExecutionChip from '../../Workflows/components/Chips/ExecutionChip';

const useExtensionListCols = ({
  executionDetailsEnabled,
  executionUpdateStatus,
  updateExecution,
  hitlLiveViewEnabled,
}: {
  executionDetailsEnabled: boolean;
  executionUpdateStatus?: 'error' | 'success' | 'idle' | 'pending' | undefined;
  hitlLiveViewEnabled: boolean;
  updateExecution: ({
    id,
    adminRun,
    status,
  }: {
    id: string;
    adminRun?: boolean;
    status?: ExecutionStatus;
  }) => unknown;
}): GridColDef<ExecutionWithWorkflowName>[] => {
  const handleUpdateExecution = useCallback(updateExecution, [updateExecution]);

  const columns: GridColDef<ExecutionWithWorkflowName>[] = useMemo(
    () => [
      {
        field: 'executionId',
        headerName: 'Execution ID',
        flex: 1,
        valueGetter: (params) => params.row.executionId,
        sortable: false,
      },
      {
        field: 'workflowName',
        headerName: 'Workflow',
        flex: 1,
        minWidth: 120,
        valueGetter: (params) => params.row.workflowName,
      },
      {
        field: 'userEmail',
        headerName: 'Owner',
        flex: undefined,
        valueGetter: (params: GridValueGetterParams<ExecutionBase>) =>
          params.row.userEmail,
        minWidth: 120,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<ExecutionBase>) => (
          <Tooltip
            containerClassName="w-full overflow-x-hidden"
            title={row.userEmail}
          >
            <span className="w-full truncate">{row.userEmail}</span>
          </Tooltip>
        ),
      },

      {
        field: 'attempts',
        headerName: 'Attempts',
        // flex: 1,
        minWidth: 80,
        sortable: false,
        valueGetter: (params: GridValueGetterParams<ExecutionBase>) =>
          params.row.attempt,
      },
      {
        field: 'adminRun',
        headerName: 'Admin Run',
        sortable: false,
        renderCell: ({
          row: { adminRun, executionId },
        }: GridRenderCellParams<ExecutionBase>) => {
          return (
            <ExecutionAdminRun
              checked={Boolean(adminRun)}
              id={executionId}
              updateAdminRun={handleUpdateExecution}
              adminRunStatus={executionUpdateStatus}
            />
          );
        },
      },

      {
        field: 'createdAt',
        headerName: 'Queued At',
        flex: 1,
        valueGetter: (params) => [
          params.row.createdAt,
          params.row.setId,
          params.row.recordId,
        ],
        valueFormatter: (params: GridValueFormatterParams<string[]>) =>
          dayjs(new Date(params.value[0])).format('MM/DD/YY -  h:mm A'),
        sortComparator: (v1: string[], v2: string[]) => {
          const [createdAt1, setId1, recordId1] = v1;
          const [createdAt2, setId2, recordId2] = v2;

          // If same createdAt, sort by setId
          if (createdAt1 === createdAt2) {
            // If same setId, sort by recordId
            if (setId1 === setId2) {
              return Number(recordId1) - Number(recordId2);
            }
            return Number(setId1 < setId2);
          }
          return (
            new Date(createdAt1).getTime() - new Date(createdAt2).getTime()
          );
        },
      },

      {
        field: 'updatedAt',
        headerName: 'Last Modification',
        flex: 1,
        valueGetter: (param: GridValueGetterParams<ExecutionBase>) =>
          new Date(param.row.lastUpdatedAt ?? param.row.createdAt),
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          dayjs(params.value).format('MM/DD/YY - h:mm A'),
      },

      {
        field: 'trigger',
        headerName: 'Trigger',
        flex: 1,
        renderCell: ({ row }) => (
          <span>
            {triggerTitleMapping[row.trigger] ?? titleCase(row.trigger)}
          </span>
        ),
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const title =
            params.row.status === ExecutionStatusEnum.Retry
              ? 'Waiting to retry'
              : params.row.status.toLowerCase().replace('_', ' ');

          return (
            <ExecutionChip
              className="!h-6"
              status={params.row.status}
              label={
                <span className="normal-case">
                  {capitalizeFirstLetter(title)}
                </span>
              }
              sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
              variant="filled"
            />
          );
        },
      },

      {
        field: 'update-status',
        headerName: 'Update Status',
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<ExecutionBase>) => {
          return (
            <ExecutionStatusDropdown
              executionUpdateStatus={executionUpdateStatus}
              id={params.row.executionId}
              status={params.row.status}
              updateExecutionStatus={handleUpdateExecution}
            />
          );
        },
      },

      {
        field: 'workflowId',
        headerName: 'View',
        minWidth: 120,
        valueGetter: (params) => params.row.workflowId,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<ExecutionBase>) => (
          <Link
            className="px-3 py-1.5 uppercase rounded border border-sola-primary text-sola-primary font-medium text-sm hover:bg-sola-primary-4 hover:border-sola-primary-dark hover:text-sola-primary-dark"
            to={`/workflows/${row.workflowId}`}
          >
            Workflow
          </Link>
        ),
      },
      {
        field: 'action',
        headerName: 'View',
        align: 'right',
        renderCell: (params) => {
          const show = allowedUserExecutionStatuses.includes(params.row.status);
          const canUseHITL =
            hitlLiveViewEnabled &&
            params.row.status === ExecutionStatusEnum.PendingUser;

          return show || executionDetailsEnabled || canUseHITL ? (
            <Link
              className="px-3 py-1.5 uppercase rounded border border-sola-primary text-sola-primary font-medium text-sm hover:bg-sola-primary-4 hover:border-sola-primary-dark hover:text-sola-primary-dark"
              to={`/execution/${params.row.executionId}`}
            >
              Execution
            </Link>
          ) : null;
        },
        flex: 1,
        sortable: false,
      },
    ],
    [
      handleUpdateExecution,
      executionUpdateStatus,
      executionDetailsEnabled,
      hitlLiveViewEnabled,
    ],
  );

  return columns;
};

export default useExtensionListCols;
