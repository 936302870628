import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  AdminVersionEnum,
  LlmTransformResponse,
  RawAxiosResponse,
  RecordingImportResponse,
  SendSlackMessageRequestPayload,
  SendSlackMessageResponse,
  UpdateWorkflowResponse,
  UploadMetaRequest,
  UploadVideoRequest,
  UploadVideoResponse,
} from 'api-types-shared';
import { UploadUrlContentTypeEnum, WorkflowStatusEnum } from 'api-types-shared';
import {
  type DatasourceMetadata,
  ExtensionData,
  type RecordingConfig,
  type TargetMap,
  type VariableMap,
  type WorkflowAction,
  type WorkflowData,
  type WorkflowNode,
} from 'types-shared';
import {
  CommitWorkflowState,
  NodeStatusEnum,
  NodeTypesEnum,
  parseTemplateString,
  QueryVariable,
  RunnableWorkflowState,
  VariableString,
} from 'types-shared';
import type { TemplateData, Variable } from 'types-shared/workflowTypes';
import { AlertVariant, notify } from 'ui-kit';
import {
  type AutolinkItem,
  type PollAutolinkTaskReponse,
  type QueueAutolinkTaskRequest,
  type QueueAutolinkTaskResponse,
} from 'dashboard-shared';
import { handleException } from 'sentry-browser-shared';
import { autoFormat } from '../utils/autoformat';
import { useAPI } from '../../../hooks/useApi';
import type { EditorWorkflowDataProps } from '../store/EditorState';
import { EditorStore } from '../store/EditorState';
import { useEffect, useRef } from 'react';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import {
  showVersionDiffDialog,
  replaceNodeWithSection,
  getWorkflowVersionType,
} from '../utils/helper';
import { sendToBackgroundViaRelay } from '@plasmohq/messaging';
import { CONTACT_SLACK_CHANNEL_ID } from '../utils/constants';
import { parseExtensionData } from '../../Workflows/utils/parseExtensionData';
import { s3Shim } from '../../../config/aws';
import { workflowDataBucket } from '../../../utils/env';
import { useFeatureFlag, copyS3Object } from '../../../utils/helper';
import { FeatureFlag } from '../../../utils/constants';

export const useGetWorkflowData = (
  workflowId: string,
  hasPersistedData: boolean,
  enabled = true,
  versionId?: string,
): UseQueryResult<WorkflowData | null> => {
  const { workflowSDK: sdk } = useAPI();

  return useQuery<WorkflowData | null>({
    queryKey: ['workflowData', workflowId, versionId],
    queryFn: async () => {
      return sdk.getWorkflowStateData(workflowId, versionId);
    },
    enabled: !hasPersistedData && enabled,
  });
};

export function useGetWorkflowVideos(
  workflowId: string,
): UseQueryResult<string[]> {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<string[]>({
    queryKey: ['workflow-video', workflowId],
    queryFn: () => {
      return sdk.getVideos(workflowId);
    },
    enabled: false,
  });
}

interface GetRefProps {
  targetData: TargetMap;
  variableData: VariableMap;
}

export const useGetRefData = (
  workflowId: string,
  hasPersistedData: boolean,
  versionId?: string,
  additionalQueryKey?: string,
): UseQueryResult<GetRefProps> => {
  const { workflowSDK: sdk } = useAPI();

  return useQuery<GetRefProps>({
    queryKey: ['nodeData', workflowId, versionId, additionalQueryKey],
    queryFn: async () => {
      const [variableData, targetData] = await Promise.all([
        sdk.getWorkflowVariableData(workflowId, versionId),
        sdk.getWorkflowTargetData(workflowId, versionId),
      ]);
      return { variableData, targetData };
    },
    enabled: !hasPersistedData,
    staleTime: 0,
  });
};

export const useGetOriginalImageData = (
  workflowId: string | undefined,
  imageId: string,
  focused: boolean,
): UseQueryResult<string | null> => {
  const { workflowSDK: sdk } = useAPI();

  return useQuery<string | null>({
    queryKey: ['image', focused, workflowId, imageId],
    queryFn: async () => {
      if (!focused || !workflowId) {
        return null;
      }
      const imageMap = await sdk.getImageData(workflowId, [imageId], true);
      return imageMap[imageId];
    },
  });
};

export const useGetAllThumbnails = (
  workflowId: string,
  imageIds: string[],
): UseQueryResult<Record<string, string | null>> => {
  const { workflowSDK: sdk } = useAPI();

  return useQuery<Record<string, string | null>>({
    queryKey: ['thumbnail', workflowId, imageIds.join('-')],
    queryFn: async () => {
      if (imageIds.length === 0) {
        return {};
      }
      return sdk.getImageData(workflowId, imageIds, false);
    },
  });
};

const showVersionPopup = async (): Promise<boolean> => {
  return new Promise((res) => {
    showVersionDiffDialog(() => {
      res(false);
    });
  });
};

export function useUpdateWorkflow(
  resetCurrentVersion: () => void,
  downloadCloudVersion: CallableFunction,
): UseMutationResult<
  string | undefined,
  Error,
  {
    workflowId: string;
    editorState: EditorWorkflowDataProps;
    bypassVersionCheck?: boolean;
  }
> {
  const { workflowSDK: sdk, miscSDK } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<
    string | undefined,
    Error,
    {
      workflowId: string;
      editorState: EditorWorkflowDataProps;
      bypassVersionCheck?: boolean;
    }
  >({
    mutationFn: async ({ workflowId, editorState, bypassVersionCheck }) => {
      if (!bypassVersionCheck) {
        const workflowMetadata = await sdk.fetchWorkflowMetadata(workflowId);
        if (
          workflowMetadata?.currentVersionId !== editorState.currentVersionId
        ) {
          const { isNotifyPush, isErrorPush, isSilentPush } =
            getWorkflowVersionType(workflowMetadata);
          if (isNotifyPush) {
            const proceed = await showVersionPopup();
            if (!proceed) {
              downloadCloudVersion();
              return undefined;
            }
          }
          if (isErrorPush) {
            window.location.reload();
            return undefined;
          }
          if (isSilentPush) {
            downloadCloudVersion();
            return undefined;
          }
        }
      }
      const committedWorkflowState = CommitWorkflowState.safeParse(editorState);
      if (!committedWorkflowState.success) {
        const message = `Unable to save, contact support`;
        await miscSDK.sendSlackMessage({
          workflowId,
          channelId: CONTACT_SLACK_CHANNEL_ID,
          text: `Workflow update failed: ${committedWorkflowState.error.message}`,
        });
        handleException(new Error(), {
          name: 'Update workflow validation failed.',
          source: 'Editor/useUpdateWorkflow',
          extra: {
            workflowId,
            editorState,
            committedWorkflowState,
          },
        });
        // TODO(manu/paul): Handle this error gracefully, ie. notify message and return
        throw new Error(message);
      }

      const runnableWorkflowState = RunnableWorkflowState.safeParse(
        committedWorkflowState.data,
      );
      await sdk.updateAllWorkflowData(workflowId, committedWorkflowState.data);

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['workflowStateData', workflowId],
        }),
        queryClient.invalidateQueries({ queryKey: ['workflows', workflowId] }),
      ]);
      resetCurrentVersion();
      const isSuccess = runnableWorkflowState.success;
      if (isSuccess) {
        notify({
          message: `Workflow updated successfully`,
          variant: AlertVariant.SUCCESS,
        });
      } else {
        notify({
          message: `Workflow saved, but not ready to execute.\n`,
          variant: AlertVariant.WARNING,
          timeoutInMs: 5 * 1000, // 5 seconds
        });
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const errorMessage = JSON.parse(
            runnableWorkflowState.error.message,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          )[0];
          if (errorMessage) {
            notify({
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              message: JSON.parse(runnableWorkflowState.error.message)[0]
                .message as string,
              variant: AlertVariant.INFO,
              timeoutInMs: 5 * 1000, // 5 seconds
            });
          }
        } catch (error) {
          handleException(error, {
            name: 'Unknown error message in workflow runnable validation',
            source: 'Editor/useUpdateWorkflow',
            extra: {
              workflowId,
              editorState,
              runnableWorkflowState,
            },
          });
        }
      }
      return workflowId;
    },
    onError: (error) => {
      handleException(error, {
        name: 'Save workflow failed',
        source: 'Editor/useUpdateWorkflow',
      });
      notify({
        message: 'Workflow save failed. Please contact support.',
        variant: AlertVariant.ERROR,
      });
    },
  });
}

interface UpdateWorkflowPayload {
  workflowId: string;
  workflowData: WorkflowData;
  variableData: VariableMap;
  targetData: TargetMap;
  datasourceMetadata: DatasourceMetadata | null;
  versionPush?: AdminVersionEnum;
  status?: WorkflowStatusEnum;
}

export function useUpdateWorkflowData(): UseMutationResult<
  string,
  Error,
  UpdateWorkflowPayload
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<string, Error, UpdateWorkflowPayload>({
    mutationFn: async ({
      workflowId,
      targetData,
      variableData,
      workflowData,
      datasourceMetadata,
      versionPush,
      status,
    }) => {
      const structuredWorkflowData = {
        nodes: workflowData.nodes,
        edges: workflowData.edges,
        variables: variableData,
        targets: targetData,
        datasourceMetadata,
      };
      const committedWorkflowState = CommitWorkflowState.safeParse(
        structuredWorkflowData,
      );
      if (!committedWorkflowState.success) {
        const message = `Workflow update failed: ${committedWorkflowState.error.message}`;
        throw new Error(message);
      }

      if (!isEmpty(versionPush)) {
        const newStatus = RunnableWorkflowState.safeParse(
          structuredWorkflowData,
        ).success
          ? WorkflowStatusEnum.Ready
          : WorkflowStatusEnum.Invalid;
        await sdk.updateAllWorkflowData(
          workflowId,
          committedWorkflowState.data,
          status === WorkflowStatusEnum.ProcessingImport ? status : newStatus,
          versionPush,
        );
      } else {
        await Promise.all([
          s3Shim
            .uploadObject(
              workflowDataBucket,
              `${workflowId}/state.json`,
              JSON.stringify(workflowData),
            )
            .done(),
          s3Shim
            .uploadObject(
              workflowDataBucket,
              `${workflowId}/variable.json`,
              JSON.stringify(variableData),
            )
            .done(),
          s3Shim
            .uploadObject(
              workflowDataBucket,
              `${workflowId}/target.json`,
              JSON.stringify(targetData),
            )
            .done(),
        ]);
      }
      await queryClient.invalidateQueries({
        queryKey: ['workflows', workflowId],
      });

      notify({
        message: `Workflow saved successfully`,
        variant: AlertVariant.SUCCESS,
      });
      return workflowId;
    },
  });
}

export function useUpdateWorkflowState(): UseMutationResult<
  string,
  Error,
  { workflowId: string; workflowData: WorkflowData }
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    { workflowId: string; workflowData: WorkflowData }
  >({
    mutationFn: async ({
      workflowId,
      workflowData,
    }: {
      workflowId: string;
      workflowData: WorkflowData;
    }) => {
      await sdk.updateWorkflowStateData(workflowId, workflowData);
      await queryClient.invalidateQueries({
        queryKey: ['workflowStateData', workflowId],
      });
      notify({
        message: `Workflow updated successfully`,
        variant: AlertVariant.SUCCESS,
        debug: true,
      });
      return workflowId;
    },
  });
}

export function useUpdateWorkflowVariableData(): UseMutationResult<
  string,
  Error,
  { workflowId: string; variableData: VariableMap }
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    { workflowId: string; variableData: VariableMap }
  >({
    mutationFn: async ({
      workflowId,
      variableData,
    }: {
      workflowId: string;
      variableData: VariableMap;
    }) => {
      await sdk.updateWorkflowVariableData(workflowId, variableData);
      await queryClient.invalidateQueries({
        queryKey: ['workflowVariableData', workflowId],
      });
      notify({
        message: `Workflow updated successfully`,
        variant: AlertVariant.SUCCESS,
      });
      return workflowId;
    },
  });
}

export function useUpdateWorkflowTargetData(): UseMutationResult<
  string,
  Error,
  { workflowId: string; targetData: TargetMap }
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    { workflowId: string; targetData: TargetMap }
  >({
    mutationFn: async ({
      workflowId,
      targetData,
    }: {
      workflowId: string;
      targetData: TargetMap;
    }) => {
      await sdk.updateWorkflowTargetData(workflowId, targetData);
      await queryClient.invalidateQueries({
        queryKey: ['workflowTargetData', workflowId],
      });
      notify({
        message: `Workflow updated successfully`,
        variant: AlertVariant.SUCCESS,
      });
      return workflowId;
    },
  });
}

export function useAutolinkTask(
  req: QueueAutolinkTaskRequest,
): UseQueryResult<Promise<RawAxiosResponse<QueueAutolinkTaskResponse> | null>> {
  const { autolinkDemoSDK: sdk } = useAPI();
  return useQuery<Promise<RawAxiosResponse<QueueAutolinkTaskResponse> | null>>({
    queryKey: [
      'autolinkTask',
      req.variables.map<string>((v) => v.id).join('-'),
    ],
    queryFn: async () => {
      return sdk.queueAutolinkTask(req);
    },
  });
}

export function useAutolinkTaskPoller(taskId?: string | undefined) {
  const { autolinkDemoSDK: sdk } = useAPI();
  const resp = useQuery<RawAxiosResponse<PollAutolinkTaskReponse>>({
    queryKey: ['taskId', taskId],
    queryFn: async () => {
      return sdk.pollAutolinkTask(String(taskId));
    },
    refetchInterval: (data) => {
      return ['expired', 'finished'].includes(data.state.status) ? false : 1000;
    },
    enabled: Boolean(taskId),
  });
  if (resp.data?.data.status === 'expired') {
    handleException(
      new Error(`Autolink task ${taskId ?? 'undefined'} expired`),
      {
        name: 'Autolink Error',
        source: 'AutolinkDemo',
      },
    );
  }

  return resp;
}

export function useUpdateStoreAutolinkData(
  autolinkData?: PollAutolinkTaskReponse,
) {
  const { addVariable, updateVariableData, nodes, updateNode } = EditorStore();
  const nodesRef = useRef<WorkflowNode[]>([]);

  useEffect(() => {
    if (nodes.length !== 0) {
      nodesRef.current = nodes;
    }
  }, [nodes]);

  useEffect(() => {
    if (autolinkData?.status === 'finished') {
      const data = JSON.parse(autolinkData.data ?? '[]') as AutolinkItem[];
      const templateVarIds: string[] = [];
      data.forEach(({ variableId, datasource }: AutolinkItem) => {
        if (datasource) {
          templateVarIds.push(variableId);
          const datasourceQueryVariableParse = QueryVariable.safeParse(
            JSON.parse(datasource),
          );
          if (!datasourceQueryVariableParse.success) {
            handleException(
              new Error(
                `Failed to parse datasource variable: ${datasourceQueryVariableParse.error.message}`,
              ),
              {
                name: 'Autolink Error',
                source: 'AutolinkDemo',
              },
            );
            return;
          }
          const datasourceQueryVariable = datasourceQueryVariableParse.data;
          datasourceQueryVariable.name = 'PUT IN VALUES HERE';
          addVariable(datasourceQueryVariable);

          updateVariableData(variableId, {
            data: [datasourceQueryVariable],
          });
        }
      });
      if (templateVarIds.length > 0) {
        nodesRef.current.forEach((node) => {
          if (node.type === NodeTypesEnum.Image) {
            values(node.data.actionData).forEach((action: WorkflowAction) => {
              if (
                action.variableId &&
                templateVarIds.includes(action.variableId)
              ) {
                updateNode({
                  ...node,
                  data: {
                    ...node.data,
                    nodeStatus: NodeStatusEnum.Autolinked,
                  },
                });
              }
            });
          }
        });
      }
    }
  }, [addVariable, autolinkData, updateNode, updateVariableData]);
}

export function useSendSlackMessage() {
  const { miscSDK: sdk } = useAPI();
  return useMutation<
    SendSlackMessageResponse,
    Error,
    SendSlackMessageRequestPayload
  >({
    mutationFn: async (request) => {
      const data = await sdk.sendSlackMessage(request);
      notify({
        message: `Your query has been submitted to our support team. We will get back to you soon!`,
        variant: AlertVariant.INFO,
      });
      return data;
    },
  });
}

export function useSendSlackMessageWithFile() {
  const { miscSDK: sdk } = useAPI();
  return useMutation<
    SendSlackMessageResponse,
    Error,
    SendSlackMessageRequestPayload
  >({
    mutationFn: async (request) => {
      const data = await sdk.sendSlackMessageWithFile(request);
      notify({
        message: `Your query has been submitted to our support team. We will get back to you soon!`,
        variant: AlertVariant.INFO,
      });
      return data;
    },
  });
}

export const useQueueAutolinkTask = (): UseMutationResult<
  string,
  Error,
  { datasourceId: string | null; variables: Variable[] }
> => {
  const { autolinkDemoSDK: autolinkSdk, datasourceSDK } = useAPI();

  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    { datasourceId: string | null; variables: Variable[] }
  >({
    mutationFn: async ({ datasourceId, variables }) => {
      if (!datasourceId) {
        throw new Error('Datasource not found!');
      }

      const datasourceData = await datasourceSDK.getDatasource({
        params: { datasourceId },
        query: { uploadUrlContentType: UploadUrlContentTypeEnum.CSV },
      });
      const csvUrl = datasourceData.url;
      if (!csvUrl) {
        throw new Error('CSV URL not found');
      }

      const response = await autolinkSdk.queueAutolinkTask({
        document: {
          datasource_id: datasourceId,
          media_type: 'text/csv',
          uri: csvUrl,
        },
        variables: Object.values(variables),
      });

      await queryClient.invalidateQueries({
        queryKey: ['datasources', datasourceId],
      });

      return response.data.task_id;
    },
  });
};

export function useTransformApiReq(variableMap: VariableMap) {
  const { transformSDK: sdk } = useAPI();
  return useMutation<
    LlmTransformResponse | null,
    Error,
    { data: string; prompt: TemplateData }
  >({
    mutationFn: async (request) => {
      const { data, prompt } = request;
      const stringifiedPrompt = parseTemplateString({
        data: prompt,
        variableMap,
        handleException,
      });
      return sdk.transform({
        data,
        prompt: VariableString.parse(stringifiedPrompt),
      });
    },
  });
}

export function useUploadFiles(): UseMutationResult<
  string[],
  Error,
  { files: File[]; workflowId: string }
> {
  const { fileSDK: sdk } = useAPI();
  return useMutation<string[], Error, { files: File[]; workflowId: string }>({
    mutationFn: async ({ files, workflowId }) => {
      const data = await sdk.addFiles(files, workflowId);
      return data;
    },
  });
}

export function useImportSubWorkflow(
  resetCurrentVersion: CallableFunction,
): UseMutationResult<
  { success: boolean },
  Error,
  {
    workflowId: string;
    extensionData: RecordingImportResponse;
    editorState: Pick<
      EditorWorkflowDataProps,
      'nodes' | 'edges' | 'variables' | 'targets'
    >;
    config: RecordingConfig;
  }
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  const isCookiesEnabled = useFeatureFlag(FeatureFlag.ExtensionCookies, false);
  const isLocalStorageEnabled = useFeatureFlag(
    FeatureFlag.ExtensionLocalStorage,
    false,
  );

  return useMutation<
    { success: boolean },
    Error,
    {
      workflowId: string;
      extensionData: RecordingImportResponse;
      editorState: Pick<
        EditorWorkflowDataProps,
        'nodes' | 'edges' | 'variables' | 'targets'
      >;
      config: RecordingConfig;
    }
  >({
    mutationFn: async ({
      workflowId,
      extensionData,
      editorState,
      config: { replaceNodeId },
    }) => {
      const { actions, scans } = extensionData;
      if (!actions || !scans) {
        notify({
          message: 'No recording found',
          variant: AlertVariant.ERROR,
        });
        throw new Error('No recording found');
      }
      const {
        workflowData: importedWorkflowData,
        newImages: imageHashes,
        variableStore: newVariableData,
        targetStore: newTargetData,
      } = await parseExtensionData(ExtensionData.parse(extensionData));
      const { config } = extensionData;

      const { nodes, edges, variables, targets } = editorState;

      let newNodes = [...nodes, ...importedWorkflowData.nodes];
      let newEdges = [...edges, ...importedWorkflowData.edges];
      const newVariables = { ...variables, ...newVariableData };
      const newTargets = { ...targets, ...newTargetData };

      const replacementNodeId = config?.replaceNodeId ?? replaceNodeId;
      if (replacementNodeId) {
        ({ newNodes, newEdges } = replaceNodeWithSection(
          { nodes, edges },
          importedWorkflowData,
          replacementNodeId,
        ));
      }

      // This first update is to get the user's "old" current state saved to the cloud before we add in all the new recording data
      await sdk
        .updateAllWorkflowData(workflowId, {
          nodes,
          edges,
          variables,
          targets,
        })
        .catch((error: unknown) => {
          notify({
            message: 'Error while saving pre-import workflow data.',
            variant: AlertVariant.ERROR,
            debug: true,
          });
          throw error;
        });

      const videoReqId = Date.now().toString();
      const data = await sdk.updateWorkflowMetadata({
        params: {
          workflowId,
        },
        query: {},
        body: {
          imageHashes,
          videoReqId,
        },
      });

      const {
        imageUploadMap,
        videoUpload,
        cookiesUploadUrl,
        localStorageUploadUrl,
      } = data as UpdateWorkflowResponse;

      if (isEmpty(data) || isEmpty(imageUploadMap) || isEmpty(videoUpload)) {
        throw new Error('presigned urls not found');
      }

      // Upload workflow data
      const promises = [];

      const extensionUploadResponse = sendToBackgroundViaRelay<
        UploadVideoRequest['body']
      >({
        name: 'onUploadVideo.handler' as never,
        body: {
          workflowId,
          videoUpload,
          imageUploadMap,
        },
      }).then(async (uploadVideoData: UploadVideoResponse) => {
        await sdk.completeUploadVideo({
          workflowId,
          uploadId: videoUpload.uploadId,
          uploadVideoData,
          videoReqId,
        });
      });

      promises.push(extensionUploadResponse);

      if (
        cookiesUploadUrl &&
        localStorageUploadUrl &&
        (Boolean(isCookiesEnabled) || Boolean(isLocalStorageEnabled))
      ) {
        const metaUploadResponse = sendToBackgroundViaRelay<UploadMetaRequest>({
          name: 'onUploadMeta.handler' as never,
          body: {
            workflowId,
            cookiesUploadUrl: isCookiesEnabled ? cookiesUploadUrl : undefined,
            localStorageUploadUrl: isLocalStorageEnabled
              ? localStorageUploadUrl
              : undefined,
          },
        }).catch((error: unknown) => {
          handleException(error as Error, {
            name: 'Upload workflow metadata on subimport failed',
            source: 'Editor/useImportSubWorkflow',
            extra: {
              workflowId,
            },
          });
          return null;
        });
        await Promise.race([
          metaUploadResponse,
          new Promise((resolve) => {
            setTimeout(() => {
              handleException(new Error('Meta upload timed out'), {
                name: 'Meta upload timeout',
                source: 'Workflows/useCreateWorkflow',
                extra: {
                  workflowId,
                  extensionData,
                },
              });
              resolve(null);
            }, 5000);
          }),
        ]);
      }

      // Wait for all promises to resolve and check if any of them are false
      const results = await Promise.allSettled(promises);
      const failed = results.filter(({ status }) => status === 'rejected');

      // TODO: Handle failed uploads with Sentry
      if (failed.length) {
        notify({
          message: 'Error while uploading workflow recording.',
          variant: AlertVariant.ERROR,
          debug: true,
        });
      }

      autoFormat(newNodes, newEdges, (_nodes: WorkflowNode[]) => {
        newNodes = _nodes;
      });
      await sdk.updateAllWorkflowData(workflowId, {
        nodes: newNodes,
        edges: newEdges,
        variables: newVariables,
        targets: newTargets,
      });

      await sdk.updateWorkflowStatus(workflowId, {
        status: WorkflowStatusEnum.ProcessingImport,
      });

      resetCurrentVersion();
      await queryClient.invalidateQueries({ queryKey: ['workflows'] });

      return {
        success: true,
      };
    },
  });
}

export function useRestoreWorkflowVersion() {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { workflowId: string; versionId: string }>(
    {
      mutationFn: async ({ workflowId, versionId }) => {
        const result = await sdk.revertWorkflow(workflowId, versionId);
        await queryClient.invalidateQueries({
          queryKey: ['workflows', workflowId],
        });
        return result;
      },
    },
  );
}

interface CloneWorkflowImagesPayload {
  fromWorkflow: WorkflowData;
  toWorkflowId: string;
}

export function useCloneWorkflowImages() {
  return useMutation<unknown, Error, CloneWorkflowImagesPayload>({
    mutationFn: async ({
      fromWorkflow,
      toWorkflowId,
    }: CloneWorkflowImagesPayload) => {
      const fromWorkflowId = fromWorkflow.workflowId;
      if (!fromWorkflowId) {
        return;
      }
      const imageIds = fromWorkflow.nodes
        .filter((node) => node.type === NodeTypesEnum.Image)
        .map((node) => node.data.imageData.imageId);
      const promises: unknown[] = [];
      promises.concat(
        imageIds.map((id) =>
          copyS3Object(
            `${fromWorkflowId}/thumbnails/${id}.png`,
            `${toWorkflowId}/thumbnails/${id}.png`,
          ),
        ),
      );
      promises.concat(
        imageIds.map((id) =>
          copyS3Object(
            `${fromWorkflowId}/images/${id}.png`,
            `${toWorkflowId}/images/${id}.png`,
          ),
        ),
      );
      await Promise.all(promises);
    },
  });
}

export function useImportSubWorkflowAdmin(): UseMutationResult<
  {
    workflowData: WorkflowData;
    targetStore: TargetMap;
    variableStore: VariableMap;
    config?: RecordingConfig;
  },
  Error,
  { workflowId: string; extensionData: RecordingImportResponse }
> {
  const queryClient = useQueryClient();
  const { workflowSDK: sdk } = useAPI();
  return useMutation<
    {
      workflowData: WorkflowData;
      targetStore: TargetMap;
      variableStore: VariableMap;
      config?: RecordingConfig;
    },
    Error,
    { workflowId: string; extensionData: RecordingImportResponse }
  >({
    mutationFn: async ({ workflowId, extensionData }) => {
      const { actions, scans } = extensionData;
      if (!actions || !scans) {
        notify({
          message:
            'No recording found – begin a new workflow by recording from the extension!',
          variant: AlertVariant.ERROR,
        });
        throw new Error('No recording found');
      }
      const {
        workflowData,
        newImages: imageHashes,
        variableStore,
        targetStore,
      } = await parseExtensionData(ExtensionData.parse(extensionData));
      const { config } = extensionData;

      const videoReqId = Date.now().toString();
      const data = await sdk.updateWorkflowMetadata({
        params: {
          workflowId,
        },
        query: {},
        body: {
          imageHashes,
          videoReqId,
        },
      });

      const { imageUploadMap, videoUpload } = data as UpdateWorkflowResponse;

      if (isEmpty(data) || isEmpty(imageUploadMap) || isEmpty(videoUpload)) {
        throw new Error('presigned urls not found');
      }

      // Upload workflow data
      const promises = [];

      const extensionUploadResponse = sendToBackgroundViaRelay<
        UploadVideoRequest['body']
      >({
        name: 'onUploadVideo.handler' as never,
        body: {
          workflowId,
          videoUpload,
          imageUploadMap,
        },
      }).then(async (uploadVideoData: UploadVideoResponse) => {
        await sdk.completeUploadVideo({
          workflowId,
          uploadId: videoUpload.uploadId,
          uploadVideoData,
          videoReqId,
        });
      });

      promises.push(extensionUploadResponse);

      // Wait for all promises to resolve and check if any of them are false
      const results = await Promise.allSettled(promises);
      const failed = results.filter(({ status }) => status === 'rejected');

      // TODO: Handle failed uploads with Sentry
      if (failed.length) {
        notify({
          message: 'Error while uploading workflow recording.',
          variant: AlertVariant.ERROR,
          debug: true,
        });
      }

      await queryClient.invalidateQueries({ queryKey: ['workflows'] });

      return {
        workflowData,
        targetStore,
        variableStore,
        config,
      };
    },
  });
}
