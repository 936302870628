import { Button, Flex, Input, LockIcon } from 'ui-kit';
import { clsx } from 'clsx';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  parseTemplateString,
  ParseVariableMode,
  type QueryVariable,
  VariableString,
} from 'types-shared';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import ApiKeyModal from './AddKeyModal';
import { isAdmin, solaApiUrl } from '../../../utils/env';
import { handleException } from 'sentry-browser-shared';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { FeatureFlag } from '../../../utils/constants';
import { CopyData } from '../../../components/CopyData';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

interface ApiCallMethodsProps {
  apiKey: string;
  setApiKey: (val: string) => void;
  hasRetryNode?: boolean;
  settings: {
    retryInterval: string | number | null;
    maxAttempts: string | number | null;
  };
  payloadData: Record<string, string | null>;
  workflowId: string;
  inputFields: [string, QueryVariable][];
  formData: Record<string, string | null>;
  setFormData: React.Dispatch<
    React.SetStateAction<Record<string, string | null>>
  >;
  executionId: string;
  setExecutionId: React.Dispatch<React.SetStateAction<string>>;
  showApiPageRetryConfig: boolean;
}

type ExecutionApiEndpoint = 'trigger' | 'status' | 'outputs';

export function ApiCallMethods({
  apiKey,
  setApiKey,
  settings,
  payloadData,
  workflowId,
  inputFields,
  formData,
  setFormData,
  executionId,
  setExecutionId,
  showApiPageRetryConfig,
}: ApiCallMethodsProps) {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showApiKeyModal, setShowApiKeyModal] = useState<boolean>(false);
  const [selectedEndpoint, setSelectedEndpoint] =
    useState<ExecutionApiEndpoint>('trigger');

  // TODO(rafic): Remove this once we have execution details for readonly (desktop) workflows
  const readOnlyWorkflowsPayload = useFeatureFlagPayload(
    FeatureFlag.ReadonlyWorkflows,
  ) as { readOnlyWorkflows?: string[] } | undefined;
  const isDesktopWorkflow = useMemo(
    () => readOnlyWorkflowsPayload?.readOnlyWorkflows?.includes(workflowId),
    [readOnlyWorkflowsPayload, workflowId],
  );
  const queueEndpoint = isDesktopWorkflow ? 'queue-desktop' : 'queue-remote';

  const retryConfig = useMemo(() => pickBy(settings, identity), [settings]);

  const apiConfig: Record<
    ExecutionApiEndpoint,
    {
      label: string;
      buttonLabel?: string;
      description: string;
      requestDescription: string;
      responseDescription: string;
      endpoint: string;
      method: string;
      sampleResponse: Record<string, unknown>;
      sampleCurl: string;
    }
  > = {
    trigger: {
      label: 'Trigger workflow',
      buttonLabel: 'Trigger Workflow',
      description:
        'Execute the following API call from your app to trigger the workflow.',
      requestDescription:
        'To execute the workflow, include data values for the variables in the payload.',
      responseDescription:
        'Check the status of the triggered execution in the API response.',
      endpoint: `${solaApiUrl}/v1/execution/${queueEndpoint}/${workflowId}`,
      method: 'PUT',
      sampleResponse: {
        executionId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
      },
      sampleCurl: `curl -X PUT "${solaApiUrl}/v1/execution/${queueEndpoint}/${workflowId}" \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer ${apiKey}" \\
-d '${JSON.stringify({ variableData: payloadData }, null, 2)}'`,
    },
    status: {
      label: 'Get workflow status',
      description:
        'Execute the following API call from your app to get the status of a workflow.',
      requestDescription:
        'To get status details for a workflow execution, include an execution ID in the payload.',
      responseDescription:
        'Receive details on the execution via the API response.',
      endpoint: `${solaApiUrl}/v1/execution/status-remote/${executionId || '<EXECUTION_ID>'}`,
      method: 'GET',
      sampleResponse: {
        executionId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        workflowId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        setId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        status: 'QUEUED',
        createdAt: 'YYYY-MM-TT',
        variableData: { 'Variable 1': 'Value', 'Variable 2': 'Value' },
      },
      sampleCurl: `curl -X GET "${solaApiUrl}/v1/execution/status-remote/${executionId || '<EXECUTION_ID>'}" \\
-H "Authorization: Bearer ${apiKey}"`,
    },
    outputs: {
      label: 'Get workflow outputs',
      description:
        'Execute the following API call from your app to get the outputs of a workflow.',
      requestDescription:
        'To get the outputs for a workflow execution, include an execution ID in the payload.',
      responseDescription:
        'Receive output details on the execution via the API response.',
      endpoint: `${solaApiUrl}/v1/execution/outputs-remote/${executionId || '<EXECUTION_ID>'}`,
      method: 'GET',
      sampleResponse: {
        variableData: { 'Variable 1': 'Value', 'Variable 2': 'Value' },
        artifactUrls: [
          'https://sola-execution-data.s3.us-east-1.amazonaws.com/xxxxx',
          'https://sola-execution-data.s3.us-east-1.amazonaws.com/xxxxx',
        ],
        status: 'success',
      },
      sampleCurl: `curl -X GET "${solaApiUrl}/v1/execution/outputs-remote/${executionId || '<EXECUTION_ID>'}" \\
-H "Authorization: Bearer ${apiKey}"`,
    },
  };

  const adminRun = isAdmin ? true : undefined;

  const payloadJsonString = useMemo(() => {
    const filteredVariableState = omitBy(
      formData,
      (value) => isNil(value) || value === '',
    );
    return JSON.stringify(
      {
        variableData: filteredVariableState,
        retryConfig: showApiPageRetryConfig ? retryConfig : undefined,
        adminRun,
      },
      null,
      2,
    );
  }, [formData, showApiPageRetryConfig, retryConfig, adminRun]);

  const triggerSnippets = useMemo(
    () => ({
      json: payloadJsonString,
      curl: `curl -X PUT "${solaApiUrl}/v1/execution/${queueEndpoint}/${workflowId}" \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer ${apiKey}" \\
-d '${payloadJsonString}'`,
      python: `import requests
url = "${solaApiUrl}/v1/execution/${queueEndpoint}/${workflowId}"
payload = ${payloadJsonString}
headers = {
  "Content-Type": "application/json",
  "Authorization": f"Bearer ${apiKey}"
}
response = requests.request("PUT", url, json=payload, headers=headers)
print(response.text)`,
      bash: `#!/bin/bash
curl -X PUT "${solaApiUrl}/v1/execution/${queueEndpoint}/${workflowId}" \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer ${apiKey}" \\
-d '${payloadJsonString}'`,
      ts: `import axios from 'axios';
const url = "${solaApiUrl}/v1/execution/${queueEndpoint}/${workflowId}";
const payload = ${payloadJsonString};
axios.put(url, payload, { headers: { "Content-Type": "application/json", "Authorization": \`Bearer ${apiKey}\` } })
  .then(response => console.log(response.data));`,
    }),
    [apiKey, payloadJsonString, workflowId, queueEndpoint],
  );

  const statusAndOutputSnippets = useMemo(() => {
    const id = executionId || '<EXECUTION_ID>';
    const remotePath =
      selectedEndpoint === 'outputs' ? 'outputs-remote' : 'status-remote';
    return {
      curl: `curl -X GET "${solaApiUrl}/v1/execution/${remotePath}/${id}" \\
-H "Authorization: Bearer ${apiKey}"`,
      python: `import requests
url = "${solaApiUrl}/v1/execution/${remotePath}/${id}"
headers = {
  "Content-Type": "application/json",
  "Authorization": "Bearer ${apiKey}"
}
response = requests.get(url, headers=headers)
print(response.text)`,
      bash: `#!/bin/bash
curl -X GET "${solaApiUrl}/v1/execution/${remotePath}/${id}" \\
-H "Authorization: Bearer ${apiKey}"`,
      ts: `import axios from 'axios';
const url = \`${solaApiUrl}/v1/execution/${remotePath}/${id}\`;
axios.get(url, { headers: { "Authorization": \`Bearer ${apiKey}\` } })
  .then(response => console.log(response.data));`,
    };
  }, [apiKey, executionId, selectedEndpoint]);

  const selectedSnippetData = useMemo(() => {
    return Object.values(
      selectedEndpoint === 'trigger'
        ? triggerSnippets
        : statusAndOutputSnippets,
    ).find((_s, idx) => idx === selectedTab);
  }, [selectedEndpoint, triggerSnippets, statusAndOutputSnippets, selectedTab]);

  const onTabChange = (_event: React.SyntheticEvent, index: number) => {
    setSelectedTab(index);
  };

  const updateForm = (name: string) => (value: string) => {
    setFormData((prevState) => ({ ...prevState, [name]: value || '' }));
  };

  return (
    <Flex className="gap-12 mt-8" flexDirection="row">
      <Flex className="flex flex-col pl-1" flexDirection="column">
        <Button
          className="flex !w-max !mb-8 !ml-6"
          color="secondary"
          onClick={() => {
            setShowApiKeyModal(true);
          }}
          variant="outlined"
        >
          Set API Key
          <LockIcon className="ml-2.5 !w-4 !h-4" />
        </Button>
        {Object.entries(apiConfig).map(([endpointType, info]) => (
          <Button
            className={clsx(
              '!py-2 !pl-6 !pr-10 flex w-full !text-nowrap !font-medium !leading-6 !text-sm !justify-start !rounded-none hover:!text-primary-light-blue',
              selectedEndpoint === endpointType
                ? '!text-primary-light-blue !border-2 !border-primary-light-blue !border-l-0 !border-y-0'
                : '!text-secondary-text !border-0',
            )}
            color="secondary"
            key={endpointType}
            onClick={() => {
              setSelectedEndpoint(endpointType as ExecutionApiEndpoint);
              setSelectedTab(0);
              setExecutionId('');
            }}
            variant="outlined"
          >
            {info.buttonLabel ?? info.label}
          </Button>
        ))}
      </Flex>

      <Flex className="gap-6 flex-1 font-medium" flexDirection="column">
        <div>
          <p className="text-info-dark text-lg font-medium mb-1.5">
            {apiConfig[selectedEndpoint].label}
          </p>
          <p className="text-sm text-color-grey font-normal mb-5">
            {apiConfig[selectedEndpoint].description}
          </p>
          <CopyData
            data={apiConfig[selectedEndpoint].endpoint}
            dataType="url"
            queryMethod={apiConfig[selectedEndpoint].method}
          />
        </div>

        <Flex className="gap-8 flex-1 font-medium mt-12" flexDirection="row">
          <div className="flex-1">
            <p className="text-info-dark text-lg font-medium mb-1.5">
              {selectedEndpoint === 'trigger'
                ? 'Test API trigger with variable values'
                : 'Test API endpoint with a workflow execution'}
            </p>
            {selectedEndpoint === 'trigger' ? (
              <>
                {!inputFields.length ? (
                  <div className="flex-1 flex flex-col items-center justify-center px-9 pt-20 pb-40 bg-[#F9FAFA]">
                    <p className="text-sm text-color-grey font-normal text-center mb-8">
                      Once you add API variables to your workflow, you can enter
                      values in this section to test API requests with different
                      input data.
                    </p>
                    <Button
                      className="!py-2 !px-6 !text-sm !font-medium"
                      color="secondary"
                      onClick={() => {
                        navigate(`/editor/${workflowId}`);
                      }}
                      variant="outlined"
                    >
                      Add API variables in the workflow
                    </Button>
                  </div>
                ) : (
                  <>
                    <p className="text-sm text-color-grey font-normal flex-1">
                      Fill in values for the workflow variables to automatically
                      populate the data in the API request.
                    </p>
                    {inputFields.map(([id, variable]) => {
                      const parsedId = VariableString.parse(
                        parseTemplateString({
                          data: variable.data.query,
                          variableMap: {},
                          mode: ParseVariableMode.Dashboard,
                          handleException,
                        }),
                      );
                      return (
                        <div className="mt-5" key={id}>
                          <Input
                            floatingLabel
                            id={parsedId}
                            label={parsedId}
                            onChange={updateForm(parsedId)}
                            placeholder="Enter value"
                            value={formData[parsedId] ?? ''}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            ) : null}
            {selectedEndpoint !== 'trigger' ? (
              <>
                <p className="text-sm text-color-grey font-normal flex-1">
                  Enter an execution ID to automatically configure the sample
                  API request. The execution ID is returned in the response of
                  an API workflow trigger.
                </p>
                <div className="mt-6">
                  <Input
                    floatingLabel
                    id="execution-Id"
                    label="Execution ID"
                    onChange={setExecutionId}
                    placeholder="Enter value"
                    value={executionId}
                  />
                </div>
              </>
            ) : null}
          </div>

          <div className="flex-1 max-w-[50%]">
            <p className="text-info-dark text-lg font-medium mb-1.5">
              Sample API request
            </p>
            <p className="text-sm text-color-grey font-normal mb-5">
              {apiConfig[selectedEndpoint].requestDescription}
            </p>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                onChange={onTabChange}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#2196F3',
                  },
                  '& .Mui-selected': {
                    color: '#2196F3 !important',
                  },
                }}
                value={selectedTab}
              >
                {Object.keys(
                  selectedEndpoint === 'trigger'
                    ? triggerSnippets
                    : statusAndOutputSnippets,
                ).map((lang, index) => (
                  <Tab key={lang} label={lang.toUpperCase()} value={index} />
                ))}
              </Tabs>
            </Box>
            {apiKey ? (
              <CopyData
                className="flex-1 !rounded-t-none"
                data={selectedSnippetData ?? ''}
                dataType="json"
              />
            ) : (
              <Flex
                className="rounded-bl-lg rounded-br-lg bg-primary-blue-extralight-2 text-sm text-info-dark justify-center items-center h-52 space-y-4"
                flexDirection="column"
              >
                <span className="text-color-grey">
                  Add API Key to generate sample API requests
                </span>
                <Button
                  className="flex !w-max"
                  color="secondary"
                  onClick={() => {
                    setShowApiKeyModal(true);
                  }}
                  variant="outlined"
                >
                  Set API Key
                  <LockIcon className="ml-2.5 !w-4 !h-4" />
                </Button>
              </Flex>
            )}

            <Flex
              className="flex-1 mt-14"
              flexDirection="column"
              justifyContent="space-between"
            >
              <p className="text-info-dark text-lg font-medium mb-1.5">
                Sample response
              </p>
              <p className="text-sm text-color-grey font-normal mb-5">
                {apiConfig[selectedEndpoint].responseDescription}
              </p>
              <CopyData
                data={JSON.stringify(
                  apiConfig[selectedEndpoint].sampleResponse,
                  null,
                  2,
                )}
                dataType="json"
              />
            </Flex>
          </div>
        </Flex>
      </Flex>
      <ApiKeyModal
        onClose={() => {
          setShowApiKeyModal(false);
        }}
        onSave={setApiKey}
        open={showApiKeyModal}
      />
    </Flex>
  );
}
