import {
  Button,
  Flex,
  EditIconAlt as EditIcon,
  InfoOutlined,
  Select,
  Tooltip,
} from 'ui-kit';
import React, { type ChangeEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import {
  type WorkflowMetadataType,
  type WorkflowStatusEnum,
} from 'api-types-shared';
import type {
  CompactWorkflowMetadata,
  WorkflowStatusSelectItem,
} from '../types';
import { isAdmin } from '../../../utils/env';
import {
  workflowProcessingStatuses,
  workflowStatusSelectOptions,
} from '../../../utils/constants';
import { useUpdateWorkflowStatus } from '../hooks';

export default function WorkflowDetailHeader({
  isEditingName,
  workflowName,
  inputRef,
  setIsEditingName,
  editWorkflowEnabled,
  workflowMetadata,
  workflowId,
  updateWorkflowName,
  onNameChange,
  setCloneWorkflowData,
  hasSuggestions,
}: {
  isEditingName: boolean;
  hasSuggestions: boolean;
  workflowName: string;
  editWorkflowEnabled: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  setIsEditingName: React.Dispatch<React.SetStateAction<boolean>>;
  workflowMetadata: WorkflowMetadataType;
  workflowId: string;
  updateWorkflowName: () => Promise<void>;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setCloneWorkflowData: React.Dispatch<
    React.SetStateAction<CompactWorkflowMetadata | null>
  >;
}) {
  const navigate = useNavigate();
  const { mutate: updateWorkflowMetadata, status: workflowUpdateStatus } =
    useUpdateWorkflowStatus();

  const workflowProcessingAndInaccessible = useMemo(() => {
    if (!workflowMetadata.status) return false;

    return (
      !isAdmin &&
      workflowProcessingStatuses.processingAndInaccessible.includes(
        workflowMetadata.status,
      )
    );
  }, [workflowMetadata.status]);

  return (
    <>
      <div>
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Flex alignItems="flex-end" className="flex-1">
            <EditIcon
              className="text-indigo-light hover:text-color-grey mb-2 mr-1 cursor-pointer !fill-none"
              onClick={() => {
                setIsEditingName((e) => !e);
              }}
            />
            <input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={isEditingName}
              style={{ width: `${workflowName.length.toString()}ch` }}
              className={clsx(
                'border-b-2 border-transparent placeholder:text-indigo-light text-4xl font-bold focus:outline-0 !text-ellipsis',
                'max-w-[700px] min-w-[250px]',
                {
                  'text-blue-500 !border-primary-blue': isEditingName,
                },
              )}
              onBlur={updateWorkflowName}
              onChange={onNameChange}
              onClick={() => {
                setIsEditingName(true);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  void updateWorkflowName();
                }
              }}
              placeholder={workflowMetadata.workflowName}
              readOnly={!isEditingName}
              ref={inputRef}
              value={workflowName}
            />
          </Flex>
          <div className="flex space-x-2">
            {isAdmin ? (
              <Select<WorkflowStatusSelectItem>
                classes={{
                  root: '!min-h-10',
                  select: '!py-0 !-mb-2',
                }}
                disabled={workflowUpdateStatus === 'pending'}
                getLabel={(option) => option.label}
                getValue={(option) => option.value}
                onChange={(event) => {
                  updateWorkflowMetadata({
                    ...workflowMetadata,
                    status: event.target.value as WorkflowStatusEnum,
                  });
                }}
                options={workflowStatusSelectOptions}
                value={
                  workflowMetadata.status as unknown as WorkflowStatusSelectItem
                }
              />
            ) : null}
            {isAdmin ? (
              <Button
                color="secondary"
                onClick={() => {
                  setCloneWorkflowData({
                    workflowId: workflowMetadata.workflowId,
                    workflowName: workflowMetadata.workflowName,
                  });
                }}
                variant="outlined"
              >
                Clone workflow
              </Button>
            ) : null}
            {editWorkflowEnabled ? (
              <Tooltip
                hidden={!workflowProcessingAndInaccessible}
                title="You will be able to view the workflow once it finishes processing"
                arrow
                placement="right"
              >
                <div>
                  <Button
                    color="secondary"
                    onClick={() => {
                      navigate(`/editor/${workflowId}`);
                    }}
                    variant="contained"
                    disabled={workflowProcessingAndInaccessible}
                  >
                    Edit workflow
                  </Button>
                </div>
              </Tooltip>
            ) : null}
          </div>
        </Flex>
        {isAdmin && workflowMetadata.email ? (
          <div>Email: {workflowMetadata.email}</div>
        ) : null}
      </div>

      {hasSuggestions && !isAdmin ? (
        <div className="mt-4 bg-primary-blue-extralight flex space-x-2 px-6 py-4 rounded">
          <InfoOutlined className="!w-5 !h-5 !text-info !mt-0.5" />
          <div className="flex flex-col space-y-1">
            <span className="text-info-dark text-base font-medium">
              Workflow improvement recommendation
            </span>
            <span className="text-sm">
              Some workflow errors can be controlled to reduce failed runs. You
              can accept, edit or reject the changes recommended by Sola.
            </span>
          </div>
          <Button
            className="!ml-auto !my-auto !max-h-max"
            color="secondary"
            onClick={() => {
              if (workflowId) {
                navigate(`/editor/${workflowId}`);
              }
            }}
            variant="outlined"
          >
            Review Workflow improvements
          </Button>
        </div>
      ) : null}
    </>
  );
}
