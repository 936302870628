import { AddCircleOutlineOutlined } from 'ui-kit';
import { clsx } from 'clsx';
import {
  forwardRef,
  type MouseEvent,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import {
  type GlobalVariable,
  type VariableMap,
  isVariableAllowedToAddInInput,
  type TemplateData,
  Variable,
  VariableTypeEnum,
} from 'types-shared';
import VariablesMenu from './Menu';
import {
  replacePlaceholderWithVariableChip,
  extractTemplateData,
  updateVariableDataIntoContainer,
  VARIABLE_ID_DATA_ATTRIBUTE,
  appendPlaceholderAtCurrentSelection,
} from './variableInput.utils';
import values from 'lodash/values';
import isEqual from 'lodash/isEqual';

import { VariableInput as VariableInput2 } from './Input2.0';

interface Props {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  value?: TemplateData;
  onChange: (val: TemplateData) => void;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  allowAddVariable?: boolean;
  showPlusButton?: boolean;
  onClickAddNew?: () => void;
  onClickVariableChip?: (variableId: string) => void;
  multiline?: boolean; // true by default
}

export interface VariableInputRef {
  addVariable: (variable: Variable, indexForVariableInsert?: number) => void;
  reRender: () => void;
}

export const VariableInput = VariableInput2;

export const VariableInputLegacy = forwardRef(
  (
    {
      className,
      value = [],
      label,
      onChange,
      onClickAddNew,
      onClickVariableChip,
      disabled = false,
      allowAddVariable = true,
      showPlusButton = true,
      variablesMap,
      globalVariablesMap,
      placeholder,
      multiline = true,
    }: Props,
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLDivElement | null>(null);

    const [localValue, setLocalValue] = useState<TemplateData>([]);
    const [showVariableMenu, setShowVariableMenu] = useState<boolean>(false);

    const allowedVariables = useMemo(() => {
      return values(variablesMap).filter((variable: Variable) => {
        return isVariableAllowedToAddInInput(variable);
      });
    }, [variablesMap]);

    const allowedGlobalVariables: GlobalVariable[] = useMemo(
      () => values(globalVariablesMap) as GlobalVariable[],
      [globalVariablesMap],
    );

    const updateContent = useCallback(() => {
      const newContent = inputRef.current?.innerHTML;
      const data = newContent ? extractTemplateData(newContent) : [];
      setLocalValue(data);
      onChange(data);
    }, [onChange]);

    const handleInput = () => {
      updateContent();
    };

    const handleAddVariable = useCallback(
      (variable: Variable) => {
        if (!inputRef.current) return;

        if (isVariableAllowedToAddInInput(variable)) {
          replacePlaceholderWithVariableChip(variable, inputRef.current);
          updateContent();
        }
      },
      [updateContent],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          addVariable(variable: Variable) {
            handleAddVariable(variable);
          },
        };
      },
      [handleAddVariable],
    );

    const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
      const target = event.target as HTMLElement;
      const variableId = target.getAttribute(VARIABLE_ID_DATA_ATTRIBUTE);

      const isGlobalVar = Boolean(
        variableId && Object.keys(globalVariablesMap).includes(variableId),
      );

      const isExecutionVar = Boolean(
        variableId &&
          variablesMap[variableId].type === VariableTypeEnum.Execution,
      );

      if (isGlobalVar || isExecutionVar) return;
      if (target.tagName === 'SPAN' && onClickVariableChip && variableId) {
        onClickVariableChip(variableId);
      }
    };

    useEffect(() => {
      const inputEl = inputRef.current;
      if (!inputEl) return;

      const hasChanged = !isEqual(value, localValue);

      if (!hasChanged) return;

      setLocalValue(value);
      updateVariableDataIntoContainer(
        inputEl,
        value,
        variablesMap,
        globalVariablesMap,
      );
    }, [value, inputRef, variablesMap, globalVariablesMap, localValue]);

    const showPlaceholder =
      placeholder &&
      (!localValue.length || (localValue.length === 1 && localValue[0] === ''));

    return (
      <div
        className={clsx(
          'relative flex border px-3 py-2.5 max-w-full rounded min-h-14 focus-within:border-gray-400',
          !multiline && '!h-[56px]',
          className,
        )}
        ref={containerRef}
      >
        {label ? (
          <span className="absolute -top-2 left-3 text-xs text-gray-500 bg-white px-1">
            {label}
          </span>
        ) : null}

        {showPlaceholder ? (
          <div className="absolute top-[1rem] text-gray-400 pointer-events-none whitespace-pre-wrap">
            {placeholder}
          </div>
        ) : null}

        <div
          className={clsx({
            'mr-8 w-full': true,
            'overflow-auto no-scrollbar': !multiline,
          })}
        >
          <div
            className={clsx({
              'w-full outline-none mt-1 leading-7 text-base h-full': true,
              'whitespace-nowrap': !multiline,
            })}
            contentEditable={disabled ? false : 'plaintext-only'}
            suppressContentEditableWarning
            ref={inputRef}
            onInput={handleInput}
            role="presentation"
            onClick={handleOnClick}
          />
        </div>

        <button
          className={clsx('absolute right-1 top-2.5 flex p-1.5 rounded', {
            'text-gray-400  !cursor-not-allowed': disabled,
            'text-blue-500 hover:bg-blue-500 hover:text-white': !disabled,
            '!hidden': !showPlusButton,
          })}
          disabled={disabled}
          onClick={() => {
            if (inputRef.current) {
              appendPlaceholderAtCurrentSelection(inputRef.current);
            }
            setShowVariableMenu(true);
          }}
          type="button"
        >
          <AddCircleOutlineOutlined className="!w-5 !h-5" />
        </button>
        {showVariableMenu ? (
          <VariablesMenu
            allowAddVariable={allowAddVariable}
            anchorEl={containerRef.current}
            onAddNew={() => {
              setShowVariableMenu(false);
              onClickAddNew?.();
            }}
            onClose={() => {
              setShowVariableMenu(false);
            }}
            onSelect={(maybeVariable) => {
              const check = Variable.safeParse(maybeVariable);
              if (check.success) {
                handleAddVariable(check.data);
              }
            }}
            open={showVariableMenu}
            variables={allowedVariables}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            globalVariables={allowedGlobalVariables}
          />
        ) : null}
      </div>
    );
  },
);

VariableInputLegacy.displayName = 'VariableInput';
