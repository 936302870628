import {
  LaunchIcon,
  Delete,
  IconButton,
  EditPencilIcon,
  Flex,
  Chip,
} from 'ui-kit';
import React, { useMemo } from 'react';
import {
  NodeTypesEnum,
  SourceTypeEnum,
  type SourceVariable,
  type WorkflowNode,
  WorkflowConditionalNode,
} from 'types-shared';
import {
  getRequestNodeTitle,
  getTriggerBlockShortTitle,
  type ScreenshotUrl,
} from '../utils';
import { isAdmin } from '../../../utils/env';
import { Link } from 'react-router-dom';

export default function ExecutionScreenshotDetails({
  hasError,
  currentNode,
  sourceVariable,
  receiverEmail,
  setShowUpdateErrModal,
  onDeleteScreenshot,
  workflowId,
  selectedScreenshot,
  loading,
  selectedIndex,
  screenshotUrlsLength,
}: {
  hasError: boolean;
  currentNode: WorkflowNode | undefined;
  completedStepsLength: number;
  sourceVariable: SourceVariable | undefined;
  receiverEmail: string | undefined;
  screenshotUrlsLength: number;
  selectedIndex: number | undefined;
  setShowUpdateErrModal: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteScreenshot?: (
    id: string,
    isImage: boolean,
    item: ScreenshotUrl,
  ) => void;
  selectedScreenshot?: ScreenshotUrl;
  workflowId?: string;
  loading?: boolean;
}) {
  const { nodeType, type, sortDataName, nodeId } = useMemo(() => {
    const payload: {
      nodeType?: ScreenshotUrl['nodeType'];
      type?: ScreenshotUrl['type'];
      sortDataName?: string;
      nodeId?: string;
    } = {};
    if (!selectedScreenshot?.sortData) return payload;

    payload.nodeType = selectedScreenshot.nodeType;
    payload.type = selectedScreenshot.type;
    payload.sortDataName =
      selectedScreenshot.sortData.name ?? selectedScreenshot.sortData.nodeId;
    payload.nodeId = selectedScreenshot.sortData.nodeId;

    return payload;
  }, [selectedScreenshot]);

  const nodeHeaderDetails = useMemo(() => {
    if (!currentNode) return null;

    switch (currentNode.type) {
      case NodeTypesEnum.Source:
        return sourceVariable?.data.sourceType ===
          SourceTypeEnum.EmailTrigger ? (
          <>
            <HeaderRow title="Type" value="Trigger" />
            <HeaderRow
              title="Name"
              value={getTriggerBlockShortTitle(sourceVariable)}
            />
            {receiverEmail ? (
              <HeaderRow title="Receiver email" value={receiverEmail} />
            ) : null}
          </>
        ) : null;

      case NodeTypesEnum.Request:
        return (
          <>
            <HeaderRow title="Type" value={getRequestNodeTitle(currentNode)} />
            <HeaderRow title="Name" value={currentNode.name} />
            <HeaderRow
              title="Description"
              value={currentNode.data.description}
            />
          </>
        );

      case NodeTypesEnum.Freeform:
        return (
          <>
            <HeaderRow title="Type" value="Freeform Step" />
            <HeaderRow title="Name" value={currentNode.name} />
          </>
        );
      case NodeTypesEnum.Conditional:
        return WorkflowConditionalNode.safeParse(currentNode).success ? (
          <>
            <HeaderRow
              title="Type"
              value={
                currentNode.errorOverlay ? 'Error Handler' : 'Conditional Step'
              }
            />
            <HeaderRow title="Name" value={currentNode.name} />
          </>
        ) : null;

      default:
        return null;
    }
  }, [currentNode, receiverEmail, sourceVariable]);

  return (
    <div className="flex flex-col space-y-2 p-9">
      <Flex alignItems="center" justifyContent="space-between">
        <Link
          to={`/editor/${workflowId ?? ''}${nodeType === NodeTypesEnum.Image ? `?focusNode=${nodeId ?? ''}` : ''}`}
          target="_blank"
          className="flex items-center space-x-2"
        >
          <p className="text-xl font-medium">
            Step{' '}
            {nodeId ? (
              <span className="text-base ml-1">{nodeId.slice(0, 5)}</span>
            ) : null}
          </p>
          {!loading ? <LaunchIcon className="!w-5 !h-5" /> : null}
          {currentNode?.hideFromUser && isAdmin ? (
            <Chip
              color="primary"
              label="Hidden"
              size="small"
              sx={{ height: 20, fontSize: '.65rem' }}
              variant="outlined"
            />
          ) : null}
        </Link>

        <div className="flex items-center space-x-3">
          {!loading &&
          isAdmin &&
          (selectedIndex === screenshotUrlsLength - 1 ||
            selectedIndex === -1) ? (
            <IconButton
              className="!p-0"
              onClick={() => {
                setShowUpdateErrModal(true);
              }}
            >
              <div className="flex space-x-2">
                <span className="text-red-900 text-sm font-medium">
                  {hasError ? 'Update' : 'Add'} Error
                </span>

                <EditPencilIcon className="!w-5 !h-5 text-red-900" />
              </div>
            </IconButton>
          ) : null}
          {isAdmin && !loading ? (
            <IconButton
              className="!p-0"
              onClick={() => {
                if (onDeleteScreenshot && sortDataName && selectedScreenshot) {
                  onDeleteScreenshot(
                    sortDataName,
                    type === 'blob',
                    selectedScreenshot,
                  );
                }
              }}
            >
              <Delete className="!w-5 !h-5 !text-red-500" />
            </IconButton>
          ) : null}
        </div>
      </Flex>
      {nodeHeaderDetails}
    </div>
  );
}

export function HeaderRow({ title, value }: { title: string; value?: string }) {
  if (!value) return null;
  return (
    <p className="text-sm font-medium">
      <span>{title}:</span> &nbsp;
      <span className="text-slate-500">{value}</span>
    </p>
  );
}
