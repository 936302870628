import { useMemo, useState, useEffect } from 'react';
import { getNodeActions, WorkflowImageNode } from 'types-shared';
import { IconButton, Clear } from 'ui-kit';
import { EditorStore } from '../../store/EditorState';
import type { SelectedAction } from './ActionsList';
import ActionsList from './ActionsList';
import EditAction from './EditAction';
import EditTarget from './EditTarget';
import MoveAction from './MoveAction';
import { useSearchParams } from 'react-router-dom';
import { useOnEscape } from '../../../../hooks/useOnEscape';
import { isAdmin } from '../../../../utils/env';

interface Props {
  setHasSelectedAction: (status: boolean) => void;
}

export default function ActionsManager({ setHasSelectedAction }: Props) {
  const [selectedActionIds, setSelectedActionIds] = useState<string[]>([]);
  const editorData = EditorStore();
  const [, setSearchParams] = useSearchParams();
  const { selectedNode, setSelectedNode, nodes } = editorData;
  const [selectedAction, setSelectedAction] = useState<SelectedAction | null>(
    null,
  );
  const [selectedTarget, setSelectedTarget] = useState<SelectedAction | null>(
    null,
  );
  const [actionToMove, setActionToMove] = useState<SelectedAction>();
  const [movingActions, setMovingActions] = useState(false);
  const [editingTargets, setEditingTargets] = useState(false);

  useOnEscape((e) => {
    e.stopPropagation();
    if (selectedAction) {
      setSelectedAction(null);
      return;
    }

    if (selectedNode) {
      setSearchParams({});
      setSelectedNode(null);
    }
  });

  useEffect(() => {
    if (selectedAction) {
      setHasSelectedAction(true);
    } else {
      setHasSelectedAction(false);
    }
  }, [selectedAction, setHasSelectedAction]);

  const node = useMemo(() => {
    const _selectedNode = nodes.find((_node) => _node.id === selectedNode);
    const parsedImageNode = WorkflowImageNode.safeParse(_selectedNode);
    if (parsedImageNode.success) {
      return parsedImageNode.data;
    }
    return null;
  }, [nodes, selectedNode]);

  const actions = useMemo(
    () =>
      node
        ? getNodeActions({
            ...node.data,
            isAdmin,
          })
        : [],
    [node],
  );

  const selectedActions = useMemo(() => {
    if (!actions.length || !selectedActionIds.length) {
      return [];
    }
    const selected = actions.filter((a) => selectedActionIds.includes(a.id));
    return selected;
  }, [actions, selectedActionIds]);

  const onClose = () => {
    setSearchParams({});
    setSelectedNode(null);
    setSelectedAction(null);
    setSelectedTarget(null);
    setActionToMove(undefined);
  };

  const onCloseEditTarget = () => {
    setSelectedTarget(null);
  };

  const onCloseMoveAction = () => {
    setActionToMove(undefined);
    setMovingActions(false);
  };

  const onEditTarget = (action: SelectedAction) => {
    setSelectedTarget(action);
  };

  const onMoveAction = (action: SelectedAction) => {
    setActionToMove(action);
  };

  const onFinishEditingTarget = (actionID: string) => {
    const isLast = selectedActionIds.length === 1;
    if (isLast) {
      setEditingTargets(false);
      setSelectedActionIds([]);
    } else {
      setSelectedActionIds((actionIds) =>
        actionIds.filter((a) => a !== actionID),
      );
    }
  };

  const { showActionsList, showMoveAction } = useMemo(() => {
    const payload = {
      showActionsList: false,
      showMoveAction: false,
      showEditTargets: false,
    };

    if (!node) return payload;

    payload.showActionsList =
      !selectedTarget &&
      !actionToMove &&
      !selectedAction &&
      !movingActions &&
      !editingTargets;
    payload.showMoveAction =
      Boolean(actionToMove) ||
      Boolean(movingActions && selectedActionIds.length);

    return payload;
  }, [
    node,
    selectedTarget,
    actionToMove,
    selectedAction,
    movingActions,
    selectedActionIds,
    editingTargets,
  ]);

  if (!node) {
    return null;
  }

  return (
    <div className="min-w-125 max-w-125 max-h-screen overflow-y-auto p-8 flex flex-col space-y-6 h-full bg-white relative">
      {showActionsList ? (
        <ActionsList
          node={node}
          onClose={onClose}
          onEditTarget={onEditTarget}
          onMoveAction={onMoveAction}
          onBulkMoveAction={() => {
            setMovingActions(true);
          }}
          onBulkEditTargets={() => {
            setEditingTargets(true);
          }}
          setSelectedAction={setSelectedAction}
          selectedActions={selectedActionIds}
          setSelectedActions={setSelectedActionIds}
          actions={actions}
        />
      ) : null}
      {selectedAction ? (
        <EditAction
          action={selectedAction}
          i={selectedAction.i}
          setSelectedAction={setSelectedAction}
        />
      ) : null}

      {selectedTarget ? (
        <EditTarget action={selectedTarget} onClose={onCloseEditTarget} />
      ) : null}

      {editingTargets ? (
        <>
          <div className="sticky top-0">
            <h1 className="flex items-center justify-between">
              <span className="font-medium text-xl">
                Editing multiple targets
              </span>

              <IconButton
                className="!ml-auto !-mr-3 !my-auto"
                onClick={() => {
                  setEditingTargets(false);
                  setSelectedActionIds([]);
                }}
              >
                <Clear className="!w-5 !h-5 !text-info" />
              </IconButton>
            </h1>
          </div>

          <div className="overflow-y-auto">
            {selectedActions.map((a, i) => (
              <EditTarget
                action={{ ...a, i: i + 1 }}
                onClose={() => {
                  onFinishEditingTarget(a.id);
                }}
                key={a.id}
                editingMultiple
              />
            ))}
          </div>
        </>
      ) : null}

      {showMoveAction ? (
        <MoveAction
          action={actionToMove}
          onClose={onCloseMoveAction}
          sourceNode={node}
          actions={selectedActions}
          actionIds={selectedActionIds}
          clearSelectedActions={() => {
            setSelectedActionIds([]);
          }}
        />
      ) : null}
    </div>
  );
}
