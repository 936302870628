import {
  Variable,
  VariableTypeEnum,
  SourceTypeEnum,
  GlobalVariable,
  type VariableMap,
  DocumentSourceEnum,
  ScrapeVariable,
  ExecutionVariable,
} from 'types-shared';
import { clsx } from 'clsx';
import {
  CalendarIcon,
  DocumentIcon,
  CloseIcon,
  LanguageOutlined,
  StarsIcon,
  Tooltip,
  DeleteOutlineIcon,
} from 'ui-kit';
import { useMemo } from 'react';
import { checkIfVariableHasTransformations } from '../pages/Editor/utils/helper';
import {
  executionVariableSubTypeMapping,
  executionVariableTitleMapping,
} from '../pages/Editor/utils/constants';
import { isAdmin } from '../utils/env';
import { deletedVariableSvg } from './CustomMentionBlot/MentionSvgs';

export const defaultVariableChipClasses = clsx({
  '!normal-case bg-blue-600 rounded-full px-2 py-1 text-white !text-xs cursor-pointer inline-flex min-w-max':
    true,
  'max-w-[10rem] !truncate': true,
  'gap-1 items-center': true,
});

export function getVariableChipClasses(
  variable: Variable,
  disabled = false,
  bordered = false,
  deletable = false,
) {
  const isApiCallChip =
    variable.type === VariableTypeEnum.Source &&
    variable.data.sourceType === SourceTypeEnum.API;

  const isDocumentScrape =
    variable.type === VariableTypeEnum.Document &&
    variable.data.source === DocumentSourceEnum.Execution;

  const isExecutionVariable = variable.type === VariableTypeEnum.Execution;
  const hasExecutionIcon =
    isExecutionVariable &&
    executionVariableSubTypeMapping[variable.name] === 'Date';
  const isGlobalVar = variable.type === VariableTypeEnum.Global;

  const hasLeftIcon = isGlobalVar || hasExecutionIcon || isDocumentScrape;

  return clsx(defaultVariableChipClasses, {
    'pl-3 pr-1': deletable && !hasLeftIcon,
    'pl-6': hasLeftIcon && !deletable,
    'pl-6 pr-1': deletable && hasLeftIcon,
    'shadow-[0px_0px_0px_3px_rgba(33,150,252,0.5)]': bordered,
    '!bg-secondary-purple':
      isDocumentScrape || variable.type === VariableTypeEnum.Scrape,
    '!bg-white !border-secondary-blue !border-2 !text-secondary-blue':
      isApiCallChip,
    'opacity-75 !cursor-default': disabled,
    '!bg-navy-blue': isExecutionVariable,
  });
}

export function getVariableChipContent(variable?: Variable) {
  if (!variable) {
    return '';
  }
  if (
    variable.type === VariableTypeEnum.Source &&
    variable.data.sourceType === SourceTypeEnum.API
  ) {
    return 'API Trigger';
  } else if (
    variable.type === VariableTypeEnum.Source &&
    variable.data.sourceType === SourceTypeEnum.EmailTrigger
  ) {
    return 'Email Trigger';
  } else if (
    variable.type === VariableTypeEnum.Source &&
    variable.data.sourceType === SourceTypeEnum.Request
  ) {
    return `${(variable.name ?? 'Un-named variable').trim()} Response`;
  } else if (
    variable.type === VariableTypeEnum.Document &&
    variable.data.source === DocumentSourceEnum.Execution
  ) {
    return (variable.name ?? 'Document-1').trim();
  } else if (variable.type === VariableTypeEnum.Execution) {
    return variable.name
      ? executionVariableTitleMapping[variable.name]
      : 'Execution Variable';
  }

  return (variable.name ?? 'Un-named variable').trim();
}

const leftIconClasses = 'absolute top-1.2 left-1';

interface Props {
  variableId: string;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  disabled?: boolean;
  className?: string;
  bordered?: boolean;
  onRemove?: () => void;
  onDelete?: () => void;
}

export function VariableChip({
  variableId,
  variablesMap,
  globalVariablesMap,
  onClick,
  disabled,
  className,
  bordered,
  onRemove,
  onDelete,
}: Props) {
  const {
    variable,
    hasTransformations,
    isGlobalVar,
    isScrapeVar,
    showCalendarIcon,
    isDocumentScrape,
    allowDelete,
  } = useMemo(() => {
    const payload: {
      variable?: Variable;
      hasTransformations: boolean;
      isGlobalVar: boolean;
      isScrapeVar: boolean;
      showCalendarIcon: boolean;
      allowDelete: boolean;
      isDocumentScrape: boolean;
    } = {
      variable: undefined,
      hasTransformations: false,
      isGlobalVar: false,
      isScrapeVar: false,
      showCalendarIcon: false,
      isDocumentScrape: false,
      allowDelete: Boolean(onDelete) && isAdmin,
    };

    const globalVariable = globalVariablesMap[variableId];
    const otherVariable = variablesMap[variableId];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- will not always exist in the global variables.
    const maybeVariable = globalVariable ?? otherVariable;

    const variableCheck = Variable.safeParse(maybeVariable);
    if (variableCheck.success) {
      payload.variable = maybeVariable;
      payload.hasTransformations =
        checkIfVariableHasTransformations(maybeVariable);

      payload.isGlobalVar = GlobalVariable.safeParse(maybeVariable).success;
      payload.isScrapeVar = ScrapeVariable.safeParse(maybeVariable).success;

      payload.showCalendarIcon = Boolean(
        ExecutionVariable.safeParse(maybeVariable).success &&
          maybeVariable.name &&
          executionVariableSubTypeMapping[maybeVariable.name] === 'Date',
      );

      payload.isDocumentScrape =
        maybeVariable.type === VariableTypeEnum.Document &&
        maybeVariable.data.source === DocumentSourceEnum.Execution;
    }

    return payload;
  }, [variablesMap, globalVariablesMap, variableId, onDelete]);

  if (!variable) {
    return (
      <div className={clsx(defaultVariableChipClasses, '!bg-error')}>
        Deleted Variable
        <span
          className="relative w-4 h-4 bottom-1 -right-1"
          dangerouslySetInnerHTML={{ __html: deletedVariableSvg }}
        />
      </div>
    );
  }

  return (
    <span
      className={clsx(
        getVariableChipClasses(variable, disabled, bordered, allowDelete),
        'relative',
        className,
      )}
      role="presentation"
      onClick={Boolean(disabled) || isGlobalVar ? undefined : onClick}
    >
      <Tooltip
        arrow
        hidden={!hasTransformations}
        placement="right"
        title={
          isScrapeVar
            ? 'This scrape uses GPT transformations'
            : 'This variable uses GPT transformations'
        }
      >
        <>
          {isDocumentScrape ? (
            <DocumentIcon className={clsx('!text-lg', leftIconClasses)} />
          ) : null}
          {showCalendarIcon ? (
            <CalendarIcon sx={{ fontSize: 15 }} className={leftIconClasses} />
          ) : null}

          {isGlobalVar ? (
            <LanguageOutlined
              sx={{ fontSize: 15 }}
              className={leftIconClasses}
            />
          ) : null}

          {getVariableChipContent(variable)}

          {hasTransformations ? (
            <StarsIcon className="!text-xs !ml-0.5" />
          ) : null}
          {onDelete && isAdmin ? (
            <span
              className="rounded-full hover:bg-gray-200/10 mr-0.5 flex"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDelete();
              }}
              role="presentation"
            >
              <DeleteOutlineIcon className="!text-base text-white" />
            </span>
          ) : null}
          {onRemove ? (
            <span
              className="rounded-full hover:bg-gray-200/10 scale-125"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onRemove();
              }}
              role="presentation"
            >
              <CloseIcon className="!text-lg text-white" />
            </span>
          ) : null}
        </>
      </Tooltip>
    </span>
  );
}
