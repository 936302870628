import { CloseIcon, Button, SubLabel } from 'ui-kit';

import React from 'react';
import {
  type TemplateData,
  type Variable,
  type WorkflowSourceNode,
  type GlobalVariable,
  type VariableMap,
} from 'types-shared';
import { useSourceVariable } from '../../../hooks/useSourceVariable';
import { getTriggerBlockTitle } from './trigger.helpers';
import { TriggerBlockData } from './TriggerBlockData';

interface Props {
  node: WorkflowSourceNode;
  onCancel: () => void;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  updateVariable: (variable: Variable) => void;
  addVariable: (variable: Variable) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    query: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
}

export function TriggerBlockCustomer({
  onCancel,
  node,
  variablesMap,
  globalVariablesMap,
  ...props
}: Props) {
  const { sourceVariable } = useSourceVariable(variablesMap);

  if (!sourceVariable) return null;

  return (
    <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
      <div className="overflow-auto p-8">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">Trigger</span>
          <Button
            className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            <CloseIcon className="text-info" />
          </Button>
        </div>
        <div className="my-6">
          <h2 className="text-cyan-900 text-lg font-medium leading-relaxed tracking-tight">
            {getTriggerBlockTitle(node, sourceVariable)}
          </h2>
        </div>
        <div className="flex-1 flex flex-col gap-4">
          {node.data.description ? (
            <div className="grow flex-col pt-1.5 bg-gray-100 rounded-tl-lg rounded-tr-lg w-full">
              <p className="text-xs text-gray-500 px-3">Description</p>
              <p className="text-base text-gray-500 mt-1 px-3 mb-1.5 break-all">
                {node.data.description}
              </p>
              <div className="border border-dashed border-slate-300" />
            </div>
          ) : null}

          <SubLabel>
            The data in emails are stored as variables, which can be used in
            later parts of the workflow. You can also query them to extract data
            and create new variables.
          </SubLabel>

          <TriggerBlockData
            node={node}
            sourceVariable={sourceVariable}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            {...props}
          />
        </div>
      </div>
    </div>
  );
}
