import {
  type ExecutionVariables,
  GmailTriggerVariableEnum,
  type WorkflowSourceNode,
} from 'types-shared';
import { useMemo } from 'react';
import { Label } from 'ui-kit';
import { Output } from './RecordOutputs';
import { extractFilename, extractSourceOutputsData } from '../../../utils';
import type { ExecutionDocument } from 'api-types-shared';

interface Props {
  node: WorkflowSourceNode;
  executionVariables: ExecutionVariables;
  onDownloadLinkData?: (url: string) => void;
  executionArtifacts: ExecutionDocument[];
}

const customTitleMap = {
  From: 'Email from',
} as Record<string, string>;

export function SourceOutputs({
  executionVariables,
  onDownloadLinkData,
  executionArtifacts,
}: Props) {
  const outputs = useMemo(() => {
    const allOutputs = extractSourceOutputsData(executionVariables);
    const allPossibleTitles = Object.values(GmailTriggerVariableEnum);
    return allOutputs.filter((o) =>
      allPossibleTitles.includes(o.title as never),
    );
  }, [executionVariables]);

  return (
    <div className="flex flex-col gap-4 py-4 px-6 overflow-auto">
      {outputs.map(({ id, title, description, scrapeText }) => {
        const attachmentArtifacts =
          title === 'Attachments'
            ? executionArtifacts.filter((e) => description.includes(e.s3Key))
            : undefined;

        return (
          <div
            className="rounded-lg border border-slate-300 px-4 py-4 flex-col flex space-y-6"
            key={id}
          >
            <Label>
              {customTitleMap[title] ? customTitleMap[title] : title}
            </Label>

            {scrapeText ? (
              <>
                <Label>Extracted data</Label>
                <div
                  className="!mt-2 text-slate-500 text-sm break-all"
                  dangerouslySetInnerHTML={{ __html: scrapeText }}
                />
              </>
            ) : null}

            {description ? (
              <>
                <Label>Content</Label>
                {attachmentArtifacts?.map((attachmentArtifact) => (
                  <Output
                    className="!max-w-[100%] !mt-2"
                    action="download"
                    description={extractFilename(attachmentArtifact.s3Key)}
                    key={id}
                    title={`Download ${title}`} // TODO(neil): Fix naming scheme for downloads on backend schema
                    onDownloadLinkData={onDownloadLinkData}
                    uri={attachmentArtifact.uri}
                  />
                ))}
                {description && !attachmentArtifacts?.length ? (
                  <div
                    className="text-slate-500 text-sm !mt-2 break-all [&>a]:text-blue-700 [&>a]:underline"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                ) : null}
              </>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
