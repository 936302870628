"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowWebhook = void 0;
var zod_1 = require("zod");
exports.WorkflowWebhook = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    webhookUrl: zod_1.z.string(),
    headers: zod_1.z.record(zod_1.z.string()),
});
