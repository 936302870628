import './config/dayjs';
import './config/quill';
import './index.css';
import 'ui-kit/dist/tailwind.css';
import 'types-shared/reactflow.css';

import CssBaseline from '@mui/material/CssBaseline';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { AlertWrapper, ModalContainer, UIKITProvider } from 'ui-kit';
import { ApolloProvider } from '@apollo/client';
/** Services */
import { queryClient } from './config/react-query';
import { createApolloClient } from './config/apollo';
import { APIProvider } from './hooks/useApi';
import { useAnalytics } from './hooks/useAnalytics';
import { router } from './router';
import theme from './theme';
import { useAuth } from './utils/helper';
import { adminAccessToken, auth0Domain, isAdmin } from './utils/env';
import ContactModal from './components/ContactModal';
import VariableModal from './components/VariableModal';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';

export default function Initializer() {
  const { getAccessTokenSilently, logout } = useAuth();
  const apolloClient = createApolloClient(getAccessTokenSilently);

  useAnalytics();

  return (
    <UIKITProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <ErrorBoundary>
        <ModalContainer />
        <AlertWrapper />
        <ApolloProvider client={apolloClient}>
          <APIProvider
            initialState={{
              kyOpts: {
                timeout: 30000, // TODO(rafic) remove this once pagination is implemented
                hooks: {
                  ...(isAdmin
                    ? {}
                    : {
                        afterResponse: [
                          async (_input, _options, response) => {
                            if (response.status === 401) {
                              await logout({
                                logoutParams: {
                                  returnTo: window.location.origin,
                                },
                              });
                            }
                          },
                        ],
                      }),
                  beforeRequest: [
                    async (request) => {
                      let accessToken = await getAccessTokenSilently();
                      if (isAdmin && adminAccessToken) {
                        accessToken = adminAccessToken;
                      }
                      if (auth0Domain) {
                        request.headers.set(
                          'Authorization',
                          `Bearer ${accessToken}`,
                        );
                      }
                    },
                  ],
                },
              },
            }}
          >
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
              <ContactModal />
              <VariableModal />
            </QueryClientProvider>
          </APIProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </UIKITProvider>
  );
}
