import { MentionBlot, type MentionBlotData } from 'quill-mention';
import { type Variable, VariableTypeEnum } from 'types-shared';
import { executionVariableTitleMapping } from '../../pages/Editor/utils/constants';
import { checkIfVariableHasTransformations } from '../../pages/Editor/utils/helper';
import Clipboard from 'quill/modules/clipboard';
import type Quill from 'quill/core';
import { Delta, type Range } from 'quill/core';
import {
  transformationSvg,
  globalVariableIcon,
  executionIcon,
  documentIcon,
  deletedVariableSvg,
} from './MentionSvgs';
import isNil from 'lodash/isNil';

export const VARIABLE_ID_DATA_ATTRIBUTE = 'data-variable-id';

const variableChipClasses = [
  'text-white',
  'rounded-full',
  'px-2',
  'py-1',
  'inline-flex',
  'my-0.5',
  'cursor-pointer',
  'mx-0.5',
  '!whitespace-nowrap',
];

// Create a free floating tooltip that is positioned dynamically next to the variable chip
const createTooltip = ({
  text,
  node,
  width,
}: {
  text: string;
  node: HTMLElement;
  width: string;
}) => {
  const tooltip = document.createElement('span');
  tooltip.classList.add('tooltiptext');
  tooltip.style.width = width;
  tooltip.style.position = 'absolute';
  tooltip.style.zIndex = '9999';
  tooltip.style.pointerEvents = 'none';
  tooltip.style.opacity = '0';
  tooltip.style.transition = 'opacity 0.3s ease';
  tooltip.style.display = 'none';

  tooltip.innerText = text;

  document.body.appendChild(tooltip);

  node.addEventListener('mouseenter', () => {
    const nodeRect = node.getBoundingClientRect();
    const tooltipRect = tooltip.getBoundingClientRect();

    // Calculate vertical offset to center tooltip
    const topOffset = (nodeRect.height - tooltipRect.height) / 2;

    // Position the tooltip vertically centered and slightly to the right of the node
    tooltip.style.top = `${(nodeRect.top + window.scrollY - topOffset).toString()}px`;
    tooltip.style.left = `${(nodeRect.right + 10 + window.scrollX).toString()}px`;

    tooltip.style.display = 'block';
    requestAnimationFrame(() => {
      tooltip.style.opacity = '1'; // Fade in.
    });
  });

  node.addEventListener('mouseleave', () => {
    tooltip.style.opacity = '0'; // Fade out.
    tooltip.addEventListener(
      'transitionend',
      () => {
        tooltip.style.display = 'none';
      },
      { once: true },
    );
  });

  return tooltip;
};

interface VariableBlotData extends MentionBlotData {
  name: string;
  id: string;
  onClickVariableChip: (id: string) => void;
  type: VariableTypeEnum;
  variable: Variable;
}

export class VariableBlot extends MentionBlot {
  static create(value: VariableBlotData) {
    const node = super.create(value) as HTMLElement;
    const thisHasTransformations = checkIfVariableHasTransformations(
      value.variable,
    );

    if (thisHasTransformations) {
      createTooltip({
        text: 'This variable uses GPT transformations',
        node,
        width: '150px',
      });
      node.style.position = 'relative';
      node.style.paddingRight = '25px';
      // node.innerHTML = `<span contenteditable="false">${value.name}</span>${transformationSvg}${tooltip}`;
      node.innerHTML = `<span contenteditable="false">${value.name}</span>${transformationSvg}`;
    } else {
      node.innerHTML = value.name;
    }
    node.style.fontSize = '12px';
    node.setAttribute(VARIABLE_ID_DATA_ATTRIBUTE, value.id);
    node.setAttribute('contenteditable', 'false');
    node.classList.add(...variableChipClasses, 'bg-[#2563eb]');

    return node;
  }
}

export class GlobalVariableBlot extends MentionBlot {
  static create(value: VariableBlotData) {
    const node = super.create(value) as HTMLElement;

    createTooltip({
      text: 'This is a shared variable. To preview or edit, go to the shared variables section.',
      node,
      width: '250px',
    });

    // node.innerHTML = `${icon}<span contenteditable="false">${value.name}</span>${tooltip}`;
    node.innerHTML = `${globalVariableIcon}<span contenteditable="false">${value.name}</span>`;

    node.setAttribute(VARIABLE_ID_DATA_ATTRIBUTE, value.id);
    node.setAttribute('contenteditable', 'false');

    node.classList.add(
      ...variableChipClasses.filter((c) => c !== 'cursor-pointer'),
      'cursor-not-allowed',
      'bg-[#2563eb]',
      'pl-2',
    );
    node.style.position = 'relative';
    node.style.fontSize = '12px';
    node.style.paddingLeft = '25px';

    return node;
  }
}

export class ExecutionVariableBlot extends MentionBlot {
  static create(value: VariableBlotData) {
    const node = super.create(value) as HTMLElement;

    const variableName = value.name
      ? executionVariableTitleMapping[value.name]
      : 'Execution Variable';

    const thisHasTransformations = checkIfVariableHasTransformations(
      value.variable,
    );

    if (thisHasTransformations) {
      createTooltip({
        text: 'This variable uses GPT transformations',
        node,
        width: '150px',
      });
    }

    const hasExecutionIcon = variableName.toLowerCase().includes('date');
    const showIcon = hasExecutionIcon || thisHasTransformations;
    node.style.fontSize = '12px';
    node.innerHTML = `${hasExecutionIcon ? executionIcon : ''}<span contenteditable="false">${variableName}</span>${thisHasTransformations ? transformationSvg : ''}`;
    node.setAttribute(VARIABLE_ID_DATA_ATTRIBUTE, value.id);
    node.setAttribute('contenteditable', 'false');

    node.classList.add(
      ...variableChipClasses,
      'bg-[#0f3d61]',
      ...(hasExecutionIcon ? ['pl-2'] : []),
      ...(thisHasTransformations ? ['pr-2'] : []),
    );

    if (showIcon) {
      node.style.position = 'relative';

      if (hasExecutionIcon) {
        node.style.paddingLeft = '25px';
      }
      if (thisHasTransformations) {
        node.style.paddingRight = '25px';
      }
    }

    return node;
  }
}

export class DocumentVariableBlot extends MentionBlot {
  static create(value: VariableBlotData) {
    const node = super.create(value) as HTMLElement;

    //  eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    node.innerHTML = `${documentIcon}<span contenteditable="false">${value.value ?? 'Document-1'}</span>`;

    node.setAttribute(VARIABLE_ID_DATA_ATTRIBUTE, value.id);
    node.setAttribute('contenteditable', 'false');
    node.style.fontSize = '12px';
    node.classList.add(...variableChipClasses, '!bg-secondary-purple', 'pl-2');
    node.style.position = 'relative';
    node.style.paddingLeft = '25px';

    return node;
  }
}

export class ScrapeVariableBlot extends MentionBlot {
  static create(value: VariableBlotData) {
    const node = super.create(value) as HTMLElement;
    const thisHasTransformations = checkIfVariableHasTransformations(
      value.variable,
    );

    if (thisHasTransformations) {
      createTooltip({
        text: 'This scrape uses GPT transformations',
        node,
        width: '150px',
      });
      node.style.position = 'relative';
      node.style.paddingRight = '25px';
      // node.innerHTML = `<span contenteditable="false">${value.name}</span>${transformationSvg}${tooltip}`;
      node.innerHTML = `<span contenteditable="false">${value.name}</span>${transformationSvg}`;
    } else {
      node.innerHTML = value.name;
    }
    node.style.fontSize = '12px';
    node.setAttribute(VARIABLE_ID_DATA_ATTRIBUTE, value.id);
    node.setAttribute('contenteditable', 'false');

    node.classList.add(...variableChipClasses, '!bg-secondary-purple');

    return node;
  }
}

export class DeletedVariableBlot extends MentionBlot {
  static create(value: VariableBlotData) {
    const node = super.create(value) as HTMLElement;

    node.style.position = 'relative';
    node.style.paddingRight = '25px';
    node.setAttribute(VARIABLE_ID_DATA_ATTRIBUTE, value.id);
    node.innerHTML = `<span contenteditable="false">Deleted Variable</span>${deletedVariableSvg}`;
    node.style.fontSize = '12px';
    node.setAttribute('contenteditable', 'false');

    node.classList.add(...variableChipClasses, '!bg-error');

    return node;
  }
}

export default class CustomMentionBlot extends MentionBlot {
  static create(value: VariableBlotData) {
    if (isNil(value.type)) {
      return DeletedVariableBlot.create(value);
    }

    let finalValue;
    switch (value.type) {
      case VariableTypeEnum.Global:
        finalValue = GlobalVariableBlot.create(value);
        break;
      case VariableTypeEnum.Document:
        finalValue = DocumentVariableBlot.create(value);
        break;
      case VariableTypeEnum.Scrape:
        finalValue = ScrapeVariableBlot.create(value);
        break;
      case VariableTypeEnum.Execution:
        finalValue = ExecutionVariableBlot.create(value);
        break;
      default:
        finalValue = VariableBlot.create(value);
        break;
    }

    const node = finalValue;
    node.addEventListener('click', (event) => {
      event.stopPropagation();
      event.preventDefault();
      const variableId = value.id;
      if (value.type !== VariableTypeEnum.Global) {
        value.onClickVariableChip(variableId);
      }
    });

    return node;
  }
}

// Disable pasting formatted text and disable new lines if multiline is disabled
type ClipboardConstructorParams = ConstructorParameters<typeof Clipboard>;
type ClipboardOptions = ClipboardConstructorParams[1];
export class PlainClipboard extends Clipboard {
  constructor(
    quill: Quill,
    options: Partial<ClipboardOptions>,
    private _multiline: boolean,
  ) {
    super(quill, options);
  }

  onPaste(range: Range, { text }: { text?: string; html?: string }): void {
    if (!text) return;
    let finalText = text;

    // Check if multiline is false, strip new lines from the pasted content
    if (!this._multiline) {
      finalText = text.replace(/\r?\n|\r/g, ' ');
    }

    const oldDelta = this.quill.getContents();
    const delta: Delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(finalText);

    const index = finalText.length + range.index;
    const length = 0;

    this.quill.updateContents(delta);
    this.quill.setSelection(index, length);

    // Trigger the text change event
    this.quill.emitter.emit('text-change', delta, oldDelta, 'api');
  }
}
