import {
  CloseIcon,
  InfoOutlined,
  WarningAmberOutlined,
  AlertVariant,
  Button,
  notify,
} from 'ui-kit';
import React, { useState } from 'react';
import { type WorkflowContinueNode } from 'types-shared';
import { isAdmin } from '../../../../utils/env';

interface Props {
  node: WorkflowContinueNode;
  onCancel: () => void;
  onImport?: (replaceNodeId?: string) => Promise<boolean>;
}

export function AddRecordingBlock({ onCancel, onImport, node }: Props) {
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [hasImportError, setHasImportError] = useState<boolean>(false);

  const handleImport = async () => {
    setHasImportError(false);
    setIsImporting(true);
    try {
      const success = await onImport?.(node.id);
      if (!success) {
        return;
      }
      if (isAdmin) {
        onCancel();
      }
      notify({
        message: 'Your recording was imported successfully.',
        variant: AlertVariant.SUCCESS,
      });
      setHasImportError(false);
    } catch {
      setHasImportError(true);
    } finally {
      setIsImporting(false);
    }
  };

  return (
    <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col justify-between space-y-5">
      <div className="overflow-visible">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">
            Import new recording
          </span>
          <Button
            className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            <CloseIcon className="text-info" />
          </Button>
        </div>
        <div className="my-6">
          <h2 className="text-lg font-medium">Add recording</h2>
          <p className="text-sm font-normal mt-2 text-info-dark">
            Add more steps to your workflow by importing a recording, starting
            from this step.
          </p>
        </div>
        <h2 className="my-8 text-sm font-bold">How it works</h2>
        <ol className="text-sm space-y-4 list-decimal	list-inside">
          <li>
            Use our Chrome extension to record the new steps you want to add.
            It’s fine if you capture a few extra steps before or after – our AI
            will intelligently link this recording into your existing workflow.
          </li>
          <li>
            Use the <b>Import Recording</b> button below to import in the new
            recording.
          </li>
          <li>
            You will be redirected to the dashboard and the workflow will take
            some time to process.
          </li>
          <li>
            Once the workflow has finished processing, you will be notified and
            be able to configure it again!
          </li>
        </ol>
      </div>
      <div className="flex flex-col space-y-6 w-full">
        {isImporting && !hasImportError ? (
          <div className="bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded text-sm">
            <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
            <span className="text-primary-blue-dark">
              Importing in progress... you will be redirected to the dashboard.
            </span>
          </div>
        ) : null}
        {hasImportError ? (
          <div className="bg-[#FFF4E5] flex space-x-2 px-4 py-3 rounded text-sm">
            <WarningAmberOutlined className="!w-5 !h-5 !text-warning !mt-0.5" />
            <span className="text-[#663C00]">
              There is no workflow to import yet. Use our Chrome extension to
              record more steps.
            </span>
          </div>
        ) : null}
        <div className="flex w-full gap-6 actions">
          <Button
            className="!flex-1"
            color="secondary"
            loading={isImporting}
            onClick={handleImport}
            variant="contained"
          >
            Import Recording
          </Button>
          <Button
            className="!flex-1"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
