import { useState } from 'react';
import { Button, ContentContainer, DataLoader, CustomTypography } from 'ui-kit';
import { useExtensionVersion } from './hooks';
import { downloadLinkData } from '../../utils/helper';
import { NavigationPanel } from '../../components/NavigationPanel';

export default function Extension() {
  const [downloadAnimating, setDownloadAnimating] = useState<boolean>(false);

  const { data: versionInfo, isFetching, refetch } = useExtensionVersion();

  const downloadExtension = async () => {
    if (versionInfo) {
      setDownloadAnimating(true);

      const { data: updatedVersionInfo } = await refetch();

      if (updatedVersionInfo) {
        downloadLinkData(
          updatedVersionInfo.url,
          `sola-extension-v${updatedVersionInfo.version}.zip`,
        );
      }

      setTimeout(() => {
        setDownloadAnimating(false);
      }, 1500);
    }
  };

  const isFetchingVersionInfo = isFetching && !versionInfo;

  const renderContent = () => {
    if (!versionInfo)
      return (
        <CustomTypography variant="subtitle2">
          Failed to fetch version info
        </CustomTypography>
      );

    return (
      <>
        <div>
          <CustomTypography variant="h4">Extension</CustomTypography>
          <div className="mt-2">
            <span className="font-normal text-sm">
              Use the Sola chrome extension to record a new workflow.
            </span>
          </div>
        </div>

        <div className="mt-8 flex gap-x-4">
          <div className="flex-1">
            <span className="text-lg  font-medium">
              How to install our extension?
            </span>

            <ol className="list-decimal text-sm space-y-3 mt-8 ml-4">
              <li>You need to download the latest version of the extension.</li>
              <li>
                In your Downloads, you will see a compressed file. Unzip the
                file.
              </li>
              <li>
                Visit <b>chrome://extensions/</b>, and turn on developer mode in
                the top right corner.
              </li>

              <li>Click on "Load unpackaged"</li>
              <li>
                Select the folder you previously unzipped. The extension is now
                installed in your browser!
              </li>
              <li>
                If you click on the puzzle icon in the top right of your browser
                you can see all your extensions. Find Sola and pin it so that it
                is easily accessible.
              </li>
            </ol>
          </div>
          <div className="flex-1">
            <div className="rounded-2xl bg-gray-50 p-12 flex flex-col">
              <span className="text-lg font-medium">
                Download Sola Chrome Extension to record workflows
              </span>

              <span className="mt-4">Version {versionInfo.version}</span>
              <div className="mt-4">
                <Button
                  color="secondary"
                  loading={downloadAnimating}
                  onClick={downloadExtension}
                  variant="contained"
                >
                  Download Extension
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="w-full flex">
      <NavigationPanel />
      <ContentContainer>
        {isFetchingVersionInfo ? (
          <DataLoader loadingText="Fetching latest version" />
        ) : (
          renderContent()
        )}
      </ContentContainer>
    </div>
  );
}
