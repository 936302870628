import React from 'react';
import { type ExecutionBase } from 'types-shared/executionTypes';
import { CustomFullscreenExitIcon, Button } from 'ui-kit';

export function FullscreenHeader({
  workflowName,
  setIsFullscreen,
  visible,
}: {
  workflowName: string | undefined;
  isAPITrigger: boolean;
  executionMetadata: ExecutionBase;
  _selectedIndex: number;
  screenshotUrlsLength: number;
  visible: boolean;
  setIsFullscreen: (value: React.SetStateAction<boolean>) => void;
}) {
  return visible ? (
    <div className="border-b px-8 py-5 flex justify-between items-center">
      <span className="text-sm">{workflowName ?? 'Workflow'}</span>
      <Button
        className="!p-2 !min-w-min !rounded-lg !bg-white !border-info"
        color="primary"
        onClick={() => {
          setIsFullscreen((val) => !val);
        }}
        variant="outlined"
      >
        <CustomFullscreenExitIcon className="!w-5 !h-5 !text-info" />
      </Button>
    </div>
  ) : null;
}
