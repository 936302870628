import { useState, useMemo } from 'react';
import type { SelectedAction } from './ActionsList';
import { Button } from 'ui-kit';
import { EditorStore } from '../../store/EditorState';
import {
  NodeTypesEnum,
  type WorkflowAction,
  type WorkflowImageNode,
  type WorkflowNode,
} from 'types-shared';
import { ActionHeader } from './ActionHeader';
import { removeNode } from '../../utils/helper';
import { useSearchParams } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface Props {
  action?: SelectedAction;
  actions: WorkflowAction[];
  actionIds: string[];
  sourceNode: WorkflowImageNode;
  onClose: () => void;
  clearSelectedActions: () => void;
}

function MoveAction({
  action,
  onClose,
  sourceNode,
  actions,
  actionIds,
  clearSelectedActions,
}: Props) {
  const handlingMultiple = !action && actions.length;
  const {
    nodes,
    setNodes,
    selectedNode,
    setSelectedNode,
    variables,
    globalVariables,
    edges,
    setEdges,
  } = EditorStore();

  const [, setSearchParams] = useSearchParams();
  const [chosenNode, setChosenNode] = useState<string | null>(null);

  const options = useMemo(
    () =>
      nodes.reduce((acc: string[], node) => {
        if (node.id !== sourceNode.id) {
          acc.push(node.id);
        }
        return acc;
      }, []),
    [nodes, sourceNode.id],
  );

  const onMove = () => {
    const destination = nodes.find(
      (_node) => _node.id === chosenNode && _node.type === NodeTypesEnum.Image,
    );
    const source = { ...sourceNode };
    const sourceActionData = new Map(Object.entries(source.data.actionData));

    if (action) {
      sourceActionData.delete(action.id);
    }
    if (handlingMultiple) {
      actions.forEach((a) => sourceActionData.delete(a.id));
    }

    const updatedNodes = nodes.map((_node) => {
      if (
        _node.type !== NodeTypesEnum.Image ||
        !destination ||
        destination.type !== NodeTypesEnum.Image
      )
        return _node;

      if (_node.id === destination.id) {
        return {
          ..._node,
          data: {
            ..._node.data,
            actionData: {
              ...destination.data.actionData,
              ...(action ? { [action.id]: action } : {}),
              ...(handlingMultiple
                ? Object.assign({}, ...actions.map((a) => ({ [a.id]: a })))
                : {}),
            } as WorkflowImageNode['data']['actionData'],
            actionOrder: [
              ...destination.data.actionOrder,
              ...(action ? [action.id] : []),
              ...(handlingMultiple ? actionIds : []),
            ],
          },
        };
      }
      if (_node.id === source.id) {
        return {
          ..._node,
          data: {
            ..._node.data,
            actionData: Object.fromEntries(sourceActionData),
            actionOrder: source.data.actionOrder.filter((id) => {
              if (action) {
                return id !== action.id;
              }
              if (handlingMultiple) {
                return !actionIds.includes(id);
              }
              return true;
            }),
          },
        };
      }
      return _node;
    });

    setNodes(updatedNodes);
    onClose();
    if (handlingMultiple) {
      clearSelectedActions();
    }
  };

  const selectedNodeData: WorkflowNode | undefined = useMemo(() => {
    return nodes.find((_node) => _node.id === selectedNode);
  }, [nodes, selectedNode]);

  const selectedVariable = action?.variableId
    ? variables[action.variableId] ?? globalVariables?.[action.variableId]
    : null;

  const onDeleteNode = () => {
    if (!selectedNode) return;
    const { nodes: filteredNodes, edges: filteredEdges } = removeNode(
      nodes,
      edges,
      selectedNode,
    );
    setSearchParams({});
    setSelectedNode(null);
    setNodes(filteredNodes);
    setEdges(filteredEdges);
  };

  return (
    <>
      <ActionHeader
        handleOnClose={onClose}
        node={selectedNodeData}
        selectedAction={action}
        selectedVariable={selectedVariable}
        setSelectedNode={setSelectedNode}
        onDeleteNode={onDeleteNode}
      />
      <div className="flex-1 flex flex-col my-10 border rounded-lg px-4 py-6">
        <div className="flex justify-between items-center">
          <h2 className="flex items-center space-x-3">
            <span className="text-xs text-white rounded-full h-6 w-6 flex justify-center items-center bg-gray-800">
              {action?.i ?? 'X'}
            </span>
            <span className="font-medium text-lg">
              {action?.actionType ?? 'Multiple actions'}
            </span>
          </h2>
        </div>
        <div className="flex flex-col mt-3">
          <Autocomplete
            className="custom-autocomplete"
            disablePortal
            options={options}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Destination Step" />
            )}
            onChange={(_, value) => {
              setChosenNode(value);
            }}
          />
        </div>
        <div className="flex flex-row justify-between gap-4 mt-auto">
          <Button
            className="flex-1"
            color="secondary"
            disabled={!chosenNode}
            onClick={onMove}
            variant="contained"
          >
            Move
          </Button>
          <Button
            className="flex-1"
            color="secondary"
            onClick={onClose}
            variant="outlined"
          >
            CANCEL
          </Button>
        </div>
      </div>
    </>
  );
}

export default MoveAction;
