import { useState, type ReactElement, useMemo, useCallback } from 'react';
import {
  CredentialVariableName,
  type NodeData,
  NodeTypesEnum,
  WorkflowImageNode,
  type Variable,
  type WorkflowAction,
  type WorkflowActionsOptions,
} from 'types-shared';
import {
  DeleteIcon,
  DesktopIcon,
  EditIcon,
  EyeIcon,
  HideEyeIcon,
  IconButton,
  Menu,
  MenuItem,
  modalEventChannel,
  PersonIcon,
  ClosedCaption,
  Logo,
  LowPriority,
  MoreVert,
  PhonelinkLock,
  Publish,
  LockIcon,
} from 'ui-kit';
import { contactModalEventChannel } from '../../../../../utils/contactModal';
import { useParams } from 'react-router-dom';
import { ActionsEnum } from 'types-shared';
import { clsx } from 'clsx';
import { EditorStore } from '../../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';

interface Props {
  node: WorkflowImageNode;
  allActions: WorkflowAction[];
  selectedActions: string[];
  setSelectedActions: React.Dispatch<React.SetStateAction<string[]>>;
  onUpdateActionOptions: (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => void;
  updateNodeData: (data: Partial<NodeData>) => void;
  startBulkMoveAction: () => void;
  startBulkEditTargets: () => void;
}

interface MenuItemProps {
  text: string;
  action: () => void;
  renderWhen: boolean;
  icon: ReactElement;
}

function CustomMenuItem({ text, action, renderWhen, icon }: MenuItemProps) {
  return renderWhen ? (
    <MenuItem className="min-w-64 h-8 !px-4" onClick={action}>
      <span className="font-normal mr-4">{text}</span>
      {icon}
    </MenuItem>
  ) : null;
}

function ActionsMenu({
  node,
  allActions,
  selectedActions: selectedActionIds,
  setSelectedActions,
  startBulkMoveAction,
  startBulkEditTargets,
}: Props) {
  const { variables, updateVariable, setNodes, nodes } = EditorStore(
    useShallow((s) => ({
      variables: s.variables,
      updateVariable: s.updateVariable,
      setNodes: s.setNodes,
      nodes: s.nodes,
    })),
  );

  const { workflowId } = useParams();

  const closeModal = useCallback(() => {
    modalEventChannel.emit('close');
  }, []);

  const openModal = useCallback(() => {
    modalEventChannel.emit('open', {
      title: 'Actions handled by Sola',
      descriptions: [
        'Our team can take care of some manually handled actions for you, including Captchas and basic approvals.',
        'Chat with us to setup the timing and pricing that works for you!',
      ],
      actions: [
        {
          text: 'Confirm',
          onClick: () => {
            closeModal();
          },
        },
        {
          text: 'Contact Us',
          onClick: () => {
            contactModalEventChannel.emit('open', {
              problemType: 'General Issue',
              workflowId,
            });
            closeModal();
          },
          variant: 'text',
        },
      ],
    });
  }, [closeModal, workflowId]);

  const updateNodeData = (data: Partial<NodeData>) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.type !== NodeTypesEnum.Image) return _node;
        const updateNode = WorkflowImageNode.parse(_node);
        if (updateNode.id === node.id) {
          return {
            ...updateNode,
            data: {
              ...updateNode.data,
              ...data,
            },
          };
        }
        return updateNode;
      }),
    );
  };

  const onBulkToggle = useCallback(
    (name: keyof WorkflowActionsOptions) => {
      const actionsToUpdate = allActions.filter((action) =>
        selectedActionIds.includes(action.id),
      );
      const updatedActions = actionsToUpdate.map((action) => {
        const exclusive = [
          'hidden',
          'adminManual',
          'adminOnly',
          'sitl',
          'hitl',
          'adminSkipped',
        ].includes(name);

        let newHitl =
          name === 'hitl' ? !action.options?.hitl : action.options?.hitl;
        let newSitl =
          name === 'sitl' ? !action.options?.sitl : action.options?.sitl;

        if (name === 'mfa') {
          newSitl = !action.options?.[name];
          if (!action.options?.[name]) {
            openModal();
          }
        }

        if (name === 'hitl') {
          newSitl = false;
        }

        if (name === 'sitl') {
          newHitl = false;
        }

        return {
          ...action,
          options: {
            ...(exclusive ? action.options : {}),
            hidden: action.options?.hidden,
            adminManual: action.options?.adminManual,
            [name]: !action.options?.[name],
            hitl: newHitl,
            sitl: newSitl,
            ...(name === 'captcha' && action.options?.captcha
              ? {
                  reCaptcha: false,
                }
              : {}),
          },
        };
      });
      updateNodeData({
        actionData: {
          ...node.data.actionData,
          ...Object.assign({}, ...updatedActions.map((a) => ({ [a.id]: a }))),
        } as WorkflowImageNode['data']['actionData'],
      });

      setSelectedActions([]);
    },
    //   eslint-disable-next-line react-hooks/exhaustive-deps
    [allActions, selectedActionIds, updateNodeData, openModal],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const actionDetails = useMemo(() => {
    const payload: {
      hidden: boolean;
      hitl: boolean;
      sitl: boolean;
      adminOnly: boolean;
      submitted: boolean;
      captcha: boolean;
      reCaptcha: boolean;
      mfa: boolean;
      adminManual: boolean;
      adminSkipped: boolean;
      terminal: boolean;
      allAreInputs: boolean;
      allAreMagicLoops: boolean;
      allAreMfaOrCaptcha: boolean;
      selectedActions: WorkflowAction[];
    } = {
      hidden: false,
      hitl: false,
      sitl: false,
      adminOnly: false,
      submitted: false,
      captcha: false,
      reCaptcha: false,
      mfa: false,
      adminManual: false,
      adminSkipped: false,
      terminal: false,
      allAreInputs: false,
      allAreMagicLoops: false,
      allAreMfaOrCaptcha: false,
      selectedActions: [],
    };
    if (!selectedActionIds.length) {
      return payload;
    }

    const allSelectedActions = allActions.filter((a) =>
      selectedActionIds.includes(a.id),
    );

    const mfa = allSelectedActions.every((a) => a.options?.mfa);
    const captcha = allSelectedActions.every((a) => a.options?.captcha);
    const reCaptcha = allSelectedActions.every((a) => a.options?.reCaptcha);
    const allAreMfaOrCaptcha = mfa || captcha || reCaptcha;
    payload.selectedActions = allSelectedActions;
    payload.hidden = allSelectedActions.every((a) => a.options?.hidden);
    payload.hitl = allSelectedActions.every((a) => a.options?.hitl);
    payload.sitl = allSelectedActions.every((a) => a.options?.sitl);
    payload.adminOnly = allSelectedActions.every((a) => a.options?.adminOnly);
    payload.submitted = allSelectedActions.every((a) => a.options?.submitted);
    payload.captcha = captcha;
    payload.reCaptcha = reCaptcha;
    payload.mfa = mfa;
    payload.adminManual = allSelectedActions.every(
      (a) => a.options?.adminManual,
    );
    payload.adminSkipped = allSelectedActions.every(
      (a) => a.options?.adminSkipped,
    );
    payload.terminal = allSelectedActions.every((a) => a.options?.terminal);
    payload.allAreMagicLoops = allSelectedActions.every(
      (a) => a.actionType === ActionsEnum.MagicLoop,
    );
    payload.allAreInputs = allSelectedActions.every(
      (a) => a.actionType === ActionsEnum.Input,
    );

    payload.allAreMfaOrCaptcha = allAreMfaOrCaptcha;

    return payload;
  }, [selectedActionIds, allActions]);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onBulkMoveActions = useCallback(() => {
    startBulkMoveAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBulkEditTargets = useCallback(() => {
    startBulkEditTargets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateVariable = useCallback(
    (updates: Pick<Variable, 'name'>, action: Variable) => {
      updateVariable({
        ...action,
        ...updates,
      });
    },
    [updateVariable],
  );

  const onBulkUpdateCredentialVariableNames = useCallback(() => {
    const allActionsAreCredentials = actionDetails.selectedActions.every(
      (action) =>
        action.variableId &&
        variables[action.variableId].name === CredentialVariableName,
    );

    actionDetails.selectedActions.forEach((action) => {
      if (action.variableId) {
        onUpdateVariable(
          {
            name: allActionsAreCredentials ? '' : CredentialVariableName,
          },
          variables[action.variableId],
        );
      }
    });

    setSelectedActions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionIds, actionDetails.selectedActions, onUpdateVariable]);

  const onBulkDeleteActions = useCallback(() => {
    const actionsForDelete = selectedActionIds
      .map((actionID) => allActions.find((a) => a.id === actionID))
      .filter((a) => a) as WorkflowAction[];

    if (!actionsForDelete.length) return;

    const actionIds = actionsForDelete.map((a) => a.id).filter((a) => a);
    let actionData = omit(node.data.actionData, actionIds);
    let actionOrder = node.data.actionOrder.filter(
      (id) => !actionIds.includes(id),
    );

    actionsForDelete.forEach((action) => {
      if (action.actionType === ActionsEnum.MagicLoop) {
        actionData = omitBy(
          actionData,
          (item: WorkflowAction) => item.variableId === action.variableId,
        );
        actionOrder = actionOrder.filter((id) => id in actionData);
      }
    });

    updateNodeData({
      actionData,
      actionOrder,
    });
    setSelectedActions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionIds]);

  const HideIcon = actionDetails.hidden ? EyeIcon : HideEyeIcon;
  const AdminIcon = actionDetails.adminOnly ? EyeIcon : HideEyeIcon;
  const SkipIcon = actionDetails.hitl ? DesktopIcon : PersonIcon;

  const menuItems = useMemo(() => {
    const adminMenu: MenuItemProps[] = [
      {
        renderWhen: !actionDetails.allAreMfaOrCaptcha,
        text: 'Toggle handling action automatically (Adds / Removes - Action manually handled)',
        action: () => {
          onBulkToggle('hitl');
        },
        icon: (
          <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: Boolean(
          actionDetails.allAreMfaOrCaptcha && actionDetails.sitl,
        ),
        text: 'User will handle action manually (shows to users) ',
        action: () => {
          onBulkToggle('hitl');
        },
        icon: (
          <PersonIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: !actionDetails.sitl,
        text: 'Sola will handle this action (shows to users) (Action handled by Sola)',
        action: () => {
          onBulkToggle('sitl');
          openModal();
        },
        icon: <Logo className="!w-5 !h-5 !ml-auto" />,
      },
      {
        renderWhen: actionDetails.sitl,
        text: 'Handle this action automatically (Removes Action handled by Sola)',
        action: () => {
          onBulkToggle('sitl');
        },
        icon: <Logo className="!w-5 !h-5 !ml-auto" />,
      },
      {
        renderWhen: true,
        text: 'Enable/Skip Action (shows to users)',
        action: () => {
          onBulkToggle('hidden');
        },
        icon: (
          <HideIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: actionDetails.allAreInputs,
        text: 'Set/Unset as credential',
        action: () => {
          onBulkUpdateCredentialVariableNames();
          onClose();
        },
        icon: (
          <LockIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: 'Disable/Enable Pass through (Adds/Removes Pass Through)',
        action: () => {
          onBulkToggle('adminSkipped');
        },
        icon: (
          <HideIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: 'Toggle Manually handled by Admin (Removes Admin Manual)',
        action: () => {
          onBulkToggle('adminManual');
        },
        icon: (
          <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: 'Show/Hide Action from Users (Toggles Admin Only)',
        action: () => {
          onBulkToggle('adminOnly');
        },
        icon: (
          <AdminIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: 'Edit Target',
        action: () => {
          onClose();
          onBulkEditTargets();
        },
        icon: (
          <EditIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: 'Move Actions',
        action: () => {
          onClose();
          onBulkMoveActions();
        },
        icon: <LowPriority className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
      {
        renderWhen: true,
        text: 'Delete Actions',
        action: () => {
          onClose();
          onBulkDeleteActions();
        },
        icon: (
          <DeleteIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: 'Mark/Unmark as Submit',
        action: () => {
          onBulkToggle('submitted');
        },
        icon: <Publish className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
      {
        renderWhen: true,
        text: 'Mark/Unmark as Captcha',
        action: () => {
          onBulkToggle('captcha');
        },
        icon: <ClosedCaption className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
      {
        renderWhen: true,
        text: 'Mark/Unmark as MFA (Toggles Two-factor authentication and Action handled by Sola)',
        action: () => {
          onBulkToggle('mfa');
        },
        icon: <PhonelinkLock className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
    ];

    if (actionDetails.allAreMagicLoops && actionDetails.terminal) {
      adminMenu.push({
        renderWhen: true,
        text: 'Move Action',
        action: () => {
          onClose();
          onBulkMoveActions();
        },
        icon: <LowPriority className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      });
    }

    return adminMenu;
  }, [
    onBulkDeleteActions,
    onBulkEditTargets,
    onBulkMoveActions,
    onBulkToggle,
    onBulkUpdateCredentialVariableNames,
    actionDetails,
    SkipIcon,
    HideIcon,
    AdminIcon,
    openModal,
    onClose,
  ]);

  return (
    <>
      <IconButton
        className="!p-0"
        disabled={!selectedActionIds.length}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(e.target as HTMLButtonElement);
        }}
      >
        <MoreVert
          className={clsx('!w-5 !h-5', {
            'text-black': !actionDetails.allAreMagicLoops,
            'text-white': actionDetails.allAreMagicLoops,
          })}
        />
      </IconButton>
      <Menu
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
        onClose={onClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map((item) => (
          <CustomMenuItem
            text={item.text}
            renderWhen={item.renderWhen}
            icon={item.icon}
            action={item.action}
            key={item.text}
          />
        ))}
      </Menu>
    </>
  );
}

export default ActionsMenu;
