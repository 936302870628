import { ExecutionStatusEnum, z } from 'types-shared';
import dayjs, { type Dayjs } from '../../../config/dayjs';
import { isAdmin } from '../../../utils/env';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

export const getRelativeTime = (date: Dayjs) => dayjs(date).fromNow();

export const formatDuration = (milliseconds: number) => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds} hs`;
};

export const executionListFiltersSchema = z.object({
  id: z.string().optional(),
  status: z.array(z.string()),
  hideAdminRuns: z.boolean().optional(),
  owner: z.string().optional(),
});
export type ExecutionListFilters = z.infer<typeof executionListFiltersSchema>;

export const defaultFilters: ExecutionListFilters = {
  id: '',
  status: ['All'],
  ...(isAdmin ? { hideAdminRuns: undefined } : {}),
  owner: '',
};

export const runningStatuses = [
  ExecutionStatusEnum.Running,
  ExecutionStatusEnum.PendingAdmin,
  ExecutionStatusEnum.PendingSystem,
  ExecutionStatusEnum.Paused,
];
export const failedStatuses = [
  ExecutionStatusEnum.Terminated,
  ExecutionStatusEnum.Failed,
  ExecutionStatusEnum.Canceled,
  ExecutionStatusEnum.Timeout,
];

export const labelToStatusesMapping: Record<string, unknown> = {
  Running: runningStatuses,
  Failed: failedStatuses,
};

export const mapStatuses = (statuses: string[]) =>
  uniq(
    statuses.flatMap((status) => {
      return labelToStatusesMapping[status] ?? [status];
    }),
  );

export const hasFilters = (filters: ExecutionListFilters) => {
  const { id, status, hideAdminRuns } = filters;
  return (
    !isEmpty(id) ||
    status.length > 1 ||
    status[0] !== 'All' ||
    typeof hideAdminRuns === 'boolean'
  );
};

export function capitalizeFirstLetter(sentence: string) {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}
