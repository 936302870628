import { CheckedCheckmarkIcon, Flex, GoogleIcon } from 'ui-kit';

interface Props {
  onClick?: () => void;
  platform: string;
  isConnected?: boolean;
  iconUrl?: string;
}
export default function IntegrationTile({
  onClick,
  platform,
  isConnected = false,
  iconUrl,
}: Props) {
  return (
    <Flex
      className="min-w-80 p-7 relative transition max-w-80 h-52 justify-center items-center rounded-lg border-2 border-color-light-blue hover:border-primary-blue cursor-pointer"
      onClick={onClick}
    >
      {platform === 'google' && <GoogleIcon className="!w-full" />}
      {iconUrl ? (
        <img alt={platform} className="!max-h-full" src={iconUrl} />
      ) : null}
      {isConnected ? (
        <CheckedCheckmarkIcon
          className="absolute top-4 right-4"
          color="success"
        />
      ) : null}
    </Flex>
  );
}
