import type { ChipProps } from '@mui/material/Chip';
import { WorkflowStatusEnum } from 'api-types-shared';
import { Chip } from 'ui-kit';

const getStatusStyles = (status: WorkflowStatusEnum) => {
  switch (status) {
    case WorkflowStatusEnum.Ready:
      return {
        backgroundColor: '#e7f8ea',
        color: '#00590f',
      };
    case WorkflowStatusEnum.ProcessingImport:
      return {
        backgroundColor: '#eaf1f3',
        color: '#0f3d61',
      };
    case WorkflowStatusEnum.Invalid:
      return {
        backgroundColor: '#e2ecff',
        color: '#033bb9',
      };
    case WorkflowStatusEnum.ProcessingImportError:
      return {
        backgroundColor: '#fdeced',
        color: '#5f2120',
      };
    case WorkflowStatusEnum.ProcessedImport:
      return {
        backgroundColor: '#e2ecff',
        color: '#033bb9',
      };
    case WorkflowStatusEnum.ProcessingImportErrorAuthentication:
      return {
        backgroundColor: '#fff4e5',
        color: '#663c00',
      };
    default:
      return {};
  }
};

interface ExtendedProps {
  status: WorkflowStatusEnum;
}

export default function WorkflowChip({
  label,
  status,
  ...props
}: ChipProps & ExtendedProps) {
  const statusStyles = getStatusStyles(status);

  return (
    <Chip
      {...props}
      label={label}
      statusStyles={{ ...statusStyles, borderRadius: '4px' }}
      className="font-medium text-sm"
      size="small"
    />
  );
}
