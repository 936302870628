import { CustomTypography, Flex, PuzzleIcon } from 'ui-kit';

export default function ComingSoonIntegrationTile() {
  return (
    <Flex className="min-w-80 max-w-80 h-52 flex-col rounded-lg border-2 border-color-light-blue bg-color-light-blue p-7">
      <div className="text-primary-blue mb-4">
        <PuzzleIcon />
      </div>
      <CustomTypography color="text.info" variant="body1">
        <b>New integrations coming soon!</b>
        <br />
        Contact us if you need a specific tool ASAP.
      </CustomTypography>
    </Flex>
  );
}
