import {
  ContentContainer,
  CustomTypography,
  DataLoader,
  Flex,
  HelpOutlineOutlined,
  IconButton,
  ScrollableContainer,
} from 'ui-kit';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { openContactModal } from '../../utils/contactModal';
import { useNavigate, useParams } from 'react-router-dom';
import { Toolbar } from '../../components/Toolbar';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import SettingsForm from './components/Form';
import { CopyData } from '../../components/CopyData';
import { useEffect } from 'react';
import { useFeatureFlag } from '../../utils/helper';
import { FeatureFlag } from '../../utils/constants';
import { useGetWorkflowWebhooks } from './hooks';
import { handleException } from 'sentry-browser-shared';

const sampleResponse = JSON.stringify(
  {
    event: 'execution.finished',
    data: {
      executionId: 'b1a2f74e-3e41-4d17-a524-8c802013bbe8',
      workflowId: 'e46b22b3-6c8b-4261-8d0b-cc6c0520f809',
      status: 'FAILED',
      statusDescription:
        '"No records found. Please, try again with another ID."',
      createdAt: '2024-10-18T22:31:21.38366+00:00',
      lastUpdatedAt: '2024-10-18T22:32:03.622Z',
      variableData: {
        country: 'US',
        name: 'John',
      },
      scrapeData: {
        company_information: 'Acme Inc. - Seattle HQ',
      },
      artifactUrls: [
        'https://prod-sola-execution-data.s3.us-east-1.amazonaws.com/fb8d9540-d49f-4bc8-917e-6b1566c27318/artifacts/1.0968f450-7e1f-454e-8a0e-26d7a6674225.50MB-TESTFILE.ORG.pdf.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIA2HL4MHM6BN%20241020%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241020T131058Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEA4aCXVzLWVhc3QtMSJHMEUCIQCLDp1lPZbTPILVsTFm4CTeC%2BYh%2Fr7FIN48wCJKhKx8zAIgQNjUiaSLvOkXHNL3cHfmpyEEC9nuLd7xRNUWCYvir6cquQMIdhAAGgw3MDMwMjQ4NzIxNTkiDEmKX4j0K1%2Fuu%2By3cSqWAzfCZvLJB4a2mPnUo9Tk2xtd67sFZgZx%2Fjy7mOlYecB9N0VYQmsdavDPXriVjJQxiidNZTdy0Gq3nuYLAse9LZkaOViJcNbyCfg0wAH%26Rk318gnRWa1p%2F%2Bcjf%2FZMP6lWjXHKWZxSA%2FoYxUI5v4RSttJzu8YW9FPud13kgE%2FTYFjehn4KTZR3DwO9dVZttCfmLiyiK04szgmFW4Im4U4N7BqH6B%2FXbPl%2BdGkArgIajwMAz4aGkIrlvsGUGFqmdF61323T6%2BH8SJl7tJvHAkR9z3rxgt3OL%2BkJc5GhWCV%2Bp5hOCtZkgBrb1xcy8RTxMEQlERjq42M0lH0UPGnRyBWgJhAozVDOk3i1CkjmZkoGgTY5E66Vj%2FvR0UoZWAYQAZKwGTdeZcruRmrQMdOceW9YexZxfb5ygrDgRWThUk6hXwj5O4DIvJmKjOV73iqTArJ7al8yy0J5KcXz9YUH1FE1SzEDox2bvdvFZsEl8iE9twG5Ys3zGrrFC9x0bAscQmLN9Qmeyv%2BAw6oHUuAY6ngFHg2dXnewW7W%2F7iQq6d%2FlHHIkLDYkPXY2aeCbzt6ujsLEEdd1CRmqooo3nl6Z7niCCoFvS2Y3OofY2B9N3doo67LwecbJv9we8u1J8%2Fj1bxEUVl3Mv72nQfnnMBwcrr%2BVuYl8KI0ncXu%2Bq6HIfW3LmGQXZQzIbDO25ydiiMs1kmruiXH4QZ%2BcIleDGDvMKp6Ktsr8wKHQAV1CIb91Gnw%3D%3D&X-Amz-Signature=96ba3f5ae391ba118ebc6b9b687e2236ea8800e9c2a9f36b64190c8b64842659&X-Amz-SignedHeaders=host&x-id=GetObject',
      ],
    },
  },
  null,
  2,
);

export default function WorkflowSettings() {
  const { workflowId } = useParams();

  if (!workflowId) {
    const error = new Error('workflowId not found!');
    handleException(error, {
      name: 'WorkflowSettings',
      source: 'WorkflowSettings',
    });
    throw error;
  }

  const navigate = useNavigate();
  const webhookPageEnabled = useFeatureFlag(FeatureFlag.WebhookPage);
  const { data: webhooks = [], isLoading: isLoadingWebhooks } =
    useGetWorkflowWebhooks(workflowId);

  if (!workflowId) {
    throw new Error('workflowId not found!');
  }

  const { data: workflowMetadata, isLoading } =
    useFetchWorkflowMetadata(workflowId);

  const loading = isLoading || isLoadingWebhooks;

  useEffect(() => {
    if (webhookPageEnabled === false) {
      navigate(`/editor/${workflowId}`);
    }
  }, [navigate, webhookPageEnabled, workflowId]);

  return (
    <ScrollableContainer>
      {loading ? (
        <DataLoader loadingText="Fetching workflow settings" />
      ) : (
        <>
          <Flex
            alignItems="center"
            className="flex-1 pr-8"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Toolbar
              onBack={() => {
                navigate(`/editor/${workflowId}`);
              }}
              pageInfo="API Call Details"
              title={workflowMetadata?.workflowName}
            />
            <IconButton
              className="order-5 ml-2 rounded-lg group"
              onClick={() => {
                openContactModal(workflowId);
              }}
            >
              <HelpOutlineOutlined className="!w-5 !h-5 text-info" />
            </IconButton>
          </Flex>

          <ContentContainer withToolbar>
            <CustomTypography
              className="!font-medium !text-2xl text-info-dark"
              variant="h4"
            >
              Workflow Settings
            </CustomTypography>
            <p className="text-color-grey text-sm mb-6">
              Configure and test the workflow trigger and setup the webhook at
              workflow level
            </p>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#2196F3',
                  },
                  '& .Mui-selected': {
                    color: '#2196F3 !important',
                  },
                }}
                value="events"
              >
                <Tab label="Events" value="events" />
              </Tabs>
            </Box>
            <div className="flex justify-between py-8 space-x-10">
              <SettingsForm workflowId={workflowId} webhooks={webhooks} />

              <Flex className="space-y-8 w-120 xl:w-140" flexDirection="column">
                <p className="text-info-dark text-lg font-medium">
                  Sample response
                </p>
                <CopyData data={sampleResponse} dataType="json" />
              </Flex>
            </div>
          </ContentContainer>
        </>
      )}
    </ScrollableContainer>
  );
}
