import {
  type ExecutionVariables,
  type QueryVariable,
  VariableTypeEnum,
} from 'types-shared';
import { DataLoader, Flex } from 'ui-kit';
import { useMemo, useCallback } from 'react';
import type { ExecutionDocument } from 'api-types-shared';
import { useParams } from 'react-router-dom';
import OutputsTable from './OutputsTable';
import { v4 as uuid } from 'uuid';
import { type OutputItem, OutputItemActions } from './OutputsTable/Description';

interface Props {
  downloadZippedOutput: (
    executionId: string,
    outputs: OutputItem[],
  ) => Promise<void>;
  downloadLinkData: (url: string) => void;
  downloadZippedOutputStatus: 'error' | 'success' | 'pending' | 'idle';
  executionVariables?: ExecutionVariables;
  artifacts: ExecutionDocument[];
  artifactsLoading: boolean;
  fetchExecutionArtifacts: () => void;
  outputDetailsExportButtonEnabled: boolean;
  variableMetadata?: Record<string, string>;
}

export function RecordInputVariables({
  executionVariables,
  artifactsLoading,
  downloadLinkData,
  variableMetadata,
}: Props) {
  const { executionId } = useParams();

  if (!executionId) {
    throw Error('executionId not found!');
  }

  const onDownloadLinkData = useCallback(
    (uri: string) => {
      downloadLinkData(uri);
    },
    [downloadLinkData],
  );

  const outputItems: OutputItem[] = useMemo(() => {
    const queryVariables = Object.values(executionVariables ?? {}).map(
      (variable) => {
        const check =
          typeof variable !== 'string' &&
          variable.type === VariableTypeEnum.Query;
        if (check) {
          const data = variable as QueryVariable;

          return data;
        }
        return null;
      },
    );

    const outputItemsToReturn = queryVariables.map((variable) => {
      if (variable) {
        const title = variable.name;
        let altDesc = '';

        if (title && variableMetadata) {
          altDesc = variableMetadata[title];
        }

        // Need this to evaluate all
        const description =
          variable.executionData?.transformedValue ||
          variable.executionData?.initialValue ||
          variable.dashboardData?.initialValue ||
          altDesc;

        const action: OutputItemActions = OutputItemActions.COPY;

        if (!title) {
          return null;
        }

        const outputItem: OutputItem = {
          id: uuid(),
          action,
          description,
          onDownloadLinkData,
          title,
          type: variable.data.valueType,
        };

        return outputItem;
      }
      return null;
    });

    return outputItemsToReturn.filter(
      (item): item is OutputItem => item !== null,
    );
  }, [executionVariables, onDownloadLinkData, variableMetadata]);

  return artifactsLoading ? (
    <DataLoader />
  ) : (
    <div>
      <Flex alignItems="center" className="my-8" justifyContent="space-between">
        <div>
          <h2 className="text-cyan-900 text-2xl font-medium leading-9 tracking-tight">
            Inputs
          </h2>
          <span className="text-slate-500 text-sm leading-normal">
            Review the values for workflow variables
          </span>
        </div>

        {/* <div>
          <Button
            className="!border-none"
            color="secondary"
            disabled={
              downloadZippedOutputStatus === 'pending' ||
              artifacts.length === 0 ||
              !outputDetailsExportButtonEnabled
            }
            onClick={() => downloadZippedOutput(executionId, outputItems)}
            variant="outlined"
          >
            <CustomDownloadIcon
              fontSize="small"
              className={clsx({
                'text-gray-350 mr-1':
                  downloadZippedOutputStatus === 'pending' ||
                  artifacts.length === 0 ||
                  !outputDetailsExportButtonEnabled,
                'text-info mr-1': !(
                  downloadZippedOutputStatus === 'pending' ||
                  artifacts.length === 0 ||
                  !outputDetailsExportButtonEnabled
                ),
              })}
            />
            Export
          </Button>
        </div> */}
      </Flex>

      <OutputsTable
        outputItems={outputItems}
        loading={artifactsLoading}
        isOutput={false}
      />
    </div>
  );
}
