import {
  VariableTypeEnum,
  type ExecutionVariable,
  ExecutionVariableNameEnum,
} from 'types-shared';
import { MarkerType } from 'types-shared/reactflow';
import { v4 as uuid } from 'uuid';

export const defaultMarker = {
  type: MarkerType.Arrow,
  width: 10,
  height: 10,
  strokeWidth: 2,
  color: '#000',
};

export const editorMaxZoomLevel = 5;
export const zoomLevelForNode = 2.5;
export const editorMinZoomLevel = 0.1;
export const minZoomLevelForFullContent = 0.4;

export const CONTACT_SLACK_CHANNEL_ID = 'C06G8CTGLLD';

export const DEFAULT_ZOOM = 0.7;

const executionVariableIds = new Array(4).fill(0).map((_) => uuid());

export const executionVariableSubTypeMapping: Record<string, string> = {
  [ExecutionVariableNameEnum.ExecutionId]: 'String',
  [ExecutionVariableNameEnum.TodayDate]: 'Date',
  [ExecutionVariableNameEnum.YesterdayDate]: 'Date',
  [ExecutionVariableNameEnum.PreviousWeekdayDate]: 'Date',
};

export const executionVariableTitleMapping: Record<string, string> = {
  [ExecutionVariableNameEnum.ExecutionId]: 'Execution ID',
  [ExecutionVariableNameEnum.TodayDate]: `Date: Today`,
  [ExecutionVariableNameEnum.YesterdayDate]: `Date: Yesterday`,
  [ExecutionVariableNameEnum.PreviousWeekdayDate]: `Date: Previous Weekday`,
};

export const executionVariableMap: Record<string, ExecutionVariable> = {
  [executionVariableIds[0]]: {
    type: VariableTypeEnum.Execution,
    name: ExecutionVariableNameEnum.ExecutionId,
    id: executionVariableIds[0],
    data: [],
  },
  [executionVariableIds[1]]: {
    type: VariableTypeEnum.Execution,
    name: ExecutionVariableNameEnum.TodayDate,
    id: executionVariableIds[1],
    data: [],
  },
  [executionVariableIds[2]]: {
    type: VariableTypeEnum.Execution,
    name: ExecutionVariableNameEnum.YesterdayDate,
    id: executionVariableIds[2],
    data: [],
  },
  [executionVariableIds[3]]: {
    type: VariableTypeEnum.Execution,
    name: ExecutionVariableNameEnum.PreviousWeekdayDate,
    id: executionVariableIds[3],
    data: [],
  },
};

export const deletableVariableTypes = [
  VariableTypeEnum.Scrape,
  VariableTypeEnum.Query,
  VariableTypeEnum.Template,
  VariableTypeEnum.Select,
  VariableTypeEnum.MultiSelect,
  VariableTypeEnum.MultiChoice,
  VariableTypeEnum.Document,
];
