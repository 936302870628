import {
  type Variable,
  type TemplateData,
  VariableTypeEnum,
  type GlobalVariable,
  type VariableMap,
  DocumentSourceEnum,
} from 'types-shared';
import {
  getVariableChipClasses,
  getVariableChipContent,
} from '../../../../../components/VariableChip';
import { checkIfVariableHasTransformations } from '../../../utils/helper';
import { executionVariableSubTypeMapping } from '../../../utils/constants';

export const VARIABLE_ID_DATA_ATTRIBUTE = 'data-variable-id';
export const SELECTION_PLACEHOLDER_DATA_ATTRIBUTE =
  'data-selection-placeholder';

export function createVariableChipElement(
  variable: Variable,
  disabled = false,
): HTMLSpanElement {
  const isGlobal = variable.type === VariableTypeEnum.Global;
  const isExecutionVariable = variable.type === VariableTypeEnum.Execution;
  const isDocumentScrape =
    variable.type === VariableTypeEnum.Document &&
    variable.data.source === DocumentSourceEnum.Execution;

  const hasTransformations = checkIfVariableHasTransformations(variable);
  const hasLeftIcon = isGlobal || isDocumentScrape;
  const hasRightIcon = hasTransformations;

  const span = document.createElement('span');
  const otherClasses = [
    hasLeftIcon ? 'ml-1 relative pl-7' : 'ml-1',
    hasRightIcon ? 'ml-1 relative pr-5' : 'ml-1',
  ];
  span.className = `${getVariableChipClasses(variable, disabled)} ${otherClasses.join(' ')}`;
  span.textContent = getVariableChipContent(variable).trim();
  span.contentEditable = 'false';

  const idAttr = document.createAttribute(VARIABLE_ID_DATA_ATTRIBUTE);
  idAttr.value = variable.id;
  span.attributes.setNamedItem(idAttr);

  // Note: This is a temporary solution to show the icons inside the variable chip
  // We can't use React components here because the chip is rendered in a non-React environment
  // Prepend global variable icon
  if (isGlobal) {
    span.setAttribute(
      'title',
      'This is a shared variable. To preview or edit, go to the shared variables section.',
    );
    const iconElement = document.createElement('span');
    iconElement.setAttribute('style', 'position: absolute; left:2px; top:4px;');
    iconElement.innerHTML =
      '<svg style="height:16px; width:16px; fill:white; position: absolute; left:5px;" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LanguageOutlinedIcon"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2m6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56M12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96M4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56m2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8M12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96M14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2m.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56M16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2z"></path></svg>';

    span.prepend(iconElement);
  }

  if (isDocumentScrape) {
    const iconElement = document.createElement('span');
    iconElement.setAttribute('style', 'position: absolute; left:2px; top:4px;');
    iconElement.innerHTML =
      '<svg style="height:14px; width:12px; fill:white; position: absolute; left:5px;" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M3.99967 10.3333H7.99967M3.99967 8.33333H7.99967M6.66659 1.00058C6.6029 1 6.53126 1 6.44948 1H3.46647C2.71973 1 2.34609 1 2.06087 1.14533C1.80999 1.27316 1.60616 1.47698 1.47833 1.72786C1.33301 2.01308 1.33301 2.38673 1.33301 3.13346V10.8668C1.33301 11.6135 1.33301 11.9867 1.47833 12.2719C1.60616 12.5228 1.80999 12.727 2.06087 12.8548C2.34581 13 2.71901 13 3.4643 13L8.53507 13C9.28034 13 9.653 13 9.93794 12.8548C10.1888 12.727 10.3933 12.5228 10.5212 12.2719C10.6663 11.987 10.6663 11.6143 10.6663 10.8691V5.21712C10.6663 5.13535 10.6663 5.06369 10.6657 5M6.66659 1.00058C6.8569 1.00232 6.97682 1.00938 7.09179 1.03698C7.22784 1.06964 7.35824 1.12351 7.47754 1.19661C7.61205 1.27904 7.72755 1.39454 7.95801 1.625L10.0417 3.70866C10.2723 3.93926 10.3869 4.05424 10.4694 4.1888C10.5425 4.30809 10.5966 4.43817 10.6292 4.57422C10.6568 4.68919 10.664 4.8097 10.6657 5M6.66659 1.00058L6.66634 2.86681C6.66634 3.61354 6.66634 3.98677 6.81167 4.27198C6.9395 4.52287 7.14332 4.72699 7.39421 4.85482C7.67914 5 8.05235 5 8.79762 5H10.6657" stroke="white" stroke-linecap="round" stroke-linejoin="round"/></svg>';

    span.prepend(iconElement);
  }

  if (hasTransformations) {
    const starsIcon = document.createElement('span');
    starsIcon.setAttribute('style', 'position: absolute; right:6px; top:5px;');
    starsIcon.innerHTML =
      '<svg style="height:12px; width:12px; margin-left:1px" focusable="false" aria-hidden="true" viewBox="0 0 14 14" fill="none" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M5.30344 2.17753C5.32596 2.22258 5.36249 2.25911 5.40755 2.28164L6.86167 3.0087C7.03325 3.09449 7.03325 3.33935 6.86167 3.42514L5.40755 4.1522C5.36249 4.17472 5.32596 4.21125 5.30344 4.25631L4.57638 5.71042C4.49059 5.88201 4.24573 5.88201 4.15994 5.71043L3.43288 4.25631C3.41035 4.21125 3.37382 4.17472 3.32877 4.1522L1.87465 3.42514C1.70307 3.33935 1.70307 3.09449 1.87465 3.0087L3.32877 2.28164C3.37382 2.25911 3.41035 2.22258 3.43288 2.17753L4.15994 0.723413C4.24573 0.551831 4.49059 0.551831 4.57638 0.723413L5.30344 2.17753Z" fill="white"></path><path d="M3.55747 9.74338C3.58 9.78844 3.61653 9.82497 3.66158 9.84749L5.1157 10.5746C5.28728 10.6603 5.28728 10.9052 5.1157 10.991L3.66158 11.718C3.61653 11.7406 3.58 11.7771 3.55747 11.8222L2.83041 13.2763C2.74462 13.4479 2.49976 13.4479 2.41397 13.2763L1.68691 11.8222C1.66439 11.7771 1.62786 11.7406 1.5828 11.718L0.128686 10.991C-0.0428956 10.9052 -0.0428953 10.6603 0.128686 10.5746L1.5828 9.84749C1.62786 9.82497 1.66439 9.78844 1.68691 9.74338L2.41397 8.28927C2.49976 8.11768 2.74462 8.11768 2.83041 8.28927L3.55747 9.74338Z" fill="white"></path><path d="M5.91736 7.66741C5.75302 7.75505 5.75302 7.99061 5.91736 8.07824L8.37353 9.38795C8.41791 9.41162 8.45343 9.44902 8.47478 9.49457L9.68626 12.0791C9.77001 12.2577 10.0241 12.2577 10.1078 12.0791L11.3193 9.49457C11.3407 9.44902 11.3762 9.41162 11.4206 9.38795L13.8767 8.07824C14.0411 7.99061 14.0411 7.75505 13.8767 7.66741L11.4206 6.3577C11.3762 6.33404 11.3407 6.29663 11.3193 6.25109L10.1078 3.6666C10.0241 3.48794 9.77001 3.48795 9.68626 3.6666L8.47478 6.25109C8.45343 6.29663 8.41791 6.33404 8.37353 6.3577L5.91736 7.66741Z" fill="white"></path></svg>';
    span.appendChild(starsIcon);
  }

  if (isExecutionVariable) {
    const subType = executionVariableSubTypeMapping[variable.name];
    if (subType === 'Date') {
      const starsIcon = document.createElement('span');
      starsIcon.innerHTML =
        '<svg style="height:12px; width:12px; margin-left:1px" focusable="false" aria-hidden="true" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.66669 5.33331H13.3334M2.66669 5.33331V11.2001C2.66669 11.9468 2.66669 12.32 2.81201 12.6052C2.93984 12.8561 3.14367 13.0603 3.39455 13.1881C3.67949 13.3333 4.05268 13.3333 4.79796 13.3333H11.2021C11.9474 13.3333 12.32 13.3333 12.6049 13.1881C12.8558 13.0603 13.0603 12.8561 13.1882 12.6052C13.3334 12.3203 13.3334 11.9476 13.3334 11.2024V5.33331M2.66669 5.33331V4.80011C2.66669 4.05337 2.66669 3.67973 2.81201 3.39451C2.93984 3.14363 3.14367 2.9398 3.39455 2.81197C3.67977 2.66665 4.05341 2.66665 4.80015 2.66665H5.33335M13.3334 5.33331V4.79792C13.3334 4.05264 13.3334 3.67945 13.1882 3.39451C13.0603 3.14363 12.8558 2.9398 12.6049 2.81197C12.3197 2.66665 11.9469 2.66665 11.2002 2.66665H10.6667M10.6667 1.33331V2.66665M10.6667 2.66665H5.33335M5.33335 1.33331V2.66665" fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path></svg>';
      span.prepend(starsIcon);
    }
  }

  return span;
}

export function createPlaceholderElement(): HTMLSpanElement {
  const span = document.createElement('span');
  span.textContent = '';
  span.contentEditable = 'false';

  const selectionPlaceholderAttr = document.createAttribute(
    SELECTION_PLACEHOLDER_DATA_ATTRIBUTE,
  );
  selectionPlaceholderAttr.value = 'true';
  span.attributes.setNamedItem(selectionPlaceholderAttr);

  return span;
}

export function removeAllPlaceholderElements(container: HTMLElement) {
  const placeholderElements = container.querySelectorAll(
    `[${SELECTION_PLACEHOLDER_DATA_ATTRIBUTE}="true"]`,
  );

  placeholderElements.forEach((el) => {
    el.remove();
  });
}

export function extractTemplateData(inputHtml: string): TemplateData {
  const parser = new DOMParser();
  const doc = parser.parseFromString(inputHtml, 'text/html');
  const result: TemplateData = [] as TemplateData;
  const elements: ChildNode[] = Array.from(doc.body.childNodes);

  elements.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent;
      if (textContent) {
        result.push(textContent);
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      const variableId = element.getAttribute(VARIABLE_ID_DATA_ATTRIBUTE);

      if (element.tagName.toLowerCase() === 'br') {
        // Skip <br> tags
        return;
      }

      if (variableId) {
        result.push({ id: variableId });
      } else {
        result.push(element.innerText); // Fallback to innerText
      }
    }
  });

  return result;
}

export function updateVariableDataIntoContainer(
  el: HTMLElement,
  variableData: TemplateData,
  variablesMap: Record<string, Variable>,
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap,
) {
  el.innerHTML = '';
  variableData.forEach((templateData) => {
    if (typeof templateData === 'string') {
      el.appendChild(document.createTextNode(templateData));
    } else if (typeof templateData === 'object' && 'id' in templateData) {
      const variable =
        globalVariablesMap[templateData.id] ?? variablesMap[templateData.id];
      const span = createVariableChipElement(variable);
      el.appendChild(span);
    }
  });
}

export function appendPlaceholderAtCurrentSelection(container: HTMLElement) {
  const selection = window.getSelection();
  if (!selection?.rangeCount || selection.rangeCount <= 0) {
    return;
  }
  const range = selection.getRangeAt(0);

  removeAllPlaceholderElements(container);

  const span = createPlaceholderElement();
  const commonAncestor = range.commonAncestorContainer;

  const isSelectionInsideContainer = container.contains(commonAncestor);

  if (isSelectionInsideContainer) {
    // Insert the span and position the cursor after it
    range.insertNode(span);
    range.setStartAfter(span);
    range.setEndAfter(span);

    // Update the selection
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    // Insert the span at the end of the containerDiv
    container.appendChild(span);

    // Move the cursor after the newly added span
    const newRange = document.createRange();
    newRange.setStartAfter(span);
    newRange.setEndAfter(span);

    // Update the selection
    selection.removeAllRanges();
    selection.addRange(newRange);
  }
}

export function replacePlaceholderWithVariableChip(
  variable: Variable,
  container: HTMLElement,
) {
  // Find the placeholder element inside the container
  const placeholderEl = container.querySelector(
    `[${SELECTION_PLACEHOLDER_DATA_ATTRIBUTE}="true"]`,
  );
  // If there's no placeholder, exit the function
  if (!placeholderEl) return;

  // Create the chip element
  const span = createVariableChipElement(variable);

  // Replace the placeholder with the chip element
  placeholderEl.replaceWith(span);

  // Create a new range and set the cursor after the chip element
  const selection = window.getSelection();
  const newRange = document.createRange();
  newRange.setStartAfter(span);
  newRange.setEndAfter(span);

  // Update the selection
  selection?.removeAllRanges();
  selection?.addRange(newRange);
}
