"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GmailTriggerOutputVariable = exports.GmailTriggerVariableName = exports.GmailTriggerVariableEnum = void 0;
var zod_1 = require("zod");
var workflowTypes_1 = require("../workflowTypes");
var GmailTriggerVariableEnum;
(function (GmailTriggerVariableEnum) {
    GmailTriggerVariableEnum["From"] = "From";
    GmailTriggerVariableEnum["To"] = "To";
    GmailTriggerVariableEnum["Subject"] = "Subject";
    GmailTriggerVariableEnum["Body"] = "Body";
    GmailTriggerVariableEnum["Attachments"] = "Attachments";
    GmailTriggerVariableEnum["EmailData"] = "Email Data";
})(GmailTriggerVariableEnum || (exports.GmailTriggerVariableEnum = GmailTriggerVariableEnum = {}));
exports.GmailTriggerVariableName = zod_1.z.nativeEnum(GmailTriggerVariableEnum);
exports.GmailTriggerOutputVariable = workflowTypes_1.QueryVariable.extend({
    name: exports.GmailTriggerVariableName,
});
