import TriggerBlockImage from './TriggerBlockImage';
import {
  GmailTriggerVariableEnum,
  QueryVariable,
  type SourceVariable,
  type TemplateData,
  type Variable,
  type WorkflowSourceNode,
  type GlobalVariable,
  type VariableMap,
} from 'types-shared';
import React, { type ReactNode, useMemo, useState, useCallback } from 'react';
import { Label } from 'ui-kit';
import { TriggerQueryVariables } from './TriggerQueryVariables';
import { AddEditQueryVariable } from './AddEditQueryVariable';

interface Props {
  sourceVariable: SourceVariable;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  node: WorkflowSourceNode;
  updateVariable: (variable: QueryVariable) => void;
  addVariable: (variable: QueryVariable) => void;

  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    query: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
}

export function TriggerBlockData({
  sourceVariable,
  variablesMap,
  globalVariablesMap,
  ...props
}: Props) {
  const [addingVariable, setAddingVariable] = useState<QueryVariable | null>(
    null,
  );
  const [editingVariable, setEditingVariable] = useState<QueryVariable | null>(
    null,
  );

  const queryVariables = useMemo(() => {
    return Object.values(variablesMap).filter((v) => {
      const parseRes = QueryVariable.safeParse(v);
      if (!parseRes.success) return false;

      return true;
    }) as QueryVariable[];
  }, [variablesMap]);

  const [
    subjectVariable,
    bodyVariable,
    emailDataVariable,
    attachmentsVariable,
  ] = useMemo(() => {
    const variableNames = [
      GmailTriggerVariableEnum.Subject,
      GmailTriggerVariableEnum.Body,
      GmailTriggerVariableEnum.EmailData,
      GmailTriggerVariableEnum.Attachments,
    ];
    return variableNames.map((name) => {
      const variables = Object.values(variablesMap).filter<QueryVariable>(
        (v): v is QueryVariable => v.name === name,
      );
      if (variables.length === 0) {
        throw new Error(`${name} variable not found`);
      }
      return variables[0];
    });
  }, [variablesMap]);

  const queryVariablesOnSource = useCallback(
    (variableId: string) => {
      return queryVariables.filter((v) =>
        v.data.sourceIds.includes(variableId),
      );
    },
    [queryVariables],
  );

  if (addingVariable) {
    return (
      <AddEditQueryVariable
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        sourceVariable={sourceVariable}
        addingVariableSource={addingVariable}
        onCancel={() => {
          setAddingVariable(null);
        }}
        {...props}
      />
    );
  }

  if (editingVariable) {
    return (
      <AddEditQueryVariable
        variable={editingVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        sourceVariable={sourceVariable}
        onCancel={() => {
          setEditingVariable(null);
        }}
        {...props}
      />
    );
  }

  return (
    <div className="rounded-lg border p-4 bg-gray-100 border-slate-300">
      <div className="flex gap-4 mb-8 items-center">
        <div className="py-2 px-1 rounded-md bg-white">
          <TriggerBlockImage variable={sourceVariable} className="!w-6" />
        </div>
        <Label>{GmailTriggerVariableEnum.EmailData}</Label>
      </div>

      <div className="mb-8">
        <TriggerQueryVariables
          queryVariables={queryVariablesOnSource(emailDataVariable.id)}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          onClickAdd={() => {
            setAddingVariable(emailDataVariable);
          }}
          onClick={setEditingVariable}
        />
      </div>

      <div className="flex flex-col gap-4">
        <VariableBlock title={GmailTriggerVariableEnum.Subject}>
          <TriggerQueryVariables
            queryVariables={queryVariablesOnSource(subjectVariable.id)}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            onClickAdd={() => {
              setAddingVariable(subjectVariable);
            }}
            onClick={setEditingVariable}
          />
        </VariableBlock>

        <VariableBlock title={GmailTriggerVariableEnum.Body}>
          <TriggerQueryVariables
            queryVariables={queryVariablesOnSource(bodyVariable.id)}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            onClickAdd={() => {
              setAddingVariable(bodyVariable);
            }}
            onClick={setEditingVariable}
          />
        </VariableBlock>

        <VariableBlock title={GmailTriggerVariableEnum.Attachments}>
          <TriggerQueryVariables
            queryVariables={queryVariablesOnSource(attachmentsVariable.id)}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            onClickAdd={() => {
              setAddingVariable(attachmentsVariable);
            }}
            onClick={setEditingVariable}
          />
        </VariableBlock>
      </div>
    </div>
  );
}

function VariableBlock({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <div className="rounded-lg border px-4 pt-2 pb-4 bg-primary-blue-extralight-2 border-indigo-light">
      <Label>{title}</Label>
      <div className="mt-4">{children}</div>
    </div>
  );
}
