import {
  ArrowLeftIcon,
  CloseIcon,
  DescriptionOutlined,
  Button,
  Input,
  Label,
  Select,
} from 'ui-kit';
import React, { type ChangeEvent, useRef, useState } from 'react';
import { clsx } from 'clsx';
import LinearProgress from '@mui/material/LinearProgress';
import { VariableInput } from '../../VariableTypes/VariableInput';
import type { SelectChangeEvent } from '@mui/material/Select';
import {
  type DocumentSource,
  DocumentSourceEnum,
  type DocumentVariable,
  type TemplateData,
  type Variable,
  VariableTypeEnum,
  type WorkflowDocumentNode,
  type GlobalVariable,
  type VariableMap,
} from 'types-shared';
import { v4 as uuid } from 'uuid';
import merge from 'lodash/merge';
import { SameVariableNameError } from '../../SameVariableNameError';
import { formatFileSize } from '../../../../../utils/helper';

interface Props {
  node: WorkflowDocumentNode;
  variable?: DocumentVariable;
  onCancel: () => void;
  onUploadFile: (file: File) => Promise<{ fileId: string }>;
  variablesMap: Record<string, Variable>;
  onSubmit: (variable: DocumentVariable) => void;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
}

export function AddEditDocumentVariable({
  node,
  onCancel,
  variable,
  variablesMap,
  globalVariablesMap,
  onUploadFile,
  onSubmit,
}: Props) {
  const [localName, setLocalName] = useState(variable?.name ?? '');
  const [localSource, setLocalSource] = useState<DocumentSource>(
    variable ? variable.data.source : DocumentSourceEnum.AWS,
  );
  const [urlData, setUrlData] = useState<TemplateData>(
    variable?.data.url ?? [],
  );
  const [pickedFile, setPickedFile] = useState<{
    name: string;
    size?: string;
    uploading?: boolean;
    fileId?: string;
  } | null>(
    variable?.data.s3Ref?.fileName
      ? {
          name: variable.data.s3Ref.fileName,
          uploading: false,
        }
      : null,
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleOnSelectSource = (event: SelectChangeEvent) => {
    if (!event.target.value) return;

    const source = event.target.value as DocumentSource;

    setLocalSource(source);
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];

      if (file) {
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
        if (!allowedTypes.includes(file.type)) {
          return;
        }

        setPickedFile({
          name: file.name,
          size: formatFileSize(file.size),
          uploading: true,
        });

        const { fileId } = await onUploadFile(file);

        setPickedFile((p) => ({
          ...p,
          fileId,
          uploading: false,
        }));
      }
    } finally {
      setPickedFile((p) => ({
        ...p,
        uploading: false,
      }));
    }
  };

  const handleOnSave = () => {
    if (!canCreateOrUpdate()) {
      return;
    }

    const documentData = {
      nodeId: node.id,
      s3Ref: {
        fileId: pickedFile?.fileId ?? '',
        fileName: pickedFile?.name ?? '',
      },
      source: localSource,
      url: urlData,
    };

    if (variable) {
      const updatedVariable = merge({}, variable, {
        name: localName,
        data: documentData,
      });
      onSubmit(updatedVariable);
    } else {
      onSubmit({
        id: uuid(),
        type: VariableTypeEnum.Document,
        name: localName,
        data: documentData,
      });
    }
  };

  const canCreateOrUpdate = () => {
    if (!localName) return false;
    else if (
      localSource === DocumentSourceEnum.AWS &&
      (!pickedFile?.fileId || !pickedFile.name)
    )
      return false;
    else if (localSource === DocumentSourceEnum.URL && !urlData.length)
      return false;

    return true;
  };

  return (
    <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
      <div className="overflow-auto h-full mb-20">
        <div className="flex justify-between items-center pt-8 px-8">
          <div className="flex items-center">
            <span
              className="flex !border !border-solid !border-info !rounded-lg cursor-pointer mr-3.5"
              onClick={onCancel}
              role="presentation"
            >
              <ArrowLeftIcon className="text-info !h-7 !w-7" />
            </span>
            <span className="text-sm font-medium text-info-dark">
              Documents step&nbsp;
            </span>
            <span className="text-sm font-medium text-primary-blue">
              / Add document
            </span>
          </div>
        </div>
        <div className="py-6 px-8 flex justify-between items-center bg-white z-50 sticky top-0">
          <h2 className="text-lg font-medium text-info-dark">Documents step</h2>

          <hr className="border-b border-color-gray-200 absolute bottom-0 left-0 w-full" />
        </div>

        <div className="p-8">
          <Label className="!text-base mb-2">Add document</Label>

          <div className="flex flex-col gap-4 mt-5">
            <div>
              <Input
                floatingLabel
                label="Document name"
                value={localName}
                placeholder="Name the document"
                onChange={setLocalName}
              />
              <SameVariableNameError
                name={localName}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                editingVariableId={variable?.id}
              />
            </div>

            <div>
              <Select
                className="mb-6"
                color="secondary"
                fullWidth
                getLabel={(opt: string) => {
                  if (opt === 'aws') return 'Upload Local File';
                  return 'File URL';
                }}
                getValue={(opt: string) => opt}
                label="Source type"
                name="source"
                onChange={handleOnSelectSource}
                options={['aws', 'url']}
                value={localSource}
              />

              {localSource === DocumentSourceEnum.AWS ? (
                <div>
                  <p className="font-medium mb-4 text-info-dark">Local file</p>

                  <p className="text-gray-500 mb-4">
                    Upload a file from your device. <br />
                    Supported formats: PDF, PNG, JPEG
                  </p>

                  <input
                    accept=".pdf,.png,.jpg"
                    className="hidden display-none"
                    onChange={handleFileSelect}
                    ref={fileInputRef}
                    type="file"
                  />
                  {pickedFile ? (
                    <div className="w-full rounded-lg border-2 border-slate-200 p-3 px-3 relative">
                      <div
                        className={clsx(
                          'flex gap-2',
                          pickedFile.uploading && 'mb-3',
                        )}
                      >
                        <DescriptionOutlined className="mt-1 text-cyan-900" />
                        <div className="flex flex-col justify-center">
                          <span className="text-cyan-900 font-medium leading-tight">
                            {pickedFile.name}
                          </span>
                          {pickedFile.size ? (
                            <p className="text-slate-500 text-xs font-normal leading-none">
                              {pickedFile.size}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <CloseIcon
                        className="absolute top-2 right-2 cursor-pointer"
                        color="secondary"
                        onClick={() => {
                          setPickedFile(null);
                        }}
                      />

                      {pickedFile.uploading ? (
                        <LinearProgress color="secondary" />
                      ) : null}
                    </div>
                  ) : (
                    <Button
                      color="secondary"
                      onClick={() => {
                        fileInputRef.current?.click();
                      }}
                      variant="outlined"
                    >
                      Upload file
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <VariableInput
                    allowAddVariable={false}
                    className="mt-4"
                    value={urlData}
                    label="File URL link"
                    onChange={setUrlData}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex w-full gap-9 px-8 py-6 absolute bottom-0 bg-white">
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            disabled={!canCreateOrUpdate()}
            onClick={() => {
              handleOnSave();
              onCancel();
            }}
            loading={pickedFile?.uploading}
            variant="contained"
          >
            Save changes
          </Button>
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={onCancel}
            variant="outlined"
          >
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
