import MenuItem from '@mui/material/MenuItem';
import type { ChangeEvent } from 'react';
import React, { useMemo, useState, useRef } from 'react';
import {
  VariableTypeEnum,
  type GlobalVariable,
  type Variable,
  type VariableMap,
} from 'types-shared';
import { VariableChip } from '../../../../../../components/VariableChip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, NotFound, Tooltip } from 'ui-kit';
import SearchIcon from '@mui/icons-material/Search';
import { clsx } from 'clsx';
import { useFeatureFlag } from '../../../../../../utils/helper';
import { FeatureFlag } from '../../../../../../utils/constants';
import { Divider } from '@mui/material';
import {
  executionVariableTitleMapping,
  deletableVariableTypes,
} from '../../../../utils/constants';
import { handleException } from 'sentry-browser-shared';
import {
  EditorStore,
  type EditorStoreProps,
} from '../../../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { whichVariablesUsedInWorkflow } from '../../../../utils/delete-variable';

interface Props {
  onClose: () => void;
  onSelect: (val: Variable) => void;
  onAddNew?: () => void;
  variables: Variable[];
  globalVariables: GlobalVariable[];
  allowAddVariable: boolean;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
}

enum MenuTypes {
  Input = 'Input',
  Scrapes = 'Scrapes',
  Shared = 'Shared',
  Execution = 'Execution',
  Documents = 'Documents',
}

type VariableFiltersType = {
  [key in MenuTypes]: VariableTypeEnum;
};

const VariableFilters: VariableFiltersType = {
  [MenuTypes.Input]: VariableTypeEnum.Query,
  [MenuTypes.Scrapes]: VariableTypeEnum.Scrape,
  [MenuTypes.Shared]: VariableTypeEnum.Global,
  [MenuTypes.Execution]: VariableTypeEnum.Execution,
  [MenuTypes.Documents]: VariableTypeEnum.Document,
};

type NoVariablesTextType = {
  [key in MenuTypes]: string;
};

const noVariablesText: NoVariablesTextType = {
  [MenuTypes.Input]:
    'No input variables have been created for this workflow yet',
  [MenuTypes.Scrapes]: 'No scrapes have been created for this workflow yet',
  [MenuTypes.Shared]: 'No global variables have been created for this team yet',
  [MenuTypes.Execution]: '', // Will always exist
  [MenuTypes.Documents]:
    'No document variables have been created for this workflow yet',
};

function VariableMenuContent({
  onClose,
  onSelect,
  onAddNew,
  variables = [],
  globalVariables = [],
  allowAddVariable = true,
  variablesMap,
  globalVariablesMap,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { nodes, deleteVariable } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
      deleteVariable: state.deleteVariable,
    })),
  );
  const [search, setSearch] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<MenuTypes>(MenuTypes.Input);

  const usedVariables = useMemo(
    () => whichVariablesUsedInWorkflow(nodes, variablesMap, handleException),
    [nodes, variablesMap],
  );

  const systemVariablesEnabled = useFeatureFlag(
    FeatureFlag.SystemVariables,
    true,
  );
  const globalVariablesEnabled = useFeatureFlag(FeatureFlag.GlobalVariables);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const enabledVariables = useMemo(() => {
    const otherVariables = variables.filter(
      (v) => v.type !== VariableTypeEnum.Execution,
    );
    const systemVariables = variables.filter(
      (v) => v.type === VariableTypeEnum.Execution,
    );
    return [
      ...(systemVariablesEnabled ? systemVariables : []),
      ...(globalVariablesEnabled ? globalVariables : []),
      ...otherVariables,
    ] as Variable[];
  }, [
    globalVariablesEnabled,
    systemVariablesEnabled,
    globalVariables,
    variables,
  ]);

  const variablesToShow = useMemo(() => {
    const activeVariables: Variable[] = [
      ...variables,
      ...globalVariables,
    ].filter((v) => v.type === VariableFilters[selectedTab]);

    if (search === '') {
      return activeVariables;
    }
    return enabledVariables.filter((variable) => {
      if (variable.type === VariableTypeEnum.Execution) {
        return executionVariableTitleMapping[variable.name]
          .toLowerCase()
          .includes(search.toLowerCase());
      }
      if (variable.type === VariableTypeEnum.Document && !variable.name) {
        return 'document-1'.includes(search.toLowerCase());
      }

      return variable.name?.toLowerCase().includes(search.toLowerCase());
    });
  }, [selectedTab, globalVariables, variables, search, enabledVariables]);

  const tabItems = useMemo(
    () => [
      {
        label: MenuTypes.Input,
        normalMessage: 'These variables are included in the API request',
      },
      {
        label: MenuTypes.Scrapes,
        normalMessage: 'These variables were scraped during the recording',
      },
      {
        label: MenuTypes.Shared,
        normalMessage: 'These variables are shared among your team members',
        disabled: !globalVariablesEnabled,
        disabledMessage:
          'Use shared variables across your team’s workflows. Contact sales to learn more!',
      },
      {
        label: MenuTypes.Execution,
        normalMessage: 'These variables vary depending on the execution',
        disabled: !systemVariablesEnabled,
        disabledMessage: 'Contact sales to access this feature.',
      },
      {
        label: MenuTypes.Documents,
        normalMessage:
          'These were the documents downloaded during the recording',
      },
    ],
    [systemVariablesEnabled, globalVariablesEnabled],
  );

  const onTabChange = (_event: React.SyntheticEvent, newValue: MenuTypes) => {
    const isDisabled = tabItems.find((t) => t.label === newValue)?.disabled;
    if (isDisabled) return;
    setSelectedTab(newValue);
    if (search.length) {
      setSearch('');
    }
  };

  return (
    <React.Fragment key={selectedTab}>
      <Box
        sx={{
          flexShrink: 0,
        }}
      >
        <Tabs
          onChange={onTabChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#3274fb',
            },
            '& .Mui-selected': {
              color: '#3274fb !important',
            },
          }}
          value={selectedTab}
        >
          {tabItems.map(
            ({ label, disabled, disabledMessage, normalMessage }) => (
              <Tab
                key={label}
                value={MenuTypes[label as keyof typeof MenuTypes]}
                disabled={disabled}
                label={
                  <Tooltip
                    title={disabled ? disabledMessage : normalMessage}
                    arrow
                  >
                    <span className={disabled ? 'text-gray-400' : ''}>
                      {label}
                    </span>
                  </Tooltip>
                }
              />
            ),
          )}
        </Tabs>
      </Box>

      <Box
        sx={{
          flexShrink: 0,
        }}
      >
        {[...variables, ...globalVariables].length > 0 ? (
          <MenuItem
            className="!bg-white !px-3 !py-4 "
            disableRipple
            onClick={() => inputRef.current?.focus()}
          >
            <div
              className={clsx({
                'outline-none border rounded w-full': true,
                ' pl-1': !search.length,
                'focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500':
                  !search.length,
              })}
              onClick={() => inputRef.current?.focus()}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              role="presentation"
            >
              {search.length ? null : (
                <SearchIcon className="text-info-dark mr-1" />
              )}

              <input
                ref={inputRef}
                className={clsx({
                  'p-1 text-sm': true,
                  'border-0 focus:outline-none': !search.length,
                  'w-full ': Boolean(search.length),
                })}
                onChange={onSearchChange}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                placeholder="Search variable"
                value={search}
              />
            </div>
          </MenuItem>
        ) : (
          <MenuItem
            className="!px-3 !py-2.5 pointer-events-none text-sm"
            divider
          >
            {noVariablesText[selectedTab]}
          </MenuItem>
        )}
      </Box>

      <Box
        sx={{
          flex: '1 1 auto',
        }}
      >
        {variablesToShow.length ? (
          <div
            className="overflow-y-auto"
            style={{ maxHeight: '500px' }}
            key={selectedTab}
          >
            {variablesToShow.map((variable: Variable) => {
              const isUsed = usedVariables.includes(variable.id);
              const isDeletable = deletableVariableTypes.includes(
                variable.type,
              );

              return (
                <MenuItem
                  className="!px-3 !py-2.5"
                  key={variable.id}
                  onClick={() => {
                    onSelect(variable);
                    onClose();
                  }}
                >
                  <VariableChip
                    variableId={variable.id}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    {...(isUsed || !isDeletable
                      ? {}
                      : {
                          onDelete: () => {
                            deleteVariable(variable.id);
                          },
                        })}
                  />
                </MenuItem>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center p-5">
            {search.length ? (
              <div>
                <NotFound
                  fontSize="large"
                  style={{
                    height: '55px',
                    width: '43px',
                  }}
                />
              </div>
            ) : null}
            <p
              className={clsx({
                'text-gray-500 text-normal text-center text-sm': true,
                'mt-3': search.length,
                'mt-10': !search.length,
              })}
            >
              {search.length
                ? `There are no matching results for "${search}".`
                : noVariablesText[selectedTab]}
            </p>
          </div>
        )}
      </Box>

      {selectedTab === MenuTypes.Input ? (
        <Box
          sx={{
            flexShrink: 0,
          }}
        >
          <Divider />
          <MenuItem
            className="!border-t !border-t-gray-300 !text-blue-600 !px-3 !py-2.5"
            onClick={() => onAddNew?.()}
          >
            <Button
              className="w-full h-9"
              color="secondary"
              onClick={() => onAddNew?.()}
              variant="contained"
              disabled={!allowAddVariable}
            >
              Add input variable
            </Button>
          </MenuItem>
        </Box>
      ) : null}
    </React.Fragment>
  );
}

export default VariableMenuContent;
