import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ContentContainer,
  Input,
  ScrollableContainer,
  Tooltip,
  CustomTypography,
  FileSearchIcon,
} from 'ui-kit';
import { type TeamMember } from 'hasura-gql';
import {
  type GridColDef,
  type GridRenderCellParams,
} from '@mui/x-data-grid/models';
import ListSkeleton from '../../components/ListSkeleton';
import { NavigationPanel } from '../../components/NavigationPanel';
import { useTeamMembers } from './hooks.gql';
import { useAuth } from '../../utils/helper';

const tooltipRoleMapping: Record<string, string> = {
  Administrator:
    'Admins have full access to all the workflows and executions of all the members',
  Member: 'Members have access to their own workflows and execution',
};

const columns: GridColDef[] = [
  { field: 'email', headerName: 'Email', flex: 1 },
  { field: 'workflowCount', headerName: 'Number of workflows', flex: 1 },
  {
    field: 'role',
    headerName: 'Role',
    minWidth: 200,
    renderCell: ({ row }: GridRenderCellParams<TeamMember>) => (
      <Tooltip
        arrow
        hidden={!tooltipRoleMapping[row.role]}
        placement="left"
        title={tooltipRoleMapping[row.role]}
      >
        <span>{row.role}</span>
      </Tooltip>
    ),
  },
];

export default function Members() {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const { user } = useAuth();
  const userId = user?.sub ?? '';
  const {
    data: members = [],
    loading: isLoading,
    isAdmin,
  } = useTeamMembers(userId);

  const filteredMembers = useMemo(() => {
    return members.filter(
      ({ email }) =>
        !search || email.toLowerCase().includes(search.toLowerCase()),
    );
  }, [members, search]);

  useEffect(() => {
    if (!isAdmin && !isLoading) {
      navigate('/');
    }
  }, [isAdmin, isLoading, navigate]);

  return (
    <ScrollableContainer>
      <div className="w-full flex">
        <NavigationPanel />
        <ContentContainer>
          <div className="flex justify-between w-full items-center">
            <div>
              <CustomTypography variant="h4">Members</CustomTypography>
              <div className="mt-2">
                <span className="font-normal text-sm">
                  Add members, remove members, and modify member permissions
                  from your organization.
                </span>
              </div>
            </div>
            <Tooltip
              arrow
              placement="left"
              title="Adding members is currently unavailable from the dashboard, contact us to modify the members of your organization."
            >
              <Button color="primary" disabled variant="contained">
                Add New Member
              </Button>
            </Tooltip>
          </div>
          <div className="flex flex-col space-y-8 mt-8">
            <Input
              classes={{ root: '!w-80' }}
              floatingLabel
              label="Search"
              onChange={setSearch}
              placeholder="Search by email"
              value={search}
            />
            {isLoading ? <ListSkeleton columns={columns} /> : null}
            {!isLoading && filteredMembers.length > 0 && (
              <DataGrid<TeamMember>
                columns={columns}
                getRowId={(row) => row.email}
                rows={filteredMembers}
              />
            )}
            {!isLoading && filteredMembers.length === 0 && (
              <div className="flex justify-between items-center h-72 bg-primary-blue-extralight-2 px-12 py-14 rounded-lg">
                {members.length > 0 ? (
                  <CustomTypography variant="h6">
                    There are no matching results for “{search}”
                  </CustomTypography>
                ) : (
                  <CustomTypography variant="h6">
                    No members found
                  </CustomTypography>
                )}
                <FileSearchIcon />
              </div>
            )}
          </div>
        </ContentContainer>
      </div>
    </ScrollableContainer>
  );
}
