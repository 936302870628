import {
  CloseIcon,
  CloudDownloadOutlinedIcon,
  CloudQueueIcon,
  ComputerIcon,
  DoneIcon,
  InfoOutlined,
  Logo,
  Button,
  modalEventChannel,
  Spinner,
  Tooltip,
} from 'ui-kit';
import {
  type MouseEvent,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { clsx } from 'clsx';
import type {
  GetWorkflowMetadataResponse,
  VersionConfigurations,
} from 'api-types-shared';
import { WorkflowStatusEnum } from 'api-types-shared';
import {
  DEFAULT_ZOOM,
  editorMaxZoomLevel,
  editorMinZoomLevel,
} from '../utils/constants';
import type {
  EdgeTypes,
  NodeTypes,
  ReactFlowInstance,
  Viewport,
} from 'types-shared/reactflow';
import {
  Background,
  ConnectionLineType,
  ReactFlow,
  ReactFlowProvider,
  SelectionMode,
} from 'types-shared/reactflow';
import type { WorkflowEdge, WorkflowNode } from 'types-shared';
import { NodeTypesEnum } from 'types-shared';
import dayjs from '../../../config/dayjs';
import { Connector } from './EdgeElement/Connector';
import { useTeamMembers } from '../../Members/hooks.gql';
import { type TeamMember } from 'hasura-gql';
import { useAuth } from '../../../utils/helper';
import { adjustEdgesForHiddenNodes } from '../utils/hidden-nodes.helper';
import { isAdmin } from '../../../utils/env';

interface ContainerProps {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
  selected?: boolean;
}

function Container({ className, children, onClick, selected }: ContainerProps) {
  return (
    <div
      className={clsx(
        'p-4 rounded-lg border cursor-pointer flex flex-col space-y-3 hover:border-sola-primary hover:ring-1 hover:ring-sola-primary hover:bg-sola-primary-10',
        {
          'border-sola-primary ring-1 ring-sola-primary bg-sola-primary-10':
            selected,
        },
        className,
      )}
      onClick={onClick}
      role="presentation"
    >
      {children}
    </div>
  );
}

interface VersionItemProps {
  className?: string;
  onClick: (version: string) => void;
  onRestore: (version: string) => void;
  selectedVersion?: string;
  version: VersionConfigurations;
  disableRestore?: boolean;
  userMap: Record<string, string>;
}

function VersionItem({
  className,
  onClick,
  onRestore,
  selectedVersion,
  version,
  disableRestore,
  userMap,
}: VersionItemProps) {
  const ready = version.status === WorkflowStatusEnum.Ready;
  const selected = selectedVersion === version.versionId;

  const commitUserEmail = useMemo(
    () => (version.commitUsers?.[0] ? userMap[version.commitUsers[0]] : ''),
    [version.commitUsers, userMap],
  );

  const onRestoreVersion = (e: MouseEvent) => {
    e.stopPropagation();
    modalEventChannel.emit('open', {
      title: 'Are you sure you want to restore the version?',
      descriptions: [
        'Restoring a version overwrites the current version in the cloud and on this device.',
      ],
      actions: [
        {
          text: 'yes, restore version',
          onClick: () => {
            modalEventChannel.emit('close');
            onRestore(version.stateVersion);
          },
        },
        {
          text: 'cancel',
          onClick: () => {
            modalEventChannel.emit('close');
          },
          variant: 'outlined',
        },
      ],
      alignActions: 'left',
    });
  };

  return (
    <Container
      className={clsx('text-sm', className)}
      key={version.committedAt}
      onClick={() => {
        if (version.versionId) {
          onClick(version.versionId);
        }
      }}
      selected={selected}
    >
      <>
        <div className="flex justify-between items-center text-sm">
          <div className="flex flex-col items-start space-y-3 w-2/5">
            <p className="inline-flex items-center font-medium">
              {dayjs(version.committedAt).format('MM/DD/YY - h:mm A')}
            </p>
            {commitUserEmail ? (
              <p className="text-sm text-slate-700 w-full truncate">
                {commitUserEmail}
              </p>
            ) : null}
          </div>
          <div className="flex flex-col items-end space-y-3 w-2/5">
            <p className="inline-flex items-center">
              {ready ? 'Ready to execute' : 'Not ready to execute'}
              {ready ? (
                <DoneIcon className="!w-4 !h-4 !ml-2 !text-success" />
              ) : (
                <Tooltip
                  placement="right"
                  title="This version was saved but cannot be executed."
                >
                  <InfoOutlined className="!w-4 !h-4 !ml-2" />
                </Tooltip>
              )}
            </p>
            {version.versionId && ready ? (
              <p className="inline-flex items-center text-xs">
                Version ID: <b className="ml-1">{version.versionId}</b>
              </p>
            ) : null}
          </div>
        </div>
        {selected ? (
          <Button
            className="!mt-8"
            color="secondary"
            disabled={disableRestore}
            onClick={onRestoreVersion}
            variant="outlined"
          >
            Restore version
          </Button>
        ) : null}
      </>
    </Container>
  );
}

interface Props {
  isLoading?: boolean;
  workflowMetadata?: GetWorkflowMetadataResponse | null;
  nodeTypes: NodeTypes;
  edgeTypes: EdgeTypes;
  edges: WorkflowEdge[];
  nodes: WorkflowNode[];
  onCancel: () => void;
  onLoadVersion: (version: string) => void;
  onRestore: () => void;
  selectedVersion: string;
  versions: VersionConfigurations[];
  downloadCloudVersion: () => void;
  uploadLocalVersionToCloud: () => void;
}

function ReadonlyReactFlow({
  nodes,
  edgeTypes,
  nodeTypes,
  edges,
  currentViewport,
  setCurrentViewport,
}: Pick<Props, 'nodes' | 'edges' | 'nodeTypes' | 'edgeTypes'> & {
  currentViewport: Viewport | null;
  setCurrentViewport: (viewport: Viewport) => void;
}) {
  const handleDefaultViewport = (instance: ReactFlowInstance) => {
    instance.fitView({
      nodes: nodes.filter((node) => node.type === NodeTypesEnum.Source),
      maxZoom: DEFAULT_ZOOM,
      minZoom: DEFAULT_ZOOM,
    });
  };

  return (
    <ReactFlow
      attributionPosition="top-right"
      className="relative readonly"
      connectionLineComponent={Connector}
      connectionLineType={ConnectionLineType.Bezier}
      defaultEdgeOptions={{ deletable: false, focusable: false }}
      edgeTypes={edgeTypes}
      edges={edges}
      edgesFocusable={false}
      edgesUpdatable={false}
      elementsSelectable={false}
      maxZoom={editorMaxZoomLevel}
      minZoom={editorMinZoomLevel}
      nodeTypes={nodeTypes}
      nodes={nodes}
      nodesConnectable={false}
      nodesDraggable={false}
      nodesFocusable={false}
      onInit={(instance) => {
        if (!currentViewport) {
          handleDefaultViewport(instance);
        } else {
          instance.setViewport(currentViewport);
        }
      }}
      onMove={(_event, data) => {
        setCurrentViewport(data);
      }}
      panOnDrag
      panOnScroll
      proOptions={{ hideAttribution: true }}
      selectionMode={SelectionMode.Partial}
      selectionOnDrag={false}
    >
      <Background className="bg-flow-view" />
    </ReactFlow>
  );
}

export default function VersionHistoryPanel({
  isLoading,
  workflowMetadata,
  nodeTypes,
  edgeTypes,
  edges = [],
  nodes = [],
  onCancel,
  onLoadVersion,
  onRestore,
  versions,
  downloadCloudVersion,
  uploadLocalVersionToCloud,
  selectedVersion,
}: Props) {
  const workflowOwnerId = workflowMetadata?.userId;
  const { user: authUser } = useAuth();
  const userId = authUser?.sub ?? workflowOwnerId;
  const { data: members } = useTeamMembers(userId);
  const userMap: Record<string, string> = useMemo(() => {
    if (!members) {
      return {};
    }
    return members.reduce((acc: Record<string, string>, user: TeamMember) => {
      acc[user.id] = user.email;
      return acc;
    }, {});
  }, [members]);
  const isProcessingImport =
    workflowMetadata?.status === WorkflowStatusEnum.ProcessingImport;
  const ready = workflowMetadata?.status === WorkflowStatusEnum.Ready;

  const selectedVersionObj = useMemo(
    () => versions.find((version) => version.versionId === selectedVersion),
    [selectedVersion, versions],
  );

  const onCloudVersionRestore = () => {
    modalEventChannel.emit('open', {
      title: 'Are you sure you want to restore the version?',
      descriptions: [
        'Restoring a version overwrites the current version in the cloud and on this device.',
      ],
      actions: [
        {
          text: 'yes, restore version',
          onClick: () => {
            modalEventChannel.emit('close');
            onRestore();
          },
        },
        {
          text: 'cancel',
          onClick: () => {
            modalEventChannel.emit('close');
          },
          variant: 'outlined',
        },
      ],
      alignActions: 'left',
    });
  };

  const [currentViewport, setCurrentViewport] = useState<Viewport | null>(null);
  const [versionNodes, setVersionNodes] = useState<WorkflowNode[]>([]);
  const [versionEdges, setVersionEdges] = useState<WorkflowEdge[]>([]);

  // only update nodes and edges when loading is off
  useEffect(() => {
    if (!isLoading && nodes.length && edges.length) {
      const { nodes: adjustedNodes, edges: adjustedEdges } =
        adjustEdgesForHiddenNodes(nodes, edges);
      setVersionNodes(adjustedNodes);
      setVersionEdges(adjustedEdges);
    }
  }, [isLoading, nodes, edges]);

  return (
    <div className="w-screen h-screen overflow-hidden fixed top-0 left-0 flex flex-col bg-white">
      <div className="py-5 px-4 w-full flex justify-between items-center border-b !h-20">
        <div className="flex space-x-6 items-center">
          <Logo className="!w-7 !h-7" />
          <span className="text-xs">
            {workflowMetadata?.workflowName}
            <br />
            <b className="text-info">Version History</b>
          </span>
        </div>
        <p className="text-sm">
          {selectedVersionObj
            ? dayjs(selectedVersionObj.committedAt).format('MM/DD/YY - h:mm A')
            : 'Current version on this device'}{' '}
          <span className="text-gray-500">- View only</span>
        </p>
        <Button
          className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
          color="secondary"
          onClick={onCancel}
          variant="outlined"
        >
          <CloseIcon className="text-info" />
        </Button>
      </div>
      <div className="flex-1 flex overflow-hidden">
        <div className="w-128 flex flex-col items-stretch border-r">
          <div className="flex justify-between items-center p-8">
            <div className="flex flex-col">
              <h2 className="text-lg font-medium text-info-dark">
                Version history
              </h2>
              <p className="text-sm text-gray-600">
                Preview and restore previous versions of this workflow
              </p>
            </div>
          </div>

          <div className="py-8 pt-0 flex flex-col space-y-6 border-b overflow-y-auto">
            <h2 className="text-sm font-medium mx-6">Current versions</h2>
            <Container
              className="text-sm flex-1 mx-6"
              onClick={() => {
                onLoadVersion('local');
              }}
              selected={selectedVersion === 'local'}
            >
              <>
                <div className="flex justify-between items-center font-medium">
                  <p className="inline-flex items-center">
                    <ComputerIcon className="!w-4 !h-4 !mr-2" />
                    On this device
                  </p>
                  <div className="flex items-center space-x-4">
                    <button onClick={downloadCloudVersion} type="button">
                      <CloudDownloadOutlinedIcon className="!w-5 !h-5 text-primary-blue" />
                    </button>
                    <Button
                      className="!px-2 !py-0 !min-w-min !h-7 !text-xs"
                      color="secondary"
                      variant="outlined"
                      onClick={uploadLocalVersionToCloud}
                    >
                      Save
                    </Button>
                  </div>
                </div>
                <p className="inline-flex items-center opacity-65">
                  <Tooltip
                    placement="right"
                    title="This is the current workflow stored on your device. Changes you make in your browser will automatically be saved here."
                  >
                    <InfoOutlined className="!w-4 !h-4 !mr-2" />
                  </Tooltip>
                  Draft
                </p>
              </>
            </Container>
            <Container
              className="text-sm flex-1 mx-6"
              onClick={() => {
                onLoadVersion('cloud');
              }}
              selected={selectedVersion === 'cloud'}
            >
              <>
                <div className="flex justify-between items-center font-medium">
                  <p className="inline-flex items-center">
                    <CloudQueueIcon className="!w-4 !h-4 !mr-2" />
                    On cloud
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-sm font-medium">
                    {dayjs(workflowMetadata?.committedAt).format(
                      'MM/DD/YY - h:mm A',
                    )}
                  </p>
                  <p className="text-sm inline-flex items-center font-normal">
                    {ready ? 'Ready to execute' : 'Not ready to execute'}
                    {ready ? (
                      <DoneIcon className="!w-4 !h-4 !ml-2 !text-success" />
                    ) : (
                      <Tooltip
                        placement="right"
                        title="This version was saved but cannot be executed."
                      >
                        <InfoOutlined className="!w-4 !h-4 !ml-2" />
                      </Tooltip>
                    )}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-sm text-slate-700 w-1/2 truncate">
                    {workflowMetadata?.currentVersionCommitUsers?.[0]
                      ? userMap[workflowMetadata.currentVersionCommitUsers[0]]
                      : ''}
                  </p>
                  <p className="inline-flex items-center font-normal">
                    Version ID:
                    <b className="ml-1">{workflowMetadata?.currentVersionId}</b>
                  </p>
                </div>
                {selectedVersion === 'cloud' ? (
                  <Button
                    className="!mt-8"
                    color="secondary"
                    onClick={onCloudVersionRestore}
                    variant="outlined"
                  >
                    Restore version
                  </Button>
                ) : null}
              </>
            </Container>
            <hr className="!my-8" />
            <h2 className="text-sm font-medium mx-6 !mt-0">
              Previous versions in cloud
            </h2>
            {isProcessingImport && !isAdmin ? (
              <div className="m-6 bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded">
                <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
                <span className="text-primary-blue-dark text-sm">
                  You can't restore previous versions while the workflow is
                  being processed
                </span>
              </div>
            ) : null}
            {versions.map((item) => (
              <VersionItem
                className="mx-6"
                key={item.versionId}
                onClick={(version) => {
                  onLoadVersion(version);
                }}
                onRestore={onRestore}
                selectedVersion={selectedVersion}
                version={item}
                disableRestore={Boolean(isProcessingImport && !isAdmin)}
                userMap={userMap}
              />
            ))}
          </div>
        </div>

        <ReactFlowProvider>
          <div className={clsx('flex-1 h-full w-full relative flex flex-col')}>
            <ReadonlyReactFlow
              currentViewport={currentViewport}
              edgeTypes={edgeTypes}
              edges={versionEdges}
              nodeTypes={nodeTypes}
              nodes={versionNodes}
              setCurrentViewport={setCurrentViewport}
            />
            {isLoading ? (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
                <Spinner className="!text-white" size={32} />
              </div>
            ) : null}
          </div>
        </ReactFlowProvider>
      </div>
    </div>
  );
}
