import {
  ArrowLeftIcon,
  MoreVert,
  Button,
  DeleteIcon,
  IconButton,
  Input,
  Menu,
  MenuItem,
  modalEventChannel,
} from 'ui-kit';
import { useState, useEffect } from 'react';
import {
  type Condition,
  type DatasourceMetadata,
  type DatasourceTable,
  type Group,
  type OperatorEnum,
  type Variable,
  type WorkflowEdge,
  type TemplateData,
  type SourceTypeEnum,
  type GlobalVariable,
  type VariableMap,
  type TemplateVariable,
  type WorkflowNode,
  WorkflowConditionalNode,
  BranchData,
  BranchModeEnum,
} from 'types-shared';
import BranchInstruction from './BranchInstruction';
import GroupBlock from '../Conditions/GroupBlock';
import {
  addConditionHelper,
  deleteConditionHelper,
  initialGroup,
  updateConditionHelper,
} from '../Conditions/conditions.helpers';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { createTemplateVariable } from './request.helpers';
import { handleException } from 'sentry-browser-shared';

interface Props {
  node: WorkflowNode;
  edge: WorkflowEdge;
  branchData?: BranchData;
  onCancel: () => void;
  onDelete: () => void;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  addVariable: (variable: Variable) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  sourceType?: SourceTypeEnum;
  onTransformApiReq: (
    prompt: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
}

export function EditBranch({
  node,
  branchData,
  edge,
  onCancel,
  onDelete,
  datasourceMetadata,
  variablesMap,
  globalVariablesMap,
  tableData,
  addVariable,
  onTransformApiReq,
  transformApiReqStatus,
  sourceType,
}: Props) {
  const { updateNode, setEdges, edges, updateVariable } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      updateNode: state.updateNode,
      setEdges: state.setEdges,
      edges: state.edges,
      updateVariable: state.updateVariable,
    })),
  );

  const updateEdgeName = (newName: string) => {
    if (newName) {
      setEdges(
        edges.map((_edge) => {
          if (_edge.id === edge.id) {
            return {
              ..._edge,
              label: newName,
            };
          }
          return _edge;
        }),
      );
    }
  };

  const instructionVariable = branchData?.instruction
    ? (variablesMap[branchData.instruction.variableId] as TemplateVariable)
    : undefined;
  const [edgeName, setEdgeName] = useState<string>(
    typeof edge.label === 'string' ? edge.label : '',
  );
  const [instruction, setInstruction] = useState(
    instructionVariable?.data ?? [],
  );
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isInstructionsOpen, setIsInstructionsOpen] = useState<boolean>(
    branchData?.selectedMode === BranchModeEnum.Instruction,
  );
  const menuOpen = Boolean(menuAnchorEl);

  const [group, setGroup] = useState<Group>();
  const [hasSetDefaultGroup, setHasSetDefaultGroup] = useState(false);

  useEffect(() => {
    if (!group && !hasSetDefaultGroup) {
      if (branchData?.rule?.data) {
        setGroup(branchData.rule.data);
      } else {
        setGroup(initialGroup(addVariable));
      }
      setHasSetDefaultGroup(true);
    }
  }, [branchData, hasSetDefaultGroup, addVariable, group]);

  const closeModal = () => {
    modalEventChannel.emit('close');
  };

  const openModal = () => {
    modalEventChannel.emit('open', {
      title: 'Are you sure you want to delete this branch?',
      descriptions: [
        typeof edge.label === 'string'
          ? `Branch Name: ${edge.label}`
          : undefined,
        'The steps in this branch will be permanently deleted, including any actions and variable configurations. Any executions going forward will not include these steps, though they will still exist in completed and ongoing executions.',
      ].filter(Boolean) as string[],
      actions: [
        {
          text: 'Yes, Delete branch',
          onClick: () => {
            onDelete();
            modalEventChannel.emit('close');
          },
          color: 'error',
        },
        {
          text: 'cancel',
          onClick: () => {
            closeModal();
          },
          variant: 'text',
        },
      ],
    });
  };

  const addCondition = (
    groupId: string,
    operator: OperatorEnum,
    andConditionId?: string,
  ) => {
    if (group) {
      setGroup((prevGroup) => {
        if (!prevGroup) {
          handleException(new Error(), {
            name: 'No group during addCondition',
            source: 'EditBranch/addCondition',
            extra: {
              groupId,
              operator,
              andConditionId,
              prevGroup,
            },
          });
          return;
        }
        return addConditionHelper(
          prevGroup,
          groupId,
          operator,
          addVariable,
          andConditionId,
        );
      });
    }
  };

  const deleteCondition = (conditionId: string) => {
    setGroup((prevGroup) => {
      if (!prevGroup) {
        handleException(new Error(), {
          name: 'No group during deleteCondition',
          source: 'EditBranch/deleteCondition',
          extra: {
            prevGroup,
            conditionId,
          },
        });
        return;
      }
      return deleteConditionHelper(prevGroup, conditionId);
    });
  };

  const updateCondition = (conditionId: string, data: Partial<Condition>) => {
    setGroup((prevGroup) => {
      if (!prevGroup) {
        handleException(new Error(), {
          name: 'No group during updateCondition',
          source: 'EditBranch/updateCondition',
          extra: {
            prevGroup,
            conditionId,
            data,
          },
        });
        return;
      }
      return updateConditionHelper(prevGroup, conditionId, data);
    });
  };

  const saveChanges = () => {
    if (!edgeName) return;

    updateEdgeName(edgeName);

    const conditionalNode = node as WorkflowConditionalNode;

    const rulePayload = {
      data: group,
      output: branchData?.rule?.output ?? [{ id: branchData?.branchId }],
    };

    const updatedBranch = {
      ...branchData,
      rule: rulePayload,
      selectedMode: isInstructionsOpen
        ? BranchModeEnum.Instruction
        : BranchModeEnum.Rule,
    } as BranchData;

    BranchData.parse(updatedBranch);

    const nodePayload = {
      ...conditionalNode,
      instruction: branchData?.instruction ?? {
        variableId: createTemplateVariable(addVariable).id,
      },
      data: {
        ...conditionalNode.data,
        branchesData: conditionalNode.data.branchesData
          ? conditionalNode.data.branchesData.map((b) =>
              b.branchId === branchData?.branchId ? updatedBranch : b,
            )
          : [updatedBranch],
      },
    };

    WorkflowConditionalNode.parse(nodePayload);

    updateNode(nodePayload as WorkflowConditionalNode);

    onCancel();
  };

  const updateInstruction = () => {
    if (edgeName) {
      updateEdgeName(edgeName);
    }

    const payload = {
      ...instructionVariable,
      data: instruction,
    };

    updateVariable(payload as TemplateVariable);

    saveChanges();
  };

  return (
    <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
      <div className="overflow-auto h-full mb-20">
        <div className="flex justify-between items-center pt-8 px-8">
          <div className="flex items-center">
            <span
              className="flex !border !border-solid !border-info !rounded-lg cursor-pointer mr-3.5"
              onClick={onCancel}
              role="presentation"
            >
              <ArrowLeftIcon className="text-info !h-7 !w-7" />
            </span>
            <span className="text-sm font-medium text-info-dark">
              Conditional step&nbsp;
            </span>
            <span className="text-sm font-medium text-primary-blue">
              / Branch
            </span>
          </div>
        </div>
        <div className="py-6 px-8 flex justify-between items-center bg-white z-50 sticky top-0">
          <h2 className="text-lg font-medium text-info-dark">
            Branch: {edge.label}
          </h2>
          <div>
            <IconButton
              aria-controls={menuOpen ? 'basic-menu' : undefined}
              aria-expanded={menuOpen ? 'true' : undefined}
              aria-haspopup="true"
              aria-label="more"
              onClick={(event) => {
                setMenuAnchorEl(event.currentTarget);
              }}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              onClose={() => {
                setMenuAnchorEl(null);
              }}
              open={menuOpen}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                className="flex justify-between items-center"
                onClick={() => {
                  setMenuAnchorEl(null);
                  openModal();
                }}
              >
                <p className="text-error max-w-50 min-w-50">Delete branch</p>
                <DeleteIcon className="!w-4 !h-4 text-error !fill-none" />
              </MenuItem>
              {isInstructionsOpen ? (
                <MenuItem
                  onClick={() => {
                    setMenuAnchorEl(null);
                    setIsInstructionsOpen(false);
                  }}
                >
                  <p className="max-w-50 min-w-50 text-wrap">
                    Determine by conditions
                  </p>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    setMenuAnchorEl(null);
                    setIsInstructionsOpen(true);
                  }}
                >
                  <p className="max-w-50 min-w-50 text-wrap">
                    Determine conditions via AI
                  </p>
                </MenuItem>
              )}
            </Menu>
          </div>
          <hr className="border-b border-color-gray-200 border-2 absolute bottom-0 left-0 w-full" />
        </div>

        {!isInstructionsOpen && group ? (
          <div>
            <div className="flex-1 flex flex-col gap-4 mt-6 px-8 mb-8">
              <p className="font-bold text-sm">Branch Name</p>
              <Input
                floatingLabel
                label="Branch Name"
                onChange={(e) => {
                  setEdgeName(e);
                }}
                placeholder="Branch Name"
                value={edgeName}
              />
            </div>
            <div className="px-8 pb-12">
              <GroupBlock
                datasourceMetadata={datasourceMetadata}
                edgeName={edgeName}
                group={group}
                label="Take this branch when"
                onAddCondition={addCondition}
                onDeleteCondition={deleteCondition}
                onTransformApiReq={onTransformApiReq}
                onUpdateCondition={updateCondition}
                tableData={tableData}
                transformApiReqStatus={transformApiReqStatus}
                sourceType={sourceType}
                updateVariable={updateVariable}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
              />
            </div>
            <div className="flex w-full gap-9 px-8 py-6 absolute bottom-0 bg-white drop-shadow shadow-top-15">
              <Button
                className="!flex-1"
                color="secondary"
                disabled={!edgeName}
                fullWidth
                onClick={saveChanges}
                variant="contained"
              >
                Save changes
              </Button>
              <Button
                className="!flex-1"
                color="secondary"
                fullWidth
                onClick={onCancel}
                variant="outlined"
              >
                cancel
              </Button>
            </div>
          </div>
        ) : (
          <BranchInstruction
            edgeName={edgeName}
            instruction={instruction}
            sourceType={sourceType}
            onCancel={onCancel}
            onUpdateInstruction={updateInstruction}
            setEdgeName={setEdgeName}
            setInstruction={setInstruction}
            globalVariablesMap={globalVariablesMap}
            variablesMap={variablesMap}
            datasourceMetadata={datasourceMetadata}
          />
        )}
      </div>
    </div>
  );
}
