import {
  ArrowLeftIcon,
  InfoOutlined,
  SendIcon,
  Button,
  CheckCircleIcon,
  IconButton,
  Input,
  Label,
  Spinner,
  SubLabel,
} from 'ui-kit';
import React, { useState } from 'react';
import {
  QueryValueTypeEnum,
  type QueryVariable,
  type SourceVariable,
  type TemplateData,
  type Variable,
  VariableString,
  VariableTypeEnum,
  type WorkflowSourceNode,
  type GlobalVariable,
  type VariableMap,
} from 'types-shared';
import { VariableInput } from '../../VariableTypes/VariableInput';
import { clsx } from 'clsx';
import { v4 as uuid } from 'uuid';
import merge from 'lodash/merge';
import {
  getTriggerBlockShortTitle,
  getTriggerBlockTitle,
} from './trigger.helpers';
import TriggerBlockImage from './TriggerBlockImage';
import { SameVariableNameError } from '../../SameVariableNameError';

interface Props {
  node: WorkflowSourceNode;
  sourceVariable: SourceVariable;
  onCancel: () => void;

  variable?: QueryVariable;
  updateVariable: (variable: QueryVariable) => void;
  addVariable: (variable: QueryVariable) => void;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;

  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    query: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;

  addingVariableSource?: QueryVariable;
}

export function AddEditQueryVariable({
  node,
  onCancel,
  variable,
  variablesMap,
  updateVariable,
  addVariable,
  sourceVariable,
  addingVariableSource,
  globalVariablesMap,
  transformApiReqStatus,
  onTransformApiReq,
}: Props) {
  const [localData, setLocalData] = useState<{
    name: string;
    query: TemplateData;
    transformedValue: string;
    initialValue: string;
  }>({
    name: variable?.name ?? '',
    initialValue: VariableString.parse(
      variable?.dashboardData?.initialValue ?? '',
    ),
    query: variable?.dashboardData?.transformInputs?.query ?? [],
    transformedValue: variable
      ? VariableString.parse(
          variable.dashboardData?.transformInputs?.transformedValue ?? '',
        )
      : '',
  });
  const [isFormDirty, setIsFormDirty] = useState(false);

  const transformApiReq = () => {
    onTransformApiReq(localData.query, localData.initialValue || '')
      .then((transformedValue) => {
        if (transformedValue) {
          setLocalData({
            ...localData,
            transformedValue,
          });
        }
      })
      .catch(() => {
        // do nothing, we are already showing an error below
      })
      .finally(() => {
        setIsFormDirty(false);
      });
  };

  const resetForm = () => {
    setLocalData({
      name: '',
      query: [],
      transformedValue: '',
      initialValue: '',
    });
  };

  const handleOnSave = () => {
    const sourceIds = variable
      ? variable.data.sourceIds
      : [addingVariableSource?.id ?? sourceVariable.id];

    const commonFields = {
      name: localData.name,
      dashboardData: {
        initialValue: localData.initialValue,
        transformInputs: {
          query: localData.query,
          transformedValue: localData.transformedValue,
        },
      },
      data: {
        sourceIds,
        query: localData.query,
        valueType: QueryValueTypeEnum.String,
      },
    };

    if (variable) {
      const updatedVariable = merge({}, variable, commonFields);
      updateVariable(updatedVariable);
      onCancel();
    } else {
      addVariable({
        id: uuid(),
        type: VariableTypeEnum.Query,
        ...commonFields,
      });
      resetForm();
      onCancel();
    }
  };

  return (
    <div className="absolute left-0 top-0 bottom-0 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
      <div className="overflow-auto h-full mb-20">
        <div className="flex justify-between items-center pt-8 px-8">
          <div className="flex items-center">
            <span
              className="flex !border !border-solid !border-info !rounded-lg cursor-pointer mr-3.5"
              onClick={onCancel}
              role="presentation"
            >
              <ArrowLeftIcon className="text-info !h-7 !w-7" />
            </span>
            <span className="text-sm font-medium text-info-dark">
              {getTriggerBlockShortTitle(sourceVariable)}&nbsp;
            </span>
            <span className="text-sm font-medium text-primary-blue">
              / Creating Variable
            </span>
          </div>
        </div>
        <div className="py-6 px-8 flex justify-between items-center bg-white z-50 sticky top-0">
          <h2 className="text-lg font-medium text-info-dark">
            {getTriggerBlockTitle(node, sourceVariable)}
          </h2>

          <hr className="border-b border-color-gray-200 absolute bottom-0 left-0 w-full" />
        </div>

        <div className="p-8">
          <Label className="!text-base">Create a variable</Label>
          <SubLabel>
            Use our AI models to query values from the trigger data and save
            them into variables
          </SubLabel>

          <div className="flex flex-col gap-4 mt-5">
            <div>
              <Input
                floatingLabel
                label="Variable name"
                placeholder="Name the variable"
                value={localData.name}
                onChange={(newName) => {
                  setLocalData({ ...localData, name: newName });
                }}
              />
              <SameVariableNameError
                name={localData.name}
                variablesMap={variablesMap}
                editingVariableId={variable?.id}
                globalVariablesMap={globalVariablesMap}
              />
            </div>

            <div className="my-6">
              <Label className="mb-2">Source</Label>
              <div className="rounded-lg border p-2 bg-primary-blue-extralight-2 border-indigo-light">
                <div className="flex gap-4 items-center">
                  <div className="py-2 px-1 rounded-md bg-white">
                    <TriggerBlockImage
                      variable={sourceVariable}
                      className="!w-6"
                    />
                  </div>
                  <Label>{addingVariableSource?.name}</Label>
                </div>
              </div>
            </div>

            <div>
              <Label className="!text-base">Query the source</Label>
              <SubLabel>
                Add a source value example to preview the query response
              </SubLabel>
            </div>

            <Input
              floatingLabel
              label="Source value example (optional) "
              placeholder="Add value example"
              value={localData.initialValue}
              onChange={(newData) => {
                setLocalData({ ...localData, initialValue: newData });
              }}
            />

            <div className="relative flex flex-col mt-3 pt-1">
              <VariableInput
                label="Instructions to extract variables from the document"
                value={localData.query}
                onChange={(newQuery) => {
                  setLocalData({ ...localData, query: newQuery });
                  setIsFormDirty(true);
                }}
                className={clsx('min-h-40')}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                placeholder="Write the instructions"
                allowAddVariable={false}
              />
              <IconButton
                className="!absolute bottom-1 right-1"
                disabled={
                  isFormDirty
                    ? false
                    : transformApiReqStatus === 'success' ||
                      transformApiReqStatus === 'error' ||
                      transformApiReqStatus === 'pending'
                }
                onClick={transformApiReq}
              >
                {transformApiReqStatus === 'pending' ? (
                  <Spinner size={16} />
                ) : null}
                {transformApiReqStatus === 'success' && !isFormDirty ? (
                  <CheckCircleIcon className="text-transparent" />
                ) : null}
                {transformApiReqStatus === 'error' ? (
                  <InfoOutlined className="text-error" />
                ) : null}
                {transformApiReqStatus !== 'pending' && isFormDirty ? (
                  <SendIcon className="text-white" />
                ) : null}
              </IconButton>
            </div>
            {transformApiReqStatus === 'error' && !isFormDirty ? (
              <p className="px-1 pt-1 text-error text-xs">
                We couldn't execute that instructions, please try with another
                one.
              </p>
            ) : null}

            <div>
              <span className="text-base text-cyan-900 mb-1 block">
                Query result example
              </span>
              <div
                className={clsx('p-[1px] !rounded bg-gray-300', {
                  '!bg-gradient-to-r from-primary-blue to-primary-purple !p-0.5':
                    localData.transformedValue &&
                    transformApiReqStatus !== 'pending',
                })}
              >
                <div className="bg-white !rounded-sm p-3 min-h-[2.75rem] break-all">
                  {localData.transformedValue}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full gap-9 px-8 py-6 absolute bottom-0 bg-white">
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            // TODO(Rafic): Add back !localData.transformedValue?
            disabled={!localData.name || !localData.query.length}
            onClick={handleOnSave}
            variant="contained"
          >
            {variable ? 'update variable' : 'Create variable'}
          </Button>
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={onCancel}
            variant="outlined"
          >
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
