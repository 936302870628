import {
  addFilesSchema,
  getFilesSchema,
  getWorkflowFilesSchema,
  type ZodFetcher,
} from 'api-types-shared';
import type { KyInstance, Options } from 'ky';
import { createZodKyFetcher } from '../fetcher';
import { apiEndpoints, type NodeEnv } from 'ui-kit';
import { handleException } from 'sentry-browser-shared';

export class FileSDK {
  readonly endpoint: string;
  private _kyFetcher: ZodFetcher<KyInstance>;

  constructor(env: NodeEnv, kyOpts?: Options) {
    this.endpoint = apiEndpoints[env].fileApiV1;
    this._kyFetcher = createZodKyFetcher(kyOpts);
  }

  addFiles = async (files: File[], workflowId: string) => {
    const filesPayload = files.map((file) => ({
      name: file.name,
      workflowId,
    }));
    const fileUploadData = await this._kyFetcher(
      addFilesSchema.response,
      `${this.endpoint}/add`,
      {
        method: 'PUT',
        body: JSON.stringify({ files: filesPayload }),
      },
    ).catch((error: unknown) => {
      handleException(error, {
        userMessage: {
          title: 'Error getting file upload data.',
        },
      });
      throw error;
    });

    const uploadPromise = files.map((file, i) => {
      const formData = new FormData();
      formData.append('file', file);
      return fetch(fileUploadData[i].uploadUrl, {
        method: 'PUT',
        body: formData,
      });
    });
    await Promise.all(uploadPromise).catch((error: unknown) => {
      handleException(error, {
        userMessage: {
          title: 'Error uploading file',
        },
      });
      throw error;
    });
    return fileUploadData.map((file) => file.fileId);
  };

  getFilesUrls = async (fileIds: string[]) => {
    const { fileDownloadUrls } = await this._kyFetcher(
      getFilesSchema.response,
      `${this.endpoint}/download-urls`,
      {
        method: 'POST',
        body: JSON.stringify({ fileIds }),
      },
    ).catch((error: unknown) => {
      handleException(error, {
        userMessage: {
          title: 'Error getting file urls',
        },
      });
      throw error;
    });
    return fileDownloadUrls;
  };

  getWorkflowFiles = async (workflowId: string) => {
    const filesMetadata = await this._kyFetcher(
      getWorkflowFilesSchema.response,
      `${this.endpoint}/workflow/${workflowId}`,
      {
        method: 'GET',
      },
    ).catch((error: unknown) => {
      handleException(error, {
        userMessage: {
          title: `Error getting workflow files for ${workflowId}`,
        },
      });
      throw error;
    });
    return filesMetadata;
  };
}
