import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import { hasuraAdminSecret, isAdmin, workflowDataBucket } from './env';
import {
  type LogoutOptions,
  useAuth0,
  notify,
  AlertVariant,
  type User,
} from 'ui-kit';
import { useActiveFeatureFlags, useFeatureFlagEnabled } from 'posthog-js/react';
import { type FeatureFlag } from './constants';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import {
  ExecutionStatusEnum,
  SourceTypeEnum,
  type SourceVariable,
  type Variable,
  type VariableBase,
  VariableTypeEnum,
  type WorkflowNode,
  type GlobalVariable,
  type QueryVariable,
  SubVariableExtractor,
} from 'types-shared';
import { s3Shim } from '../config/aws';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import { VariableEventsEnum, variableModalEventChannel } from './variableModal';
import { handleException } from 'sentry-browser-shared';
import { useEffect } from 'react';

const units = ['bytes', 'KB', 'MB'];

export const formatFileSize = (val: number): string => {
  let i = 0,
    size = val;

  while (size >= 1000 && ++i) {
    size /= 1000;
  }

  return `${size.toFixed(size < 10 && i > 0 ? 1 : 0)} ${units[i]}`;
};

export const downloadLinkData = (url: string, filename?: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename ?? 'true');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const deepDiff = (obj1: object, obj2: object) => {
  function changes(obj: object, base: object) {
    return transform(obj, (result: Record<string, unknown>, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(obj2, obj1);
};

export const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

type UseAuth = () => {
  user?: User;
  getAccessTokenSilently: () => Promise<string>;
  logout: (payload?: LogoutOptions) => Promise<void>;
};

export const useAuth0Wrapper = () => {
  const auth0 = useAuth0();

  useEffect(() => {
    if (auth0.error) {
      handleException(auth0.error, {
        name: 'Auth0 Error',
        source: 'helper/useAuth0',
      });
    }
  }, [auth0.error]);

  return auth0;
};

export const useAuth: UseAuth = isAdmin
  ? () => ({
      user: undefined,
      getAccessTokenSilently: () => Promise.resolve(hasuraAdminSecret),
      logout: () => Promise.resolve(),
    })
  : useAuth0Wrapper;

export const useFeatureFlag = isAdmin
  ? (featureFlag: FeatureFlag, value?: boolean) => value ?? Boolean(featureFlag)
  : useFeatureFlagEnabled;

export const useGetActiveFeatureFlags = isAdmin
  ? () => []
  : useActiveFeatureFlags;

export const extractDaysHoursMinutes = (minutes: number) => {
  const days = Math.floor(minutes / (24 * 60));
  const remainingMinutes = minutes % (24 * 60);
  const hours = Math.floor(remainingMinutes / 60);
  const minutesLeft = remainingMinutes % 60;
  return { days, hours, minutes: minutesLeft };
};

export const getTotalMinutes = ({
  days,
  hours,
  minutes,
}: {
  days: number;
  hours: number;
  minutes: number;
}) => {
  return days * 24 * 60 + hours * 60 + minutes;
};

export const formatDate = (date: string | Date) => {
  const formattedDate = new Date(date).toLocaleString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  const [datePart, timePart] = formattedDate.split(', ');

  return `${datePart} - ${timePart}`;
};

export const titleCase = (val: string): string => startCase(toLower(val));

export const copyToClipboard = (
  data: string,
  message = 'Copied to clipboard',
) => {
  void navigator.clipboard.writeText(data).then(() => {
    notify({
      message,
      variant: AlertVariant.SUCCESS,
    });
  });
};

export const getStatusColor = (status: ExecutionStatusEnum) => {
  switch (status) {
    case ExecutionStatusEnum.Queued:
    case ExecutionStatusEnum.Retry:
      return 'warning';
    case ExecutionStatusEnum.Running:
    case ExecutionStatusEnum.PendingAdmin:
    case ExecutionStatusEnum.PendingSystem:
      return 'primary';
    case ExecutionStatusEnum.Success:
      return 'success';
    case ExecutionStatusEnum.Paused:
    case ExecutionStatusEnum.PendingUser:
      return 'info';
    default:
      return 'error';
  }
};

export const createVariableMap = (variables: GlobalVariable[]) => {
  return variables.reduce<Record<string, typeof GlobalVariable._type>>(
    (map, variable) => {
      map[variable.id] = variable;
      return map;
    },
    {},
  );
};

export const copyS3Object = async (
  fromPath: string,
  toPath: string,
): Promise<void> => {
  await s3Shim.copyObject(
    workflowDataBucket,
    fromPath,
    workflowDataBucket,
    toPath,
  );
};

const allowedSourceTypes = [SourceTypeEnum.API, SourceTypeEnum.Datasource];

export const getFilteredVariables = (
  variables: Record<string, Variable>,
  nodes: WorkflowNode[],
  variablesMap: Record<string, VariableBase>,
): Record<string, QueryVariable> => {
  const subVariableExtractor = new SubVariableExtractor(
    variables,
    handleException,
  );
  const extractedVariables =
    subVariableExtractor.extractVariablesFromNodes(nodes);
  const usedVariables = new Set(Object.keys(extractedVariables));

  return pickBy(variables, (value, key) => {
    if (value.type !== VariableTypeEnum.Query) {
      return false;
    }
    const sourceId = value.data.sourceIds[0];
    const sourceVariable = variablesMap[sourceId] as SourceVariable;
    if (isNil(sourceVariable)) {
      return false;
    }
    return (
      usedVariables.has(key) &&
      allowedSourceTypes.includes(sourceVariable.data.sourceType)
    );
  }) as Record<string, QueryVariable>;
};

export const openPreviewVariableModal = ({
  variableId,
  isCondition,
  updateBranchData,
}: {
  isCondition?: boolean;
  variableId: string;
  updateBranchData?: (variable: Variable, isAdding?: boolean) => void;
}) => {
  variableModalEventChannel.emit('open', {
    modalAction: VariableEventsEnum.VARIABLE_PREVIEW,
    variableId,
    isCondition,
    updateBranchData,
  });
};

export const openAddVariableModal = ({
  insertVariable,
  updateBranchData,
  isCondition,
}: {
  insertVariable?: (variable: Variable) => void;
  updateBranchData?: (variable: Variable, isAdding?: boolean) => void;
  isCondition?: boolean;
}) => {
  variableModalEventChannel.emit('open', {
    isCondition,
    modalAction: VariableEventsEnum.NEW_VARIABLE,
    updateBranchData,
    insertVariable,
  });
};

export const formatWithSuffix = (number: number): string => {
  if (number % 100 >= 11 && number % 100 <= 13) {
    return `${number.toString()}th`;
  }

  switch (number % 10) {
    case 1:
      return `${number.toString()}st`;
    case 2:
      return `${number.toString()}nd`;
    case 3:
      return `${number.toString()}rd`;
    default:
      return `${number.toString()}th`;
  }
};
