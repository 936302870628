import {
  CloseIcon,
  Button,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  Tooltip,
} from 'ui-kit';
import type { WorkflowRetryNode } from 'types-shared';
import { NodeStatusEnum } from 'types-shared';
import { useEffect, useState } from 'react';
import { NodeCheck } from '../NodeCheck';
import {
  extractDaysHoursMinutes,
  getTotalMinutes,
} from '../../../../utils/helper';

interface Props {
  node: WorkflowRetryNode;
  updateNodeData: (data: object) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  onCancel: () => void;
}

const defaultRetryConfig = {
  retryInterval: 1440,
  maxAttempts: 3,
};

export function RetryBlock({
  node,
  onCancel,
  updateNodeData,
  updateNodeStatus,
}: Props) {
  const settings = node.data;
  const { retryInterval, maxAttempts } = settings;

  const [duration, setDuration] = useState<{
    days: number | string;
    hours: number | string;
    minutes: number | string;
  }>(() => {
    return retryInterval
      ? extractDaysHoursMinutes(Number(retryInterval))
      : {
          days: '',
          hours: '',
          minutes: '',
        };
  });

  const updateInterval = (value: number) => () => {
    updateNodeData({
      retryInterval: value,
    });
    if (value === 0) {
      setDuration({
        days: '1',
        hours: '0',
        minutes: '0',
      });
    }
  };

  const updateSettings = (key: 'maxAttempts') => (value: string) => {
    if (
      Number(value) < 0 ||
      ['e', 'E', '-', '.'].some((char) => value.includes(char))
    ) {
      return;
    }
    updateNodeData({ [key]: Number(value) });
  };

  const updateDuration =
    (key: 'days' | 'hours' | 'minutes') => (value: string | undefined) => {
      if (value === undefined) {
        setDuration({
          ...duration,
          [key]: value,
        });
      } else if (
        Number(value) < 0 ||
        (key === 'minutes' && Number(value) > 59) ||
        (key === 'hours' && Number(value) > 23) ||
        ['e', 'E', '-', '.'].some((char) => value.includes(char))
      ) {
        return;
      }
      setDuration({ ...duration, [key]: Number(value) });
    };

  useEffect(() => {
    const minutes = getTotalMinutes({
      days: Number(duration.days),
      hours: Number(duration.hours),
      minutes: Number(duration.minutes),
    });
    if (retryInterval && retryInterval !== minutes) {
      updateNodeData({
        retryInterval: minutes,
      });
    }
  }, [duration, retryInterval, updateNodeData]);

  return (
    <div className="node-block absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col justify-between space-y-5">
      <div className="overflow-y-auto flex-1">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">
            Retry step
          </span>
          <Button
            className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            <CloseIcon className="text-info" />
          </Button>
        </div>
        <div className="my-6">
          <h2 className="text-lg font-medium">Retry step</h2>
          <p className="text-sm font-normal mt-2 text-grey-light">
            Customize your workflow retry settings.
          </p>
        </div>
        <div className="retry-block flex flex-col space-y-8">
          <div className="flex flex-col space-y-2">
            <RadioGroup
              defaultValue="true"
              name="radio-buttons-group"
              onChange={(_, val) => {
                const newVal =
                  val === 'true' ? defaultRetryConfig.retryInterval : 0;
                setDuration({
                  days: '1',
                  hours: '0',
                  minutes: '0',
                });
                updateInterval(newVal)();
              }}
              value={retryInterval ? 'true' : 'false'}
            >
              <FormControlLabel
                control={<Radio color="secondary" />}
                label={
                  <span className="text-info-dark text-sm font-medium">
                    Retry immediately
                  </span>
                }
                value="false"
              />
              <FormControlLabel
                control={<Radio color="secondary" />}
                label={
                  <span className="text-info-dark text-sm font-medium">
                    Time between retries
                  </span>
                }
                value="true"
              />
            </RadioGroup>
            <div className="ml-7 flex flex-row">
              <Input
                InputProps={{
                  className: 'w-24 mr-4',
                  inputMode: 'numeric',
                  inputProps: { max: 1000 },
                }}
                disabled={retryInterval === 0}
                floatingLabel
                id="retry-days"
                label="Days"
                onChange={updateDuration('days')}
                placeholder="1"
                type="number"
                value={duration.days}
              />
              <Input
                InputProps={{
                  className: 'w-24 mr-4',
                  inputMode: 'numeric',
                  inputProps: { max: 1000 },
                }}
                disabled={retryInterval === 0}
                floatingLabel
                id="retry-hours"
                label="Hours"
                onChange={updateDuration('hours')}
                placeholder="0"
                type="number"
                value={duration.hours}
              />
              <Input
                InputProps={{
                  className: 'w-24',
                  inputMode: 'numeric',
                  inputProps: { max: 1000 },
                }}
                disabled={retryInterval === 0}
                floatingLabel
                id="retry-minutes"
                label="Minutes"
                onChange={updateDuration('minutes')}
                placeholder="0"
                type="number"
                value={duration.minutes}
              />
            </div>
          </div>
          <div className="mt-8 flex flex-col space-y-2">
            <Tooltip title="The highest number of times a workflow will retry from the start before moving forward. If there are no steps after, the workflow will fail.">
              <span className="text-info-dark text-sm font-medium">
                Maximum number of attempts
              </span>
            </Tooltip>
            <Input
              InputProps={{
                className: 'w-32',
                inputMode: 'numeric',
                inputProps: { max: 1000 },
              }}
              error={Number(maxAttempts) === 0}
              floatingLabel
              id="retry-count"
              label="Attempts"
              onChange={updateSettings('maxAttempts')}
              placeholder="3"
              type="number"
              value={maxAttempts}
            />
          </div>
          {maxAttempts !== null && Number(maxAttempts) === 0 ? (
            <p className="text-red-500 text-sm font-normal mt-3">
              Number of failures to trigger termination must be at least 1
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col space-y-7">
        {!node.hideFromUser ? (
          <NodeCheck
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            updateNodeStatus={updateNodeStatus}
          />
        ) : null}
        <Button
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          Back to flow view
        </Button>
      </div>
    </div>
  );
}
