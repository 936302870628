import {
  ArrowLeftIcon,
  SendIcon,
  Button,
  Input,
  Label,
  SubLabel,
  Tooltip,
} from 'ui-kit';
import React, { useState } from 'react';
import {
  type DocumentVariable,
  QueryValueTypeEnum,
  type QueryVariable,
  type TemplateData,
  type Variable,
  VariableString,
  VariableTypeEnum,
  type VariableMap,
  type GlobalVariable,
} from 'types-shared';
import { VariableInput } from '../../VariableTypes/VariableInput';
import { clsx } from 'clsx';
import { v4 as uuid } from 'uuid';
import merge from 'lodash/merge';
import { DocumentLinkedQueryVariables } from './DocumentLinkedQueryVariables';
import { SameVariableNameError } from '../../SameVariableNameError';

interface Props {
  documentVariables: DocumentVariable[];
  onCancel: () => void;

  variable?: QueryVariable;
  updateVariable: (variable: QueryVariable) => void;
  addVariable: (variable: QueryVariable) => void;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    query: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
}

export function AddEditQueryVariable({
  onCancel,
  variable,
  documentVariables,
  variablesMap,
  updateVariable,
  addVariable,
  globalVariablesMap,
  // transformApiReqStatus,
  // onTransformApiReq,
}: Props) {
  const [localData, setLocalData] = useState<{
    name: string;
    query: TemplateData;
    transformedValue: string;
  }>({
    name: variable?.name ?? '',
    query: variable?.dashboardData?.transformInputs?.query ?? [],
    transformedValue: variable
      ? VariableString.parse(
          variable.dashboardData?.transformInputs?.transformedValue ?? '',
        )
      : '',
  });
  // const [isFormDirty, setIsFormDirty] = useState(false);

  // const transformApiReq = () => {
  //   onTransformApiReq(localData.query, 'some fake document data')
  //     .then((transformedValue) => {
  //       if (transformedValue) {
  //         setLocalData({
  //           ...localData,
  //           transformedValue,
  //         });
  //       }
  //     })
  //     .catch(() => {
  //       // do nothing, we are already showing an error below
  //     })
  //     .finally(() => {
  //       setIsFormDirty(false);
  //     });
  // };

  const resetForm = () => {
    setLocalData({
      name: '',
      query: [],
      transformedValue: '',
    });
  };

  const handleOnSave = () => {
    const sourceIds = documentVariables.map((v) => v.id);

    const commonFields = {
      name: localData.name,
      dashboardData: {
        initialValue: '',
        transformInputs: {
          query: localData.query,
          transformedValue: localData.transformedValue,
        },
      },
      data: {
        sourceIds,
        query: localData.query,
        valueType: QueryValueTypeEnum.String,
      },
    };

    if (variable) {
      const updatedVariable = merge({}, variable, commonFields);
      updateVariable(updatedVariable);
      onCancel();
    } else {
      addVariable({
        id: uuid(),
        type: VariableTypeEnum.Query,
        ...commonFields,
      });
      resetForm();
    }
  };

  const unlinkVariable = (v: QueryVariable) => {
    updateVariable({
      ...v,
      data: {
        ...v.data,
        sourceIds: v.data.sourceIds.filter(
          (id) => !documentVariables.some((dv) => dv.id === id),
        ),
      },
    });

    if (variable?.id === v.id) {
      onCancel();
    }
  };

  return (
    <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
      <div className="overflow-auto h-full mb-20">
        <div className="flex justify-between items-center pt-8 px-8">
          <div className="flex items-center">
            <span
              className="flex !border !border-solid !border-info !rounded-lg cursor-pointer mr-3.5"
              onClick={onCancel}
              role="presentation"
            >
              <ArrowLeftIcon className="text-info !h-7 !w-7" />
            </span>
            <span className="text-sm font-medium text-info-dark">
              Documents step&nbsp;
            </span>
            <span className="text-sm font-medium text-primary-blue">
              / Add document
            </span>
          </div>
        </div>
        <div className="py-6 px-8 flex justify-between items-center bg-white z-50 sticky top-0">
          <h2 className="text-lg font-medium text-info-dark">Documents step</h2>

          <hr className="border-b border-color-gray-200 absolute bottom-0 left-0 w-full" />
        </div>

        <div className="p-8">
          <Label className="!text-base mb-2">
            Create variables from the document
          </Label>
          <SubLabel>Use our AI models to query values from documents</SubLabel>

          <div className="flex flex-col gap-4 mt-5">
            <div>
              <Input
                floatingLabel
                label="Variable name"
                placeholder="Name the variable"
                value={localData.name}
                onChange={(newName) => {
                  setLocalData({ ...localData, name: newName });
                }}
              />
              <SameVariableNameError
                name={localData.name}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                editingVariableId={variable?.id}
              />
            </div>

            <div className="relative flex flex-col mt-3 pt-1">
              <VariableInput
                label="Instructions to extract variables from the document"
                value={localData.query}
                onChange={(newQuery) => {
                  setLocalData({ ...localData, query: newQuery });
                  // setIsFormDirty(true);
                }}
                className={clsx('min-h-40')}
                variablesMap={variablesMap}
                placeholder="Write the instructions"
                globalVariablesMap={globalVariablesMap}
                allowAddVariable={false}
              />
              <Tooltip title="Transformations on documents coming soon..">
                <div>
                  <SendIcon className="text-white !absolute bottom-2 right-2" />
                </div>
              </Tooltip>
              {/*<IconButton*/}
              {/*  className="!absolute bottom-1 right-1"*/}
              {/*  disabled={*/}
              {/*    isFormDirty*/}
              {/*      ? false*/}
              {/*      : transformApiReqStatus === 'success' ||*/}
              {/*        transformApiReqStatus === 'error' ||*/}
              {/*        transformApiReqStatus === 'pending'*/}
              {/*  }*/}
              {/*  onClick={transformApiReq}*/}
              {/*>*/}
              {/*  {transformApiReqStatus === 'pending' ? (*/}
              {/*    <Spinner size={16} />*/}
              {/*  ) : null}*/}
              {/*  {transformApiReqStatus === 'success' && !isFormDirty ? (*/}
              {/*    <CheckCircleIcon className="text-transparent" />*/}
              {/*  ) : null}*/}
              {/*  {transformApiReqStatus === 'error' ? (*/}
              {/*    <InfoOutlined className="text-error" />*/}
              {/*  ) : null}*/}
              {/*  {transformApiReqStatus !== 'pending' && isFormDirty ? (*/}
              {/*    <SendIcon className="text-white" />*/}
              {/*  ) : null}*/}
              {/*</IconButton>*/}
            </div>
            {/*{transformApiReqStatus === 'error' && !isFormDirty ? (*/}
            {/*  <p className="px-1 pt-1 text-error text-xs">*/}
            {/*    We couldn’t execute that instructions, please try with another*/}
            {/*    one.*/}
            {/*  </p>*/}
            {/*) : null}*/}

            <Input
              floatingLabel
              label="Example variable result"
              placeholder="Variable result"
              value={localData.transformedValue}
              onChange={(newData) => {
                setLocalData({ ...localData, transformedValue: newData });
              }}
            />

            {/*<div>*/}
            {/*  <span className="text-base text-cyan-900 mb-1 block">*/}
            {/*    Example variable result*/}
            {/*  </span>*/}
            {/*  <div*/}
            {/*    className={clsx('p-[1px] !rounded bg-gray-300', {*/}
            {/*      '!bg-gradient-to-r from-primary-blue to-primary-purple !p-0.5':*/}
            {/*        localData.transformedValue &&*/}
            {/*        transformApiReqStatus !== 'pending',*/}
            {/*    })}*/}
            {/*  >*/}
            {/*    <div className="bg-white !rounded-sm p-3 min-h-[2.75rem] break-all">*/}
            {/*      {localData.transformedValue}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <Button
              color="secondary"
              size="small"
              variant="outlined"
              disabled={
                !localData.name ||
                !localData.query.length ||
                !localData.transformedValue
              }
              className="!w-[fit-content] self-start"
              onClick={handleOnSave}
            >
              {variable ? 'update variable' : 'Create variable'}
            </Button>
          </div>

          <div className="mt-8">
            <Label>Created variables</Label>

            <DocumentLinkedQueryVariables
              variablesMap={variablesMap}
              documentVariables={documentVariables}
              selectedVariableId={variable?.id}
              globalVariablesMap={globalVariablesMap}
              noDataUI={
                <SubLabel className="mt-2">
                  You don't have variables created yet
                </SubLabel>
              }
              onDelete={unlinkVariable}
            />
          </div>
        </div>

        <div className="flex w-full gap-9 px-8 py-6 absolute bottom-0 bg-white">
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={onCancel}
            variant="contained"
          >
            Add variable
          </Button>
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={onCancel}
            variant="outlined"
          >
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
