import { type WorkflowWebhook } from 'types-shared/workflowTypes';
import { v4 as uuid } from 'uuid';

export interface FormValues {
  id: string;
  eventName: string;
  event: string;
  action: string;
  url: string;
  headers: { id: string; key: string; value: string }[];
}

export const events = ['Execution finished'];
export const actions = ['Send webhook'];

export const defaultForm: FormValues = {
  id: uuid(),
  eventName: '',
  event: events[0],
  action: actions[0],
  url: '',
  headers: [],
};

export const convertWebhookToFormValues = (
  webhook: WorkflowWebhook,
): FormValues => ({
  id: webhook.id,
  eventName: webhook.name,
  event: events[0],
  action: actions[0],
  url: webhook.webhookUrl,
  headers: Object.entries(webhook.headers).map(([key, value]) => ({
    id: uuid(),
    key,
    value,
  })),
});

export const convertFormValuesToWebhook = (
  formValues: FormValues,
): WorkflowWebhook => ({
  id: formValues.id,
  name: formValues.eventName,
  webhookUrl: formValues.url,
  headers: formValues.headers.reduce((acc: Record<string, string>, header) => {
    acc[header.key] = header.value;
    return acc;
  }, {}),
});
