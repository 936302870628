import { useMemo, useState, useCallback } from 'react';
import { ExecutionStatusEnum } from 'types-shared';
import { Button, Select, InputWithActiveBorder, CheckboxSelect } from 'ui-kit';
import { type SelectChangeEvent } from '@mui/material/Select';

import { isDeepEqual } from '@mui/x-data-grid/internals';
import { isAdmin } from '../../../utils/env';
import { statusTitleMapping } from '../../../utils/constants';
import {
  defaultFilters,
  type ExecutionListFilters,
  failedStatuses,
  runningStatuses,
} from '../utils/helper';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    sx: {
      '& .MuiTypography-body1': {
        fontSize: 14,
      },
    },
  },
  BackdropProps: {
    classes: { root: 'no-backdrop' },
  },
};

const adminRunsOptions = ['All', 'true', 'false'];
const defaultStatus = 'Filter by status';

interface ExecutionListFilterProps {
  filters: ExecutionListFilters;
  setFilters: (filters: ExecutionListFilters) => void;
  onRefresh: () => void;
  onResetPage: () => void;
}

export default function ExecutionListFilter({
  filters,
  setFilters,
  onResetPage,
}: ExecutionListFilterProps) {
  const [id, setId] = useState<string>(filters.id ?? '');
  const [owner, setOwner] = useState<string>(filters.owner ?? '');
  const [statusFilterUsed, setStatusFilterUsed] = useState(false);

  const onClear = () => {
    setId('');
    setOwner('');
    setFilters(defaultFilters);
    onResetPage();
  };

  const updateFilters = (key: string, value: unknown) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const getFilterTitle = useCallback((status: string) => {
    const formattedStatus = status.toLowerCase().replace('_', ' ');
    const title = isAdmin
      ? formattedStatus
      : statusTitleMapping[status] ?? formattedStatus;

    if (status.toLowerCase() === defaultStatus.toLowerCase()) {
      return defaultStatus;
    }

    return isAdmin
      ? upperFirst(status.toLowerCase()).replaceAll('_', ' ')
      : upperFirst(title.toLowerCase()).replaceAll('_', ' ');
  }, []);

  const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    if (!statusFilterUsed) {
      setStatusFilterUsed(true);
    }
    onResetPage();

    let selectedStatuses =
      typeof value === 'string' ? value.split(',') : (value as string[]);

    if (!isAdmin) {
      if (selectedStatuses.includes(ExecutionStatusEnum.Running)) {
        selectedStatuses = selectedStatuses.concat(runningStatuses);
      } else {
        selectedStatuses = selectedStatuses.filter(
          (status) => !runningStatuses.includes(status as ExecutionStatusEnum),
        );
      }
      if (selectedStatuses.includes(ExecutionStatusEnum.Terminated)) {
        selectedStatuses = selectedStatuses.concat(failedStatuses);
      } else {
        selectedStatuses = selectedStatuses.filter(
          (status) => !failedStatuses.includes(status as ExecutionStatusEnum),
        );
      }
    }
    if (
      selectedStatuses[0] === defaultStatus ||
      selectedStatuses[0] === 'All'
    ) {
      selectedStatuses = selectedStatuses.slice(1);
    }
    if (selectedStatuses.length === 0) {
      selectedStatuses = ['All'];
    }
    updateFilters('status', uniq(selectedStatuses));
  };

  const hasFilters = useMemo(
    () => !isDeepEqual(filters, defaultFilters),
    [filters],
  );

  const executionStatusOptions = useMemo(() => {
    let statusOpts: ExecutionStatusEnum[] | string[] = [];

    if (isAdmin) {
      statusOpts = Object.values(ExecutionStatusEnum);
    } else {
      statusOpts = Object.values(ExecutionStatusEnum).filter(
        (s) =>
          ![...runningStatuses, ...failedStatuses].includes(
            s as ExecutionStatusEnum,
          ),
      );
      statusOpts.push(ExecutionStatusEnum.Running);
      statusOpts.push(ExecutionStatusEnum.Terminated);
    }

    return statusOpts.sort((a, b) =>
      getFilterTitle(a).localeCompare(getFilterTitle(b)),
    );
  }, [getFilterTitle]);

  return (
    <div className="w-full flex items-center space-x-4 mt-10 mb-5">
      <InputWithActiveBorder
        classes={{ root: 'flex-1' }}
        style={{ fontSize: '14px' }}
        isSearch
        // floatingLabel
        placeholder="Search by Execution ID"
        onBlur={() => {
          onResetPage();
          updateFilters('id', id);
        }}
        onChange={(val) => {
          setId(val);
        }}
        value={id}
      />

      <InputWithActiveBorder
        classes={{ root: 'flex-1' }}
        style={{ fontSize: '14px' }}
        isSearch
        // floatingLabel
        placeholder="Search by Owner"
        onBlur={() => {
          onResetPage();
          updateFilters('owner', owner);
        }}
        onChange={(val) => {
          setOwner(val);
        }}
        value={owner}
      />

      <CheckboxSelect
        style={{ fontSize: '14px' }}
        multiple
        placeholder={defaultStatus}
        value={
          !statusFilterUsed &&
          filters.status.length === 1 &&
          filters.status[0] === 'All'
            ? ([defaultStatus] as unknown as string)
            : (filters.status as unknown as string)
        }
        doesInputHaveValue={(value) => {
          let valueExists = false;
          if (Array.isArray(value)) {
            const valueArr = value;
            const isDefaultValue =
              valueArr.length === 1 &&
              (valueArr[0] === 'All' || valueArr[0] === defaultStatus);
            valueExists = Boolean(valueArr.length && !isDefaultValue);
          }
          if (typeof value === 'string') {
            valueExists = Boolean(value.length);
          }
          return valueExists;
        }}
        getFilterTitle={getFilterTitle}
        isChecked={(item) => filters.status.includes(item)}
        options={executionStatusOptions}
        onChange={handleStatusChange}
        renderValue={(selected) => {
          if (!Array.isArray(selected)) {
            return <>{selected}</>;
          }
          const hasRunning = selected.includes(ExecutionStatusEnum.Running);
          const hasFailed = selected.includes(ExecutionStatusEnum.Terminated);
          const finalText = selected
            .filter(
              (item: ExecutionStatusEnum) =>
                (!hasRunning || !runningStatuses.includes(item)) &&
                (!hasFailed || !failedStatuses.includes(item)),
            )
            .concat(hasRunning ? [ExecutionStatusEnum.Running] : [])
            .concat(hasFailed ? [ExecutionStatusEnum.Terminated] : [])
            .map((item) => getFilterTitle(item as string))
            .join(', ');

          return <>{finalText}</>;
        }}
        MenuProps={MenuProps}
      />

      {isAdmin ? (
        <Select
          className="min-w-56"
          getLabel={(op: string) => op}
          getValue={(op: string) => op}
          style={{ fontSize: '14px' }}
          label="Admin Runs"
          MenuProps={{
            sx: {
              '& .MuiTypography-body1': {
                fontSize: 14,
              },
            },
          }}
          onChange={(e) => {
            const value = e.target.value;
            const hideAdminRuns =
              value === 'All' ? undefined : value === 'true';
            onResetPage();
            updateFilters('hideAdminRuns', hideAdminRuns);
          }}
          options={adminRunsOptions}
          value={
            filters.hideAdminRuns === undefined
              ? 'All'
              : String(filters.hideAdminRuns)
          }
        />
      ) : null}

      {hasFilters ? (
        <Button color="secondary" onClick={onClear} variant="text">
          Clear Filters
        </Button>
      ) : null}
    </div>
  );
}
